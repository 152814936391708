import axios from 'axios'
import _ from 'lodash'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { toast } from 'react-toastify'

import { StatusType } from 'components/UI/OrgStatus/StatusType'
import { PaymentUrlParamsType } from 'components/Payment/paymentUrlParams'
import { ReducerType } from 'store/reducers'
import getPartner from 'utils/getPartner'
import { isProduction } from 'utils/isProduction'
import { getOrgNameWithAmpersand } from 'utils/paymentUtils'
import * as webSocketConstants from 'utils/webSocketConstants'

type Partner = {
  email: string
  fee: number
  fees: {
    agent: { yearly: number; monthly: number }
    scs: {
      yearly: number
      monthly: number
    }
    id: string
    name: string
    stripe_user_id: string
    username: string
    webhook_address: string
    welcome_text: string
    white_label_emails: true
  }
  id: string
  name: string
  stripe_user_id: string
  username: string
  webhook_address: string
  welcome_text: string
  white_label_emails: true
}

type Address = {
  address1: string
  address2: string
  city: string
  country: string
  county: string
  id: string
  lat: number | null
  lon: number | null
  province: string
  zipcode: string
}

type User = {
  email: string
  first_name: string
  full_name: string
  last_name: string
  phone: string
}

type PersonalType = {
  address: Address
  fields: { invested: string; stake: string }
  id: string
  order: number
  type: 'Member' | 'Manager' | 'Director'
  user: User
}

type OrgData = {
  id: ReducerType['payment']['orgId']
  name: string
  type: string
  state_filed: string
  date_created: string
  date_filed: string
  date_paid: string
  address: Address
  agent: {
    address: Address
    email: string
    first_name: string
    full_name: string
    id: string
    last_name: string
    partner: string
  }
  directors: PersonalType[]
  shareholders?: {
    fields?: {
      shares: number
    }
  }[]
  status: StatusType['status']
  pm?: {
    id: string
    active?: boolean
    action?: string
    fees?: {
      admin_fee: number
      filing_fee: number
    }
    summary?: string
    turnaround?: string
  }
  principal_office?: Address
  managers: PersonalType[]
  partner: Partner
  is_confirmed: boolean
  note: string
  fields: {
    business_purpose: string
    corporation_type: string
    par_value: string
    shares: string
  }
}

type WsOrgInfoArguments =
  | { fields: Partial<OrgData['fields']> }
  | { name: OrgData['name'] }
  | { address: Address }

type WsAffiliateArguments = PersonalType & { address?: Address }

export const usePartner = () => {
  const [partner, setPartner] = useState('betterlegal')
  const [isBetterlegal, setIsBetterlegal] = useState(true)

  useEffect(() => {
    setPartner(getPartner(window.location.hostname))
    setIsBetterlegal(partner === 'betterlegal')
  }, [partner])

  return [partner, isBetterlegal]
}

export const useOrgWebSocket = (
  orgId: ReducerType['payment']['orgId'],
  orgPath = 'org'
): [
  orgData: OrgData,
  handlers: {
    wsOrgInfo: (data: WsOrgInfoArguments) => Promise<void>
    wsAgent: (data: OrgData['agent']) => Promise<void>
    wsAffiliate: (data: WsAffiliateArguments) => Promise<void>
    wsAddAffiliate: (type: string) => Promise<void>
    wsRemoveAffiliate: (type: string, uuid: string) => Promise<void>
    wsPaymentRevert: () => Promise<void>
    wsPaymentConfirm: (customer_id?: string, transaction_id?: string) => Promise<void>
  }
] => {
  if (!orgId) throw new Error('No org id')

  const dispatch = useDispatch()

  const [ws, setWs] = useState(null)
  const [orgData, setOrgData] = useState(null)

  const { token, userData } = useSelector<any, any>(state => state.auth)

  useEffect(() => {
    if (!ws) {
      const wsUrl = `${process.env.REACT_APP_WS_URL}/${orgPath}/${orgId}/?token=${token}`
      setWs(new WebSocket(wsUrl))
    } else {
      ws?.addEventListener('message', e => {
        const response = JSON.parse(e.data)
        dispatch(hideLoading())

        if (response.type === webSocketConstants.CONNECT_ORG_INFO) {
          setOrgData(response?.data)
          return
        } else if (response.type === webSocketConstants.ORG_INFO) {
          setOrgData({ ...response.data, address: { ...response.data.address } })
          return
        } else if (response.type === webSocketConstants.ERROR) {
          toast.error(response.message)
          return
        } else {
          toast.warn('Unknown WebSocket Response, see console for the details')
          console.warn(e)
        }
      })
    }

    return () => {
      ws?.removeEventListener('message', e => console.log(JSON.parse(e)))
      ws?.close()
    }
  }, [dispatch, token, ws, orgId])

  const wsOrgInfo = useCallback(
    async data => {
      dispatch(showLoading())
      ws.send(
        JSON.stringify({
          type: webSocketConstants.EDIT_INFO,
          payload: {
            uuid: orgId,
            ...data,
          },
        })
      )
    },
    [dispatch, ws, orgId]
  )

  const wsAgent = useCallback(
    async data => {
      dispatch(showLoading())
      ws.send(
        JSON.stringify({
          type: webSocketConstants.EDIT_AGENT,
          payload: {
            uuid: data.id,
            ...data,
          },
        })
      )
    },
    [dispatch, ws]
  )

  const wsAffiliate = useCallback(
    async data => {
      dispatch(showLoading())

      data.fields.invested = +_.replace(data.fields?.invested, /[$,]/g, '')
      data.fields.stake = +_.replace(data.fields?.stake, /[,%]/g, '')

      ws.send(
        JSON.stringify({
          type: webSocketConstants.EDIT_AFFILIATE,
          payload: {
            uuid: data.id,
            ...data,
          },
        })
      )
    },
    [dispatch, ws]
  )

  const wsAddAffiliate = useCallback(
    async type => {
      dispatch(showLoading())

      ws.send(
        JSON.stringify({
          type: webSocketConstants.ADD_AFFILIATE,
          payload: {
            type,
          },
        })
      )
    },
    [dispatch, ws]
  )

  const wsRemoveAffiliate = useCallback(
    async (type, uuid) => {
      dispatch(showLoading())

      ws.send(
        JSON.stringify({
          type: webSocketConstants.REMOVE_AFFILIATE,
          payload: {
            type,
            uuid,
          },
        })
      )
    },
    [dispatch, ws]
  )

  const wsPaymentRevert = useCallback(async () => {
    dispatch(showLoading())

    ws.send(
      JSON.stringify({
        type: webSocketConstants.MODAL_REVERT,
        payload: {},
      })
    )
  }, [dispatch, ws])

  const wsPaymentConfirm = useCallback(
    async (customer_id?: string, transaction_id?: string) => {
      dispatch(showLoading())

      ws.send(
        JSON.stringify({
          type: webSocketConstants.MODAL_CONFIRM,
          payload: {},
        })
      )

      if (isProduction()) {
        await axios({
          method: 'post',
          headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          url: 'https://hooks.zapier.com/hooks/catch/962269/ow9ebz1/',
          data: {
            org_id: orgData?.id,
            org_name: orgData?.name,
            org_type: orgData?.type,
            service: orgData?.pm?.action,
            customer_id: customer_id,
            transaction_id: transaction_id,
            amount: orgData?.pm?.fees?.admin_fee + orgData?.pm?.fees?.filing_fee,
            user_name: userData?.full_name,
            user_email: userData?.email,
          },
        })
      }
    },
    [orgData, userData, dispatch, ws]
  )

  const handlers: ReturnType<typeof useOrgWebSocket>[1] = {
    wsOrgInfo,
    wsAgent,
    wsAffiliate,
    wsAddAffiliate,
    wsRemoveAffiliate,
    wsPaymentRevert,
    wsPaymentConfirm,
  }

  return [orgData, handlers]
}

export const useUrlParams = (urlParamsString, username) => {
  const [params, setParams] = useState<PaymentUrlParamsType>({
    orgType: '',
    variant: '',
    managers: '',
    members: '',
    directors: '',
    state: '',
    coupon: '',
    couponTime: '',
    partner: '',
    ra: '',
    w2: '',
    contact: '',
    email: '',
    name: '',
    aff: '',
    chamber: '',
    allstate: '',
  })

  useEffect(() => {
    const urlParams = queryString.parse(urlParamsString)

    setParams({
      orgType: urlParams.type,
      variant: urlParams.variant,
      managers: urlParams.managers,
      members: urlParams.members,
      directors: urlParams.directors,
      state: urlParams.state?.toString().replace('_', ' '),
      coupon: urlParams.c,
      couponTime: urlParams.ct,
      partner: username,
      ra: urlParams.ra,
      w2: urlParams.w2 || urlParams.W2,
      contact: urlParams.contact,
      email: urlParams.email,
      name: getOrgNameWithAmpersand(urlParamsString),
      kw: urlParams.kw,
      aff: urlParams.aff,
      chamber: urlParams.chamber,
      allstate: urlParams.allstate,
    })
  }, [urlParamsString, username])

  return [params]
}
