import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Org from './Org'
import OrgListHeader from './OrgListHeader'
import * as vars from 'utils/styledVariables'

const StyledOrgList = styled.div`
  margin-bottom: 20px;
  box-shadow: ${vars.shadow};

  @media screen and (max-width: 768px) and (min-width: 480px) {
    width: 420px;
    margin: 0 auto;
  }
`

function OrgList({ orgs }) {
  return (
    <StyledOrgList>
      <OrgListHeader />
      <div>
        {orgs.map(org => {
          return (
            <Link to={'/org/' + org.id} key={org.id}>
              <Org org={org} />
            </Link>
          )
        })}
      </div>
    </StyledOrgList>
  )
}

export default OrgList
