import { createGlobalStyle } from 'styled-components'

import * as vars from './styledVariables'

export const GlobalStyle = createGlobalStyle`
  html, body {
    min-height: 100%;
  }

  body {
    background-color: ${vars.blueLightest};

    font-family: ${vars.mainFont};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overscroll-behavior: none;
  }
`