import React, { useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import { CustomInput, CustomInputMasked } from '../CustomFields'
import { SimpleDropdown } from '../SimpleDropdown'
import { AddressAutocompleteDropdown } from '../AddressAutocompleteDropdown'

import { ValidationError } from '../ValidationError'

import { isDefaultAddress } from 'utils/simplifiedAddress'
import { investedMask, stakeMask } from '../editableSchemas'

const StyledPerson = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas:
    'fullName fullName fullName fullName stake stake invested invested'
    'email email email email address1 address1 address1 address1'
    'phone phone phone phone city city state zipcode';
  gap: 2px;

  padding: 8px;

  @media screen and (max-width: 480px) {
    grid-template-areas:
      'fullName fullName fullName fullName'
      'stake stake invested invested'
      'email email email email'
      'phone phone phone phone'
      'address1 address1 address1 address1'
      'city city state zip';
  }
`

export function EditableMemberSimplified({
  userRights,
  managers,
  loading,
  editMode,
  onChange,
  address,
  useRaAddress,
}) {
  const [managerOptions, setmanagerOptions] = useState(() => {
    const options = managers.map((manager, i) => ({
      key: i,
      value: manager.user.full_name,
      text: manager.user.full_name,
    }))

    return options
  })

  const isDefault = useRaAddress

  return (
    <>
      <StyledPerson>
        <Field name={'user.full_name'}>
          {({ field }) => (
            <SimpleDropdown
              placeholder="Full name"
              fluid
              search
              selection
              style={{ gridArea: 'fullName' }}
              options={managerOptions}
              value={field.value}
              disabled={loading || !userRights.editInfo}
              noResultsMessage="Keep typing if this is a new unique name."
              allowAdditions
              wsHandler={d => {
                onChange('user.full_name', d.value)
              }}
              onAddItem={(_, d) => {
                setmanagerOptions([
                  ...managerOptions,
                  { text: d.value, value: d.value, key: managerOptions.length },
                ])
                onChange('user.full_name', d.value)
              }}
            />
          )}
        </Field>

        <Field
          disabled={loading || !userRights.editInfo}
          active={editMode}
          name={'user.email'}
          placeholder="Email"
          component={CustomInput}
          style={{ gridArea: 'email' }}
        />
        <Field
          disabled={loading || !userRights.editInfo}
          active={editMode}
          placeholder="Phone"
          name={'user.phone'}
          component={CustomInput}
          style={{ gridArea: 'phone' }}
        />

        <Field
          disabled={loading || !userRights.editStake}
          active={editMode}
          name={'fields.stake'}
          mask={stakeMask}
          placeholder="%"
          component={CustomInputMasked}
          style={{
            gridArea: 'stake',
          }}
        />
        <Field
          disabled={loading || !userRights.editInfo}
          active={editMode}
          mask={investedMask}
          name={'fields.invested'}
          placeholder="$"
          component={CustomInputMasked}
          style={{
            gridArea: 'invested',
          }}
        />

        <Field name="address">
          {() => (
            <AddressAutocompleteDropdown
              defaultOption={{ key: 'agent', value: 'agent', text: 'Use Registered Agent Address' }}
              isDefault={isDefault}
              placeholder="Select Registered Agent Address"
              style={{ gridArea: 'address1' }}
              readOnly={!userRights.editInfo}
              value={address?.address1}
              wsHandler={address => {
                if (!address) {
                  // do nothing if ra selected and user click on it
                  if (!isDefault) {
                    onChange('address', null)
                  }
                } else {
                  onChange('address', address)
                }
              }}
            />
          )}
        </Field>

        {!isDefault && (
          <>
            <Field
              disabled={loading || !userRights.editInfo}
              active={editMode}
              placeholder="City"
              name={'address.city'}
              component={CustomInput}
              style={{ gridArea: 'city' }}
            />
            <Field
              disabled={loading || !userRights.editInfo}
              active={editMode}
              placeholder="State"
              name={'address.province'}
              component={CustomInput}
              style={{ gridArea: 'state' }}
            />
            <Field
              disabled={loading || !userRights.editInfo}
              active={editMode}
              placeholder="Zipcode"
              name={'address.zipcode'}
              component={CustomInput}
              style={{ gridArea: 'zipcode' }}
            />
          </>
        )}
      </StyledPerson>
      <ul style={{ padding: '0 5px', listStyle: 'none' }}>
        <ErrorMessage name="address.address1" component={ValidationError} />
        <ErrorMessage name="address.city" component={ValidationError} />
        <ErrorMessage name="address.province" component={ValidationError} />
        <ErrorMessage name="address.zipcode" component={ValidationError} />
        <ErrorMessage name="user.first_name" component={ValidationError} />
        <ErrorMessage name="user.last_name" component={ValidationError} />
        <ErrorMessage name="user.email" component={ValidationError} />
        <ErrorMessage name="user.phone" component={ValidationError} />
      </ul>
    </>
  )
}
