
import Iframe from 'react-iframe'
import queryString from 'query-string'

import { formatOrgType } from 'utils/orgType'

export default function ServicesRequest(props) {
  const { org_name, org_type, state_filed } = queryString.parse(props.location.search)

  return (
    <Iframe
      url={`https://poseidonimaging.typeform.com/to/Nq42Ub?org_name=${org_name}&org_type=${formatOrgType(
        org_type.toString()
      )}&state_filed=${state_filed}`}
      width="100%"
      height="100%"
    />
  )
}
