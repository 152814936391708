import { put } from 'redux-saga/effects'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { toast } from 'react-toastify'

import axiosInstance from 'utils/axiosInstance'
import cookies from 'utils/cookies'
import * as authActions from '../actions/auth'
import { authSuccess, fetchUsername, setUsernameSuccess, fetchPartnerInfoSuccess } from 'store/slices/authSlice'

export function* authSaga(action) {
  yield put(showLoading())

  const credentials = action.payload.data

  try {
    const response = yield axiosInstance.post('/users/token', {
      partner_site: action.partner,
      username: credentials.email,
      password: credentials.password
    })
    const token = response.data.token

    yield cookies.set('jwt', token, {
      path: '/',
      maxAge: 7 * 24 * 60 * 60
    })
    yield (axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`)
    yield put(authSuccess(token))
    yield put(fetchUsername())
    yield put(hideLoading())
  } catch (err) {
    yield put(hideLoading())
    yield toast.error(err?.response?.data?.details[0]?.title)
  }
}

export function* fetchUsernameSaga() {
  try {
    const response = yield axiosInstance.get('/user')
    yield put(setUsernameSuccess(response.data))
  } catch (err) {
  }
}

export function* fetchPartnerInfoSaga(action) {
  try {
    const response = yield axiosInstance.get(`/public/partner/${action.payload}`)
    yield put(fetchPartnerInfoSuccess(response.data))
  } catch (err) {
    console.error(err)
  }
}

export function* logoutSaga() {
  yield cookies.remove('jwt')
  yield put(authActions.logout())
}
