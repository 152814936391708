import React, { useState, useEffect, useCallback } from 'react'
import { Checkbox, Message, Button } from 'semantic-ui-react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import TagManager from 'react-gtm-module'

import { paymentSetRegisteredAgent, paymentSwitchRegisteredAgentType } from 'store/actions/payment'
import { usePartner } from 'utils/hooks'

const AgentRow = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`

const StyledPrice = styled.span`
  display: flex;
  align-items: center;

  margin-left: auto;

  font-size: 18px;
  font-weight: 600;

  h3 {
    margin: 0 10px 0;

    font-size: 16px;
  }

  @media screen and (max-width: 400px) {
    margin: 10px 0 0;
  }
`

const MessageHighlight = styled.p`
  margin: 0.3em 0 0 !important;

  font-weight: 900;
`

function Addons() {
  const [agentPlan, setAgentPlan] = useState('RA-MONTHLY')
  const [agentChecked, setAgentChecked] = useState(false)
  const [, isBetterlegal] = usePartner()
  const dispatch = useDispatch()

  const orgType = useSelector<any, any>(state => state.payment.data.orgType)
  const agentData = useSelector<any, any>(state => state.payment.data.agent)
  const agentFees = useSelector<any, any>(state => state.auth.partnerInfo.fees?.agent)
  const urlParams = queryString.parse(useSelector<any, any>(state => state.router.location.search))
  const { ra, type } = urlParams

  const switchRegisteredAgentType = fee => dispatch(paymentSwitchRegisteredAgentType(fee))
  const setRegisteredAgent = useCallback(checked => dispatch(paymentSetRegisteredAgent(checked)), [
    dispatch,
  ])

  useEffect(() => {
    const urlAgentCondition = ra === 'Provide a Registered Agent to me' || ra === 'BetterLegal'
    const agentShouldBeChecked =
      agentData.full_name === 'BetterLegal' ||
      agentData.full_name === 'Registered Agent Partner' ||
      agentData.email === 'partner@registeredagent.com' ||
      agentData.email === 'registeredagent@betterlegal.com'
    const agentNeeded = agentShouldBeChecked || urlAgentCondition

    setAgentChecked(agentNeeded)
    setRegisteredAgent(agentNeeded)
  }, [ra, agentData, setRegisteredAgent])

  const handleAgentPlanSwitch = newPlan => {
    switchRegisteredAgentType(newPlan)
    if (isBetterlegal) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'interactionEvent',
          eventCategory: 'Payment Page',
          eventAction: 'Registered Agent',
          eventLabel: newPlan === 'RA-MONTHLY' ? 'Month' : 'Year',
        },
      })
    }

    setAgentPlan(newPlan)
  }

  const handleAgentFlag = (_, data) => {
    setRegisteredAgent(data.checked)
    setAgentChecked(data.checked)
    if (isBetterlegal) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'interactionEvent',
          eventCategory: 'Payment Page',
          eventAction: 'Registered Agent',
          eventLabel: data.checked,
        },
      })
    }
  }

  let generalAffiliate

  switch (orgType.toLowerCase() || type?.toString().toLowerCase()) {
    case 'inc':
      generalAffiliate = 'Inc director'
      break
    case 'llc':
    default:
      generalAffiliate = 'LLC manager'
      break
  }

  return (
    <div>
      <AgentRow>
        <Checkbox
          label="Registered Agent Service"
          checked={agentChecked}
          onChange={handleAgentFlag}
        />
        <StyledPrice>
          <h3>
            {agentPlan === 'RA-MONTHLY'
              ? `$${agentFees?.monthly}/month`
              : `$${agentFees?.yearly}/year`}
          </h3>
          <Button.Group size="mini">
            <Button
              onClick={() => handleAgentPlanSwitch('RA-MONTHLY')}
              color={agentPlan === 'RA-MONTHLY' ? 'teal' : null}
              content="Mo"
            />
            <Button
              onClick={() => handleAgentPlanSwitch('RA-YEARLY')}
              color={agentPlan === 'RA-YEARLY' ? 'teal' : null}
              content="Yr"
            />
          </Button.Group>
        </StyledPrice>
      </AgentRow>
      <Message compact style={{ lineHeight: 1.2, fontSize: 12, padding: '10px' }}>
        Not selecting this means we will use the registered agent and address provided by you (or,
        if you did not list an agent and address, the name and address of your first{' '}
        {generalAffiliate}). That person is required to be present during normal business hours to
        receive service of process (lawsuits) and important mail from the state. They'll also be
        responsible for notifying the state each time their address changes.
        <MessageHighlight>
          This cost isn't included in your total checkout cost listed above
        </MessageHighlight>
      </Message>
    </div>
  )
}

export default withRouter(Addons)
