import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Document from './DocumentListItem'
import DocumentListHeader from './DocumentListHeader'
import * as vars from 'utils/styledVariables'

const StyledDocumentList = styled.div`
  margin-bottom: 20px;
  box-shadow: ${vars.shadow};

  @media screen and (max-width: 767px) and (min-width: 480px) {
    width: 420px;
    margin: 0 auto;
  }
`

const StyledDocumentLink = styled(Link)`
  &:last-child > * {
    border-radius: 0 0 4px 4px;
    border: none;
  }
`

function DocumentList({ documents }) {
  return (
    <StyledDocumentList>
      <DocumentListHeader />
      <div>
        {documents.map(document => {
          return (
            <StyledDocumentLink to={'/document/' + document.id} key={document.id}>
              <Document document={document} />
            </StyledDocumentLink>
          )
        })}
      </div>
    </StyledDocumentList>
  )
}

export default DocumentList
