import React, { useState, useEffect, useCallback, useRef } from 'react'
import queryString from 'query-string'
import { Container, Grid, Loader } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'
import styled from 'styled-components'
import TagManager from 'react-gtm-module'
import moment from 'moment'

import PaymentTabs from 'components/Payment/PaymentTabs'
import Checkout from 'components/Payment/Checkout/Checkout'
import {
  paymentCheckCoupon,
  paymentSearchStateInfo,
  paymentSimplifiedLoad,
  paymentModeSet,
  paymentApplyDiscount,
  paymentSetCoupon,
  paymentCheckParamsAffiliate,
} from 'store/actions/payment'
import {
  calculateServiceFee,
  ACCEPTABLE_KEYWORDS,
  AFFILIATE_DISCOUNT,
  CHAMBER_DISCOUNT,
  checkAffiliate,
  checkChamber,
  transformSuffix,
} from 'utils/paymentUtils'
import { formatOrgType } from 'utils/orgType'
import { RootState } from 'store/rootState'
import axiosInstance from 'utils/axiosInstance'
import { CouponBanner } from 'components/Payment/CouponBanner'
import { usePartner, useUrlParams } from 'utils/hooks'
import { EMPTY_URL_PARAM } from 'utils/constants'
import { searchForGetOrgId } from 'utils/helpers'
import { useCancelToken } from 'utils/axiosCancelTokenHook'
import { ReducerType } from 'store/reducers'
import { getPaymentState } from 'store/sagas/selectors/payment'

const StyledCaption = styled.h1`
  font-size: 3em;
  font-weight: 900;
  color: #212b36;

  @media screen and (max-width: 480px) {
    font-size: 2em;
  }
`

export default function PaymentSimplified() {
  const dispatch = useDispatch()
  const [kwTitle, setKwTitle] = useState('Finalize')
  const [, isBetterlegal] = usePartner()
  const couponTimeDiff = useRef(null)

  const [isCountDownRunning, setIsCountDownRunning] = useState(false);
  const [affiliate, setAffiliate] = useState(null)
  const [chamber, setChamber] = useState(null)
  const [orgId, setOrgId] = useState('')
  const [price, setPrice] = useState({ serviceFee: 0, stateFee: 0 })

  const partnerInfo = useSelector((state: RootState) => state.auth.partnerInfo)
  const { fee, username } = partnerInfo

  const urlParamsString = useSelector<any, any>(state => state.router.location.search)
  const [params] = useUrlParams(urlParamsString, username)

  const stateFee = useSelector<any, any>(state => state.payment.regularStateFee)
  const couponName = useSelector<any, any>(state => state.payment.couponName)

  const { newAxiosCancelToken } = useCancelToken()

  useEffect(() => {
    dispatch(paymentModeSet('simple'))
  }, [dispatch])

  //Aff discount
  useEffect(() => {
    if (params.aff && params.aff !== EMPTY_URL_PARAM) {
      checkAffiliate(params.aff).then(affiliateFullName => {
        setAffiliate(affiliateFullName)
        dispatch(paymentApplyDiscount(AFFILIATE_DISCOUNT))
        dispatch(paymentSetCoupon(`aff-${params.aff}`))
      })
    }
  }, [dispatch, params.aff, checkAffiliate])

  //Chamber discount
  useEffect(() => {
    if (params.chamber && params.chamber !== EMPTY_URL_PARAM) {
      checkChamber(params.chamber).then(chamberName => {
        setChamber(chamberName)
        dispatch(paymentApplyDiscount(CHAMBER_DISCOUNT))
        dispatch(paymentSetCoupon(`cbr-${params.chamber}`))
      })
    }
  }, [dispatch, params.chamber, checkChamber])

  //paramsAffiliate discount
  useEffect(() => {
    const checkParamsAffiliate = (
      paramsAffiliateCode: ReducerType['payment']['paramsAffiliate']['code']
    ) => dispatch(paymentCheckParamsAffiliate(paramsAffiliateCode))

    const partnerId = params.allstate as string

    if (partnerId && partnerId !== EMPTY_URL_PARAM) {
      checkParamsAffiliate(partnerId)
    }
  }, [dispatch, params.allstate])

  //Coupon discount
  useEffect(() => {
    const checkCoupon = coupon => dispatch(paymentCheckCoupon(coupon))

    if (params.coupon && params.coupon !== EMPTY_URL_PARAM) {
      dispatch(paymentSetCoupon(params.coupon))

      if (params.couponTime && params.couponTime !== EMPTY_URL_PARAM) {
        const now = moment()
        const couponDate = moment.unix(+params.couponTime)
        const diff = moment.duration(couponDate.diff(now))
        const expired = diff?.asMilliseconds() <= 0

        if (!expired) {
          checkCoupon(params.coupon)
          couponTimeDiff.current = diff
          setIsCountDownRunning(true)
        }
      } else {
        checkCoupon(params.coupon)
      }
    }
  }, [params, dispatch])

  useEffect(() => {
    if (isCountDownRunning) {
      let myInterval = setInterval(() => {
        if (couponTimeDiff.current.asMilliseconds() > 0) {
          couponTimeDiff.current = couponTimeDiff.current.clone().subtract(1 , 'second')
        } else {
          clearInterval(myInterval)
          // reload the page if coupon expires
          window.location.reload()
        }
      }, 1000)
      return ()=> {
        clearInterval(myInterval);
      };
    }
  }, [isCountDownRunning])

  useEffect(() => {
    const serviceFee = calculateServiceFee(fee, stateFee)

    dispatch(paymentSimplifiedLoad(serviceFee))
  }, [fee, stateFee, dispatch])

  useEffect(() => {
    if (params.state && !stateFee) dispatch(paymentSearchStateInfo(params.state))
  }, [params.state, stateFee, dispatch])

  useEffect(() => {
    const parsedKw = params.kw?.toString().toLowerCase()

    if (!ACCEPTABLE_KEYWORDS.includes(parsedKw)) return

    switch (parsedKw) {
      case 'become':
      case 'set up':
        setKwTitle('Set Up')
        break

      case 'apply':
        setKwTitle('Apply For')
        break

      default:
        setKwTitle(params.kw?.toString().charAt(0).toUpperCase() + params.kw?.slice(1))
        break
    }
  }, [params.kw])

  const [stripe, setStripe] =
    useState<{ createToken: ({ name: string }) => Promise<{ token: string }> }>(null)

  useEffect(() => {
    if (window['Stripe']) {
      setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_TOKEN))
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_TOKEN))
      })
    }
  }, [])

  // TODO: change from get ID to get All org
  useEffect(() => {
    searchForGetOrgId(
      transformSuffix(params.name as string),
      params.state,
      orgId,
      newAxiosCancelToken
    ).then(orgId => setOrgId(orgId))
  }, [params.name, params.state])

  useEffect(() => {
    const urlParams = queryString.parse(urlParamsString)
    if (isBetterlegal) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'interactionEvent',
          eventCategory: 'Form',
          eventAction: 'Complete',
          eventLabel: `${formatOrgType(urlParams.type?.toString())} - ${urlParams.state}`,
        },
      })
    }
  }, []) // eslint-disable-line

  const discount = useSelector<any, any>(state => state.payment.discount)
  const { paramsAffiliate } = useSelector<any, ReducerType['payment']>(getPaymentState)

  const [loading, setLoading] = useState(true)

  const fetchPrice = useCallback(async () => {
    const state = params.state
    const type = params.orgType

    try {
      if (!state || !type) {
        throw new Error('no state or type')
      }
      const response = await axiosInstance.get(`/public/formation?state=${state}&org_type=${type}`)

      let variantBasedServiceFee: number
      let variantBasedStateFee: number

      switch (params.variant) {
        case 'SeriesMaster':
          variantBasedServiceFee = 1499
          variantBasedStateFee = response.data?.filing_fee
          break
        case 'Series':
          variantBasedServiceFee = 75
          variantBasedStateFee = 0
          break
        default:
          variantBasedServiceFee = response.data?.admin_fee
          variantBasedStateFee = response.data?.filing_fee
          break
      }

      setPrice({
        serviceFee: variantBasedServiceFee,
        stateFee: variantBasedStateFee,
      })

      setLoading(false)
    } catch (error) {}
  }, [params])

  useEffect(() => {
    if (isBetterlegal) {
      fetchPrice()
    } else {
      let variantBasedServiceFee: number
      let variantBasedStateFee: number

      switch (params.variant) {
        case 'SeriesMaster':
          variantBasedServiceFee = 1499
          variantBasedStateFee = stateFee
          break
        case 'Series':
          variantBasedServiceFee = 75
          variantBasedStateFee = 0
          break
        default:
          variantBasedServiceFee = partnerInfo.fees.filing
          variantBasedStateFee = stateFee
          break
      }

      setPrice({
        serviceFee: partnerInfo.fees?.filing > 0 ? variantBasedServiceFee : stateFee * 0.06,
        stateFee: variantBasedStateFee,
      })
      setLoading(false)
    }
  }, [params, fetchPrice, stateFee, isBetterlegal, partnerInfo.fees])

  return (
    <StripeProvider stripe={stripe}>
      <Container style={{ paddingTop: discount || affiliate ? 80 : 20 }}>
        <Grid doubling columns={2}>
          <CouponBanner
            couponCode={params.coupon?.toString()}
            couponTimeDiff={couponTimeDiff.current}
            orgType={formatOrgType(params.orgType?.toString())}
            discount={discount}
            affiliate={affiliate}
            chamber={chamber}
            couponName={couponName}
            paramsAffiliate={paramsAffiliate}
          />
          <Grid.Column width={9}>
            <StyledCaption>
              {kwTitle} {params.name} {formatOrgType(params.orgType?.toString())}
            </StyledCaption>
            <PaymentTabs simplified />
          </Grid.Column>
          <Grid.Column width={7}>
            {loading ? (
              <Loader
                inline="centered"
                active={true}
                size="large"
                style={{ marginTop: 80, marginBottom: 40 }}
              />
            ) : (
              <Checkout urlData={params} price={price} orgId={orgId} />
            )}
          </Grid.Column>
        </Grid>
      </Container>
    </StripeProvider>
  )
}
