import { Route, Switch, Redirect } from 'react-router'

import HeaderContainer from 'components/UI/Header/HeaderContainer'
import Orgs from 'components/OrgList/OrgsContainer'
import Profile from 'components/Profile/Profile'
import ProfileSimplified from 'components/Profile/ProfileSimplified'
import Payment from 'components/Payment/Payment'
import Afterpayment from 'components/Payment/Afterpayment/Afterpayment'
import NoMatch from 'components/NoMatch'
import SingleMemberLLC from 'components/LLC/SingleMemberLLC'
import MultiMemberLLC from 'components/LLC/MultiMemberLLC'
import CreateLLC from 'components/LLC/CreateLLC'
import CreateInc from 'components/LLC/CreateInc'
import ServicesRequest from 'components/LLC/ServicesRequest'
import PartnerSettings from 'components/Account/PartnerSettings'
import ForgotPassword from 'components/Account/ForgotPassword'
import ResetPassword from 'components/Account/ResetPassword'
import PartnerService from 'components/Account/PartnerService'
import Login from 'components/Account/Login'
import Welcome from 'components/UI/Welcome'
import SingleDirectorInc from 'components/LLC/SingleDirectorInc'
import MultiDirectorInc from 'components/LLC/MultiDirectorInc'
import PaymentSimplified from 'components/Payment/PaymentSimplified'
import PaymentQuick from 'components/Payment/PaymentQuick'
import ActivityList from 'components/ActivityList/ActivitiesContainer'
import Document from 'components/Document/EditDocument'
import DocumentForm from 'components/Document/DocumentForm'
import DocumentList from 'components/Document/DocumentsList'
import ConfirmFiling from 'components/Payment/ConfirmFiling'
import TestUserProfile from 'components/Profile/test/TestUserProfile'
import { PaymentDone } from 'components/Payment/PaymentDone'
import ActivityPage from 'components/Activity/ActivityPage'
import { DOMAIN } from 'utils/constants'

function IndexPage({ token, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          //@ts-ignore
          <Orgs {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/welcome',
            }}
          />
        )
      }
    />
  )
}

function ParamsAffiliateHeaderRedirect({ ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        <Redirect
          push
          to={{
            pathname: '/welcome',
            search: `allstate=${props.match.params.partnerId}`,
          }}
        />
      )}
    />
  )
}

function PrivateRoute({ component: Component, token, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

function TypeformPartnersRedirectRoute({ ...rest }) {
  const search = location?.search || ''
  const partnerDomain = `${rest.computedMatch.params.partner}.` || ''
  window.location.assign(`https://${partnerDomain}${DOMAIN}/payment${search}`)

  return <></>
}

function RoutesWithHeader({ token }) {
  return (
    <>
      <HeaderContainer />
      <Switch>
        <Route exact path="/notfound" component={NoMatch} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute token={token} exact path="/partner-settings" component={PartnerSettings} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />

        <ParamsAffiliateHeaderRedirect exact path="/allstate/:partnerId" />
        <Route exact path="/welcome" component={Welcome} />

        <Route exact path="/activities" component={ActivityList} />

        <IndexPage token={token} exact path="/" />

        <PrivateRoute token={token} exact path="/org/:id" component={Profile} />
        <PrivateRoute token={token} exact path="/org-s/:id" component={ProfileSimplified} />
        <PrivateRoute token={token} exact path="/document/:id" component={Document} />

        <PrivateRoute token={token} exact path="/documents" component={DocumentList} />

        <PrivateRoute token={token} exact path="/activity/:id" component={ActivityPage} />

        <PrivateRoute token={token} exact path="/test/org-user/:id" component={TestUserProfile} />
        <Route path="*" component={NoMatch} />
      </Switch>
    </>
  )
}

function AppRouter({ token }) {
  return (
    <>
      <Switch>
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/payment/:id" component={Payment} />
        <Route exact path="/payment-q/:id" component={PaymentQuick} />
        <Route exact path="/afterpayment/" component={Afterpayment} />
        <Route exact path="/afterpayment/:id" component={Afterpayment} />
        <Route exact path="/confirm" component={ConfirmFiling} />
        <Route exact path="/confirm/:id" component={ConfirmFiling} />
        <Route exact path="/payment-s" component={PaymentSimplified} />
        <TypeformPartnersRedirectRoute exact path="/partner-redirect/:partner" />
        <Route exact path="/payment-done" component={PaymentDone} />
        <Route exact path="/partner-service" component={PartnerService} />

        <Route exact path="/create-llc" component={CreateLLC} />
        <Route exact path="/single-member-llc" component={SingleMemberLLC} />
        <Route exact path="/multi-member-llc" component={MultiMemberLLC} />

        <Route exact path="/create-inc" component={CreateInc} />
        <Route exact path="/single-director-inc" component={SingleDirectorInc} />
        <Route exact path="/multi-director-inc" component={MultiDirectorInc} />
        <Route exact path="/services-request" component={ServicesRequest} />

        <Route exact path="/document/:id/form" component={DocumentForm} />

        <Route render={props => <RoutesWithHeader token={token} {...props} />} />
      </Switch>
    </>
  )
}

export default AppRouter
