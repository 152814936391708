import React, { useState, useEffect, useCallback } from 'react'
import { Button, Step, Segment, Container } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import queryString from 'query-string'
import Helmet from 'react-helmet'
import axios from 'axios'

import { usePartner } from 'utils/hooks'
import cookies from 'utils/cookies'

import AfterpaymentEIN from './AfterpaymentEIN'
import AfterpaymentMembers from './AfterpaymentMembers'
import AfterpaymentEmployee from './AfterpaymentEmployee'
// import AfterpaymentPlaceholder from './AfterpaymentPlaceholder'
import {
  AfterpaymetFormType,
  checkState,
  emptyForm,
  getW2withOrgId,
  mapAfterpaymentStateData,
} from './afterpaymentHelpers'
import { paymentGetOrg } from 'store/actions/payment'
import { getOrgNameWithAmpersand } from 'utils/paymentUtils'

// TODO:
// - Store all info in payment reducer
// - Check if payment reducer has info, if not — check url params

const StyledContainer = styled(Container)`
  display: flex !important;
  align-items: center;
  flex-direction: column;

  padding: 20px 0;
`

const StyledSegment = styled(Segment)`
  height: 450px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    height: auto;
  }
`

const StyledContent = styled.div`
  width: 600px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const StyledStep = styled(Step)`
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;

    max-width: 33.33%;

    & .icon {
      margin: 0 0 8px !important;
    }
  }

  @media screen and (max-width: 400px) {
    padding: 10px !important;

    & .title {
      font-size: 14px !important;
    }

    & .icon {
      font-size: 25px !important;
    }
  }
`

const AfterpaymentControls = styled(Segment)`
  display: flex;
  justify-content: center;
`

function Afterpayment(props) {
  const dispatch = useDispatch()
  const [, isBetterlegal] = usePartner()

  const [activeTab, setActiveTab] = useState('ein')
  const [isStepLast, setIsStepLast] = useState(false)
  const [employeeStep, setEmployeeStep] = useState(false)
  const [membersStep, setMembersStep] = useState(false)

  const [form, setForm] = useState<AfterpaymetFormType>(emptyForm)

  const [formsCompletion, setFormsCompletion] = useState({
    ein: false,
    employee: false,
    members: false,
  })

  useEffect(() => {
    const einCompleted = !!(form.ein.firstName && form.ein.lastName && form.ein.phone)
    const employeeCompleted = !!(
      form.employee.expectedAgriculturalEmployees &&
      form.employee.expectedEmployees &&
      form.employee.firstMonthWages &&
      form.employee.taxesLessThan1000
    )
    const membersCompleted =
      form.members.married === 'yes' ? !!form.members.llcType : !!form.members.married

    setFormsCompletion({
      ein: einCompleted,
      employee: employeeCompleted,
      members: membersCompleted,
    })
  }, [form])

  let submitEnabled = formsCompletion.ein

  if (employeeStep) submitEnabled = submitEnabled && formsCompletion.employee
  if (membersStep) submitEnabled = submitEnabled && formsCompletion.members

  const hasSteps = employeeStep || membersStep

  const {
    match: {
      params: { id: orgId },
    },
    location: { search: urlParams },
  } = props

  const { w2, W2, members, type, state, cid } = queryString.parse(urlParams)
  const name = getOrgNameWithAmpersand(urlParams)

  const orgType = type?.toString()?.toLowerCase()

  const getOrg = useCallback(id => dispatch(paymentGetOrg(id)), [dispatch])

  const paymentStateData = useSelector<any, any>(state => state.payment.data)

  // employee step check
  useEffect(() => {
    if (orgId) {
      getW2withOrgId(orgId).then(w2 => setEmployeeStep(w2))
    } else {
      setEmployeeStep(w2 === 'Yes' || W2 === 'Yes')
    }
  }, [w2, W2, orgId])

  // members step check
  useEffect(() => {
    if (orgId) {
      if (!paymentStateData.id) getOrg(orgId)

      if (paymentStateData.state_filed) {
        checkState(paymentStateData.state_filed).then(communityProperty => {
          setMembersStep(
            paymentStateData.orgType?.toLowerCase() === 'llc' &&
              paymentStateData.members.length === 2 &&
              communityProperty
          )
        })
      }
    } else {
      if (state) {
        checkState(state).then(communityProperty => {
          setMembersStep(orgType === 'llc' && +members === 2 && communityProperty)
        })
      }
    }
  }, [state, orgType, members, orgId, getOrg, paymentStateData])

  useEffect(() => {
    if (!hasSteps || activeTab === 'members') {
      setIsStepLast(true)
    } else if (activeTab === 'employee') {
      setIsStepLast(!membersStep)
    } else {
      setIsStepLast(false)
    }
  }, [setIsStepLast, activeTab, hasSteps, membersStep])

  const handleNextSlide = () => {
    if (activeTab === 'ein') {
      setActiveTab(employeeStep ? 'employee' : 'members')
    } else {
      setActiveTab('members')
    }
  }

  const submitData = async data => {
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        url: 'https://hooks.zapier.com/hooks/catch/962269/83m782/',
        data, // {partnerSite: partnername, data}
      })

      if (response.data.status === 'success') {
        const redirectURL = orgId
          ? `/confirm/${orgId}?cid=${cid}`
          : `/confirm?type=${type}&name=${name}&state=${state}&cid=${cid}`
        dispatch(push(redirectURL))
      }
    } catch (err) {
      console.error('Zapier Error: ', err)
    }
  }

  let content

  switch (activeTab) {
    case 'employee':
      content = (
        <AfterpaymentEmployee
          data={form.employee}
          updateData={value => setForm({ ...form, employee: { ...value } })}
        />
      )
      break
    case 'members':
      content = (
        <AfterpaymentMembers
          data={form.members}
          updateData={value => setForm({ ...form, members: { ...value } })}
        />
      )
      break
    case 'ein':
    default:
      content = (
        <AfterpaymentEIN
          data={form.ein}
          affiliates={orgType === 'llc' ? 'members' : 'officers'}
          updateData={value => setForm({ ...form, ein: { ...value } })}
        />
      )
      break
  }

  // if (loading)
  //   return (
  //     <StyledContainer>
  //       <AfterpaymentPlaceholder />
  //     </StyledContainer>
  //   )

  const clickId = cookies.get('betterlegalClickId')
  const isSAS = cookies.get('isSAS')

  return (
    <StyledContainer>
      <Helmet defer={false}>
        <title>Payment Successful!</title>
      </Helmet>
      <h1>Payment Successful!</h1>

      {isBetterlegal && clickId && (
        <img src={`https://click.betterlegal.com/api/conv?click=${clickId}`} alt="" />
      )}

      {isBetterlegal && clickId && +isSAS === 1 && (
        <img
          src={`https://www.shareasale.com/sale.cfm?tracking=${clickId}&amount=299&merchantID=100343&transtype=sale`}
          width="1"
          height="1"
          alt=""
        />
      )}

      <div style={{ maxWidth: '100%' }}>
        {hasSteps && (
          <Step.Group attached="top" unstackable>
            <StyledStep
              icon="file text outline"
              completed={formsCompletion.ein}
              title="EIN"
              active={activeTab === 'ein'}
              style={{ width: 220 }}
              onClick={() => setActiveTab('ein')}
            />
            {employeeStep && (
              <StyledStep
                completed={formsCompletion.employee}
                icon="address card outline"
                title="Employee"
                style={{ width: 220 }}
                active={activeTab === 'employee'}
                onClick={() => setActiveTab('employee')}
              />
            )}
            {membersStep && (
              <StyledStep
                completed={formsCompletion.members}
                icon="users"
                title="Members"
                style={{ width: 220 }}
                active={activeTab === 'members'}
                onClick={() => setActiveTab('members')}
              />
            )}
          </Step.Group>
        )}
        <StyledSegment attached padded>
          <StyledContent>{content}</StyledContent>
        </StyledSegment>
        <AfterpaymentControls attached>
          {hasSteps && !isStepLast && (
            <Button
              color={isBetterlegal ? 'teal' : null}
              content="Next"
              type="submit"
              icon="right arrow"
              labelPosition="right"
              size="large"
              onClick={() => handleNextSlide()}
            />
          )}
          <Button
            color={isBetterlegal ? 'teal' : null}
            content="Submit"
            type="submit"
            icon="send"
            labelPosition="right"
            size="large"
            disabled={!submitEnabled}
            onClick={() =>
              submitData(mapAfterpaymentStateData({ id: orgId || 'hadNoID', name, type }, form))
            }
          />
        </AfterpaymentControls>
      </div>
    </StyledContainer>
  )
}

export default Afterpayment

// export function* afterpaymentFetchSaga(action) {
//   // yield put(afterpaymentActions.afterPaymentLoadingStart())

//   try {
//     const response = yield axiosInstance.get(`/business/organization/${action.id}`)
//     yield put(afterpaymentActions.afterpaymentSearchStateInfo(response.data.state_filed))

//     yield put(afterpaymentActions.afterpaymentFetchSuccess(response.data))

//     // yield put(afterpaymentActions.afterPaymentLoadingFinish())
//   } catch (err) {
//     yield console.error(err)
//     // yield put(afterpaymentActions.afterPaymentLoadingFinish())
//   }
// }
