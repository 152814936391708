
import { Field, ErrorMessage } from 'formik'
import styled from 'styled-components'

import { CustomInput } from '../CustomFields'
import { ValidationError } from '../ValidationError'

const StyledPerson = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas:
    'firstName firstName lastName lastName shares shares . .'
    'email email email email address1 address1 address1 address1'
    'phone phone phone phone city city state zip';
  gap: 2px;

  padding: 8px;

  @media screen and (max-width: 480px) {
    grid-template-areas:
      'firstName firstName lastName lastName'
      'shares shares shares shares'
      'email email email email'
      'phone phone phone phone'
      'address1 address1 address1 address1'
      'city city state zip';
  }
`

export function EditableShareholder({ readOnly, loading, editMode }) {
  return (
    <>
      <StyledPerson>
        <Field
          disabled={loading || readOnly}
          active={editMode}
          name={'user.first_name'}
          placeholder="First name"
          component={CustomInput}
          style={{ gridArea: 'firstName' }}
        />
        <Field
          disabled={loading || readOnly}
          active={editMode}
          name={'user.last_name'}
          placeholder="Last name"
          component={CustomInput}
          style={{ gridArea: 'lastName' }}
        />

        <Field
          disabled={loading || readOnly}
          active={editMode}
          name={'fields.shares'}
          placeholder="Shares"
          component={CustomInput}
          style={{ gridArea: 'shares' }}
        />

        <Field
          disabled={loading || readOnly}
          active={editMode}
          name={'user.email'}
          placeholder="Email"
          component={CustomInput}
          style={{ gridArea: 'email' }}
        />
        <Field
          disabled={loading || readOnly}
          active={editMode}
          placeholder="Phone"
          name={'user.phone'}
          component={CustomInput}
          style={{ gridArea: 'phone' }}
        />

        <Field
          disabled={loading || readOnly}
          active={editMode}
          placeholder="Address"
          name={'address.address1'}
          component={CustomInput}
          style={{ gridArea: 'address1' }}
        />

        <Field
          disabled={loading || readOnly}
          active={editMode}
          placeholder="City"
          name={'address.city'}
          component={CustomInput}
          style={{ gridArea: 'city' }}
        />
        <Field
          disabled={loading || readOnly}
          active={editMode}
          placeholder="State"
          name={'address.province'}
          component={CustomInput}
          style={{ gridArea: 'state' }}
        />
        <Field
          disabled={loading || readOnly}
          active={editMode}
          placeholder="Zipcode"
          name={'address.zipcode'}
          component={CustomInput}
          style={{ gridArea: 'zip' }}
        />
      </StyledPerson>

      <ul style={{ padding: '0 5px', listStyle: 'none' }}>
        <ErrorMessage name="address.address1" component={ValidationError} />
        <ErrorMessage name="address.city" component={ValidationError} />
        <ErrorMessage name="address.province" component={ValidationError} />
        <ErrorMessage name="address.zipcode" component={ValidationError} />
        <ErrorMessage name="user.first_name" component={ValidationError} />
        <ErrorMessage name="user.last_name" component={ValidationError} />
        <ErrorMessage name="user.email" component={ValidationError} />
        <ErrorMessage name="user.phone" component={ValidationError} />
      </ul>
    </>
  )
}
