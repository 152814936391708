import _ from 'lodash'
import axiosInstance from 'utils/axiosInstance'
import * as Yup from 'yup'

export const afterpaymentMembersSchema = Yup.object().shape({
  married: Yup.string().required('Required'),
  llcType: Yup.string(),
})

const nameRegex = /^[a-zA-Z\-&\s]+$/g

export const afterpaymentEINSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .matches(nameRegex, { message: 'The only special characters allowed are - or &.' }),
  lastName: Yup.string()
    .matches(nameRegex, { message: 'The only special characters allowed are - or &.' })
    .required('Required'),
  suffix: Yup.string(),
  phone: Yup.string().required('Required'),
  ssn: Yup.string(),
})

export const afterpaymentEmployeeSchema = Yup.object().shape({
  firstMonthWages: Yup.string().required('Required'),
  expectedAgriculturalEmployees: Yup.number()
    .min(0, 'Should be positive or 0')
    .integer('Should be integer')
    .required('Required'),
  expectedEmployees: Yup.number()
    .min(0, 'Should be positive or 0')
    .integer('Should be integer')
    .required('Required'),
  taxesLessThan1000: Yup.string().required('Required'),
})

export interface AfterpaymentEINType {
  firstName: string
  lastName: string
  suffix: string
  phone: string
  ssn: string
}

export interface AfterpaymentEmployeeType {
  firstMonthWages: string
  expectedAgriculturalEmployees: string
  expectedEmployees: string
  taxesLessThan1000: 'yes' | 'no' | ''
}

export interface AfterpaymentMembersType {
  married: 'yes' | 'no' | ''
  llcType: 'multi' | 'single' | ''
}

export interface AfterpaymetFormType {
  ein: AfterpaymentEINType
  employee: AfterpaymentEmployeeType
  members: AfterpaymentMembersType
}

export const emptyForm: AfterpaymetFormType = {
  ein: {
    firstName: '',
    lastName: '',
    suffix: '',
    phone: '',
    ssn: '',
  },
  employee: {
    firstMonthWages: '',
    expectedAgriculturalEmployees: '',
    expectedEmployees: '',
    taxesLessThan1000: '',
  },
  members: {
    married: '',
    llcType: '',
  },
}

export const mapAfterpaymentStateData = (org, form) => {
  return {
    orgName: org.name,
    orgId: org.id,
    orgType: org.type,
    EIN: {
      firstName: form.ein.firstName,
      lastName: form.ein.lastName,
      suffix: form.ein.suffix,
      phone: form.ein.phone,
      ssn: form.ein.ssn
    },
    employee: {
      empFirstMonthWages: form.employee.firstMonthWages,
      empAgricultural: form.employee.expectedAgriculturalEmployees,
      empOther: form.employee.expectedEmployees,
      empExpectLow: form.employee.taxesLessThan1000
    },
    members: {
      membersMarried: form.members.married,
      membersType: form.members.llcType
    }
  }
}


export const checkState = async state => {
  try {
    const response = await axiosInstance.get('/payment/state/' + state)

    return response.data.community_property
  } catch (err) {
    console.error(err)
  }
}

export const getW2withOrgId = async id => {
  try {
    const response = await axiosInstance.get('/public/activity', {
      params: { type: 'Filing', org_id: id },
    })

    const activities = response.data.results
    if (activities.length === 0) return false
    const EINActivity = _.find(activities, _.matchesProperty('activity', 'EIN'))
    return EINActivity.fields.w2 === 'True'
  } catch (err) {
    console.log(err)
  }
}