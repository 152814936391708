import axios from 'axios'

import getPartner from 'utils/getPartner'
import cookies from 'utils/cookies'

const partner = getPartner(window.location.hostname)

const axiosInstanceCustom = (serverURL: string = process.env.REACT_APP_API_URL) => {
  const token = cookies.get('jwt')

  return axios.create({
    baseURL: serverURL,
    headers: {
      common: { 'partner-site': partner },
      ...(token && { Authorization: `Token ${token}` }),
    },
  })
}
export default axiosInstanceCustom
