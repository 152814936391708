import { useEffect, useState } from 'react'
import { Feed, Loader } from 'semantic-ui-react'
import { Scrollbars } from 'react-custom-scrollbars'

import { ProfileEditActivityModal } from './ProfileEditActivityModal'
import { Activity } from 'components/Activity/Activity'
import { fetchActivities } from 'components/UI/orgHelpers'
import { ProfileAddActivityModal } from './ProfileAddActivityModal'

// const StyledSearch = styled(Input)`
//   width: 100%;
// `

type ActivityStatus = 'initial' | 'loading' | 'loaded'

function ProfileActivities({ orgData, userRights, addModalActive, setAddModalActive }) {
  const [activities, setActivities] = useState([])
  const [modalActive, setModalActive] = useState(false)
  const [activeActivity, setActiveActivity] = useState(null)
  const [activitiesStatus, setActivitiesStatus] = useState<ActivityStatus>('initial')

  const refetchActivities = () => {
    setActivitiesStatus('loading')
    fetchActivities(orgData.id)
      .then(result => setActivities(result))
      .finally(() => setActivitiesStatus('loaded'))
  }

  useEffect(() => {
    setActivitiesStatus('loading')
    fetchActivities(orgData.id)
      .then(result => setActivities(result))
      .finally(() => setActivitiesStatus('loaded'))

    // if (ws && ws?.readyState === 1) {
    //   ws.send(JSON.stringify({ type: webSocketConstants.FETCH_ACTIVITIES, payload: {} }))
    // }
  }, [orgData])

  const filteredActivities = userRights.seeFilingActivity
    ? activities
    : activities.filter(activity => activity.activity_type !== 'Filing')

  if (activitiesStatus === 'loading')
    return (
      <div style={{ height: 100 }}>
        <Loader active>Loading</Loader>
      </div>
    )

  return (
    <div>
      <ProfileAddActivityModal
        userRights={userRights}
        orgId={orgData.id}
        refetchActivities={refetchActivities}
        active={addModalActive}
        setAddModalActive={setAddModalActive}
      />
      <ProfileEditActivityModal
        userRights={userRights}
        afterSubmit={refetchActivities}
        data={activeActivity}
        active={modalActive}
        setModalActive={setModalActive}
        onClose={() => setModalActive(false)}
      />
      {/* <StyledSearch icon="search" placeholder="Search an activity" /> */}
      <Scrollbars autoHeight autoHeightMin={300} autoHeightMax={1200} autoHide>
        <Feed>
          {filteredActivities &&
            filteredActivities.map(activity => {
              return (
                <Activity
                  key={activity.id}
                  data={activity}
                  userRights={userRights}
                  setData={setActiveActivity}
                  setModalActive={setModalActive}
                />
              )
            })}
        </Feed>
      </Scrollbars>
    </div>
  )
}

export default ProfileActivities
