type UserRightsType = {
  [K in UserRole]: UserRights
}

export const userRights: UserRightsType = {
  super_admin: {
    editInfo: true,
    seeFilingActivity: true,
    controlActivities: true,
    extendedMenu: true,
    partnerSettings: true,
    sidebarMessage: true,
    orgNote: true,
    seeConfirmedStatus: true,
    uploadDocument: true,
    removeDocument: true
  },
  admin: {
    editInfo: true,
    seeFilingActivity: false,
    controlActivities: true,
    extendedMenu: true,
    partnerSettings: true,
    sidebarMessage: true,
    orgNote: true,
    seeConfirmedStatus: true,
    uploadDocument: false,
    removeDocument: false
  },
  user: {
    editInfo: false,
    seeFilingActivity: false,
    controlActivities: false,
    extendedMenu: false,
    partnerSettings: false,
    sidebarMessage: false,
    orgNote: false,
    seeConfirmedStatus: false,
    uploadDocument: false,
    removeDocument: false
  },
  public: { // only used on checkout page
    editInfo: true,
    seeFilingActivity: false,
    controlActivities: false,
    extendedMenu: false,
    partnerSettings: false,
    sidebarMessage: false,
    orgNote: false,
    seeConfirmedStatus: false,
    uploadDocument: false,
    removeDocument: false
  }
}

type UserRole = 'super_admin' | 'user' | 'admin' | 'public' | null

export interface UserRights {
  editInfo: boolean
  seeFilingActivity: boolean
  controlActivities: boolean
  extendedMenu: boolean
  partnerSettings: boolean
  sidebarMessage: boolean
  orgNote: boolean
  seeConfirmedStatus: boolean
  uploadDocument: boolean
  removeDocument: boolean
}

export function createUserRightsObject(userRole: UserRole = 'user'): UserRights {
  return userRights[userRole]
}