
import { Container, Segment, Form, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'

import { passwordForgotSubmit } from 'store/actions/password'

function ForgotPassword(props) {
  return (
    <Container>
      <Segment padded="very">
        <h1>Forgot Password?</h1>
        <Formik initialValues={{ email: '' }} onSubmit={values => props.submit(values.email)}>
          {({ handleSubmit }) => (
            <Form>
              <Form.Group>
                <label>Email</label>
              </Form.Group>
              <Form.Group>
                <Field name="email" placeholder="Email" />
              </Form.Group>

              <Button type="submit" content="Submit" onClick={() => handleSubmit()} />
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  )
}

const mapDispatchToProps = dispatch => ({
  submit: email => dispatch(passwordForgotSubmit(email))
})

export default connect(
  null,
  mapDispatchToProps
)(ForgotPassword)
