import { useEffect, useState } from 'react'
import { Modal, Icon, Form, Dropdown, Button, Loader } from 'semantic-ui-react'
import { Formik, Field, FieldArray } from 'formik'
import styled from 'styled-components'
import _ from 'lodash'
import { toast } from 'react-toastify'

import axiosInstance from 'utils/axiosInstance'
import { useCancelToken } from 'utils/axiosCancelTokenHook'

const ModalWrapper = styled.div`
  padding: 20px;
`

export function ProfileAddActivityModal({
  orgId,
  userRights,
  refetchActivities,
  active,
  setAddModalActive,
}) {
  const [activityTypes, setActivityTypes] = useState([])
  const [loading, setLoading] = useState<boolean>(false)

  const { newAxiosCancelToken } = useCancelToken()

  const fetchActivityTypes = async () => {
    const response = await axiosInstance.get('/activity/type', {
      cancelToken: newAxiosCancelToken(),
    })

    return response.data.results
  }

  useEffect(() => {
    fetchActivityTypes().then(types => {
      const options = types?.map(type => ({
        key: type.id,
        text: type.name,
        value: type.id,
      }))

      setActivityTypes(options)
    })
  }, [])

  const submitActivity = async data => {
    try {
      const response = await axiosInstance.post('/activity', data)

      return response
    } catch (error) {
      toast.error('error')
    }
  }

  return (
    <>
      {userRights.controlActivities && (
        <Modal open={active} closeIcon onClose={() => setAddModalActive(false)} size="small">
          <ModalWrapper>
            <h1 style={{ margin: '0 0 30px' }}>Add an activity</h1>
            <Formik
              initialValues={{
                name: '',
                typeId: '',
                fields: [],
              }}
              onSubmit={async values => {
                submitActivity({
                  type_id: values.typeId,
                  name: values.name,
                  org_id: orgId,
                  fields: _.fromPairs(values.fields), // TODO: change array of pairs to object
                })
                  .then(response => {
                    setAddModalActive(false)
                    if (response?.data?.id) toast.success('Activity added')
                    refetchActivities()
                  })
                  .finally(() => {
                    setLoading(false)
                  })
              }}
            >
              {({ values, handleSubmit, setFieldValue }) => (
                <Form>
                  <Form.Field required>
                    <label>Activity Type</label>
                    <Dropdown
                      placeholder="Select Type"
                      fluid
                      selection
                      options={activityTypes}
                      onChange={(_, option) => setFieldValue('typeId', option.value)}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>Name</label>
                    <Field type="text" name="name" placeholder="Name" />
                  </Form.Field>

                  <FieldArray
                    name="fields"
                    render={arrayHelpers => {
                      return (
                        <Form.Field required>
                          <label>Fields</label>
                          {values.fields.map((field, index) => {
                            return (
                              <Form.Group key={index} style={{ margin: '0 0 10px' }}>
                                <Field name={`fields[${index}][0]`} placeholder="Field Name" />
                                <Field
                                  name={`fields.${index}][1]`}
                                  placeholder="Field Value"
                                  style={{ margin: '0 10px' }}
                                />

                                <Button
                                  icon="trash alternate"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </Form.Group>
                            )
                          })}

                          <Form.Button onClick={() => arrayHelpers.push(['', ''])}>
                            Add fields
                          </Form.Button>
                        </Form.Field>
                      )
                    }}
                  />

                  <Form.Field>
                    <Form.Button
                      floated="right"
                      color="teal"
                      style={{ margin: '50px auto 20px' }}
                      onClick={() => {
                        setLoading(true)
                        handleSubmit()
                      }}
                      loading={loading}
                      disabled={loading}
                    >
                      Submit
                    </Form.Button>
                  </Form.Field>
                </Form>
              )}
            </Formik>
          </ModalWrapper>
        </Modal>
      )}
    </>
  )
}
