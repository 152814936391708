import Iframe from 'react-iframe'
import queryString from 'query-string'

import { formatOrgType } from 'utils/orgType'

function PartnerService(props) {
  const { org_id, org_name, org_type, state } = queryString.parse(props.location.search)

  return (
    <Iframe
      url={`https://form.typeform.com/to/ODrVHwol?typeform-medium=embed-snippet#org_id=${org_id}&org_name=${org_name}&org_type=${formatOrgType(
        org_type.toString()
      )}&state=${state}`}
      width="100%"
      height="100%"
    />
  )
}

export default PartnerService
