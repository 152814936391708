import axios from 'axios'

import getPartner from 'utils/getPartner'
import cookies from 'utils/cookies'

const token = cookies.get('jwt')
const partner = getPartner(window.location.hostname)

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { common: { 'partner-site': partner } },
})

if (token) axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`

export default axiosInstance
