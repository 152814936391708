import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import styled from 'styled-components'
import { darken } from 'polished'
import { Icon, Popup } from 'semantic-ui-react'

import OrgCol from './OrgCol'
import { OrgStatus } from 'components/UI/OrgStatus'

import { usePartner } from 'utils/hooks'
import { createUserRightsObject, userRights } from 'utils/userRights'
import { useSelector } from 'react-redux'

const StyledOrg = styled.div<{ customPartner: boolean }>`
  display: grid;
  grid-template-columns: 100px 50px 1fr 150px 120px 150px 150px;
  align-items: center;
  grid-column-gap: 20px;

  width: 100%;
  min-height: 50px;
  padding: 5px 20px;

  position: relative;

  background-color: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #eff1f6;

  color: #555;

  cursor: pointer;

  &:hover {
    background-color: ${darken(0.015, '#fff')};
    border-top: 1px solid;
    border-color: ${darken(0.1, '#eff1f6')};
  }

  &:after {
    content: '';
    display: ${p => (p.customPartner ? 'block' : 'none')};

    width: 10px;
    height: 100%;

    position: absolute;
    left: -10px;
    top: 0;

    background-color: #2285d0;
    border-radius: 5px 0 0 5px;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: 75px 50px 1fr repeat(4, 1fr);
    font-size: 13px;
  }

  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    height: auto;
    width: 400px;
    margin: 0 auto;
    padding: 10px 15px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const IncIcon = styled.strong`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  border: 1px solid currentColor;
  border-radius: 50%;

  font-size: 12px;
  font-weight: 900;
`

function formatDateToChicagoTimeZone(dateString) {
  return dateString ? moment.parseZone(dateString).utcOffset(-5).format('M/D/YYYY h:mm A') : null
}

function renderTypeIcon(orgData) {
  const orgType = orgData?.type?.toLowerCase()
  let type

  if (orgType === 'llc') type = orgData.fields?.members_type
  if (orgType === 'inc') type = orgData.fields?.corporation_type
  if (orgType === 'sole') return null

  switch (type) {
    case 'Multi Member':
      return <Icon name="users" color="grey" />
    case 'Single Member':
      return <Icon name="user" style={{ color: '#35978b' }} />
    case 'S Corp':
      return <IncIcon>S</IncIcon>
    case 'C Corp':
      return <IncIcon>C</IncIcon>
    default:
      return <em style={{ color: 'tomato' }}>wrong</em>
  }
}

const Org = ({ org }) => {
  const [partner, isBetterlegal] = usePartner()
  const { userData } = useSelector<any, any>(state => state.auth)

  const userRights = createUserRightsObject(userData?.role)

  const orgType =
    org.note && isBetterlegal && userRights.orgNote ? (
      <>
        <Popup
          trigger={
            <Icon
              name="exclamation triangle"
              color="yellow"
              style={{ position: 'relative', top: -2 }}
            />
          }
          content={org.note}
          basic
        />
        {org.type}
      </>
    ) : (
      org.type
    )

  return (
    <StyledOrg customPartner={org.partner.username !== partner}>
      <OrgCol name="structure" value={orgType} />
      <OrgCol name="type" value={renderTypeIcon(org)} />
      <OrgCol name="name" value={org.name} bold />
      <OrgCol name="state" value={org.state_filed} />
      <OrgCol name="status" value={<OrgStatus status={org.status} />} />
      <OrgCol name="created" value={formatDateToChicagoTimeZone(org.date_created)} />
      <OrgCol name="paid" value={formatDateToChicagoTimeZone(org.date_paid)} />
    </StyledOrg>
  )
}

export default React.memo(Org)
