import React, { useCallback } from 'react'
import { Dropdown } from 'semantic-ui-react'

import { isProduction } from 'utils/isProduction'
import { OrgStatus } from '../OrgStatus'

const statuses = [
  {
    text: 'DRAFT',
    value: 'Draft',
  },
  {
    text: 'IN PROGRESS',
    value: 'In Progress',
  },
  {
    text: 'FILED',
    value: 'Filed',
  },
  {
    text: 'REFILE',
    value: 'Refile',
  },
  {
    text: 'COMPILE DOCS',
    value: 'Compile Docs',
  },
  {
    text: 'ACTIVE',
    value: 'Active',
  },
  {
    text: 'INACTIVE',
    value: 'InActive',
  },
]

function EditableStatus({ data, readOnly, wsHandler, orgId }) {
  const sendStatusHook = useCallback(
    async data => {
      if (isProduction()) {
        try {
          const hookData = {
            organizationId: orgId,
            status: data,
          }

          fetch('https://hooks.zapier.com/hooks/catch/962269/lcwf5t/', {
            method: 'POST',
            body: JSON.stringify(hookData),
          })
        } catch (err) {
          console.error('Zapier Error: ', err)
        }
      }
    },
    [orgId]
  )

  const handleFieldChange = (_, d) => {
    if (data !== d.value) {
      wsHandler({ status: d.value })
      sendStatusHook(d.value)
    }
  }

  return (
    <Dropdown
      trigger={<OrgStatus status={data} />}
      placeholder="Select Status"
      disabled={readOnly}
      value={data}
      inline
      floating
      options={statuses}
      onChange={(_, d) => handleFieldChange(_, d)}
    />
  )
}

export default EditableStatus
