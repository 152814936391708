
import styled from 'styled-components'

import ProfileBtn from '../ProfileBtn'

const StyledDoc = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDocTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
`

function Document({ data }) {
  return (
    <StyledDoc>
      <div>
        <StyledDocTitle>{data.name}</StyledDocTitle>
      </div>
      <div style={{ width: '100px' }}>
        <a href={data.path} target="_blank noopener noreferrer">
          <ProfileBtn caption="View" />
        </a>
        {/* <ProfileBtn grey caption="Preview" /> */}
      </div>
    </StyledDoc>
  )
}

export default Document
