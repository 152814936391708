import React, { useEffect, useState } from 'react'
import { Container, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import queryString from 'query-string'
import { fetchOrg } from 'store/slices/orgSlice'

import ActivitiesList from './ActivitiesList'
import ActivitiesListFilters from './ActivitiesListFilters'
import ActivitySidebar from './ActivitySidebar/ActivitySidebar'

import { usePartner } from 'utils/hooks'
import axiosInstance from 'utils/axiosInstance'
import { createUserRightsObject } from 'utils/userRights'

const StyledActivitiesListControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
`

const StyledContainer = styled(Container)`
  margin-bottom: 50px;

  position: relative;

  @media screen and (max-width: 1200px) {
    &.ui.container {
      width: 100%;
      padding: 0 15px;
    }
  }
`

const StyledActivitySidebar = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 1rem;
`

function ActivitiesContainer(props) {
  const dispatch = useDispatch()
  const [activities, setActivities] = useState([])
  const [statuses, setStatuses] = useState('')
  const [filter, setFilter] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [hasNextPage, setHasNextPage] = useState(true)
  const [currentPage, setCurrentPage] = useState(() => {
    const page = +queryString.parse(props.location.search).page

    const isValid = Boolean(page) && Number.isInteger(page) && page > 0

    if (isValid) {
      return page
    }

    return 1
  })

  const [selectedActivity, setSelectedActivity] = useState({ id: null })

  const { userData } = useSelector<any, any>(state => state.auth)

  const userRights = createUserRightsObject(userData?.role)

  const [, isBetterlegal] = usePartner()

  const activeOrg = useSelector<any, any>(state => state.org.activeOrg)

  useEffect(() => {
    async function fetchActivities() {
      dispatch(showLoading())
      const response = await axiosInstance({
        method: 'GET',
        url: '/activity',
        params: {
          ordering: '-date_created',
          page: currentPage,
          page_size: 50,
          ...(filter ? { org_status: filter } : {}),
          ...(searchQuery ? { name: searchQuery } : {}),
        },
      })

      setHasNextPage(response.data.results.length === 50)
      setActivities(response.data.results)

      setStatuses(response.data.statuses)
      dispatch(hideLoading())
    }

    fetchActivities()
  }, [dispatch, filter, searchQuery])

  function fetchOrdData(ordId) {
    dispatch(fetchOrg(ordId))
  }

  const loadMore = async () => {
    dispatch(showLoading())
    const response = await axiosInstance({
      method: 'GET',
      url: '/activity',
      params: {
        ordering: '-date_created',
        page: currentPage + 1,
        page_size: 50,
        ...(filter ? { org_status: filter } : {}),
        ...(searchQuery ? { name: searchQuery } : {}),
      },
    })

    setHasNextPage(response.data.results.length === 50)
    setCurrentPage(currentPage + 1)
    setActivities(activities.concat(response.data.results))

    setStatuses(response.data.statuses)
    dispatch(hideLoading())
  }

  function updateActivity(activity) {
    const index = activities.findIndex(ac => ac.id === activity.id)

    const updatedActivities = [
      ...activities.slice(0, index),
      activity,
      ...activities.slice(index + 1),
    ]

    setActivities(updatedActivities)
    onActivityClick(activity)
  }

  function onActivityClick(activity) {
    if (activity.org?.id) {
      fetchOrdData(activity.org?.id)
    }
    setSelectedActivity(activity)
  }

  const filteredActivities = userRights.seeFilingActivity
    ? activities
    : activities.filter(activity => activity.activity_type !== 'Filing')

  return (
    <>
      <StyledContainer>
        {userRights.extendedMenu && (
          <StyledActivitiesListControls>
            <ActivitiesListFilters
              statuses={statuses}
              activeFilter={filter}
              setFilter={setFilter}
            />
          </StyledActivitiesListControls>
        )}
        <Grid doubling columns={2} reversed="computer">
          <Grid.Column width={9}>
            <StyledActivitySidebar>
              <ActivitySidebar
                org={activeOrg}
                selectedActivity={selectedActivity}
                updateActivity={updateActivity}
              />
            </StyledActivitySidebar>
          </Grid.Column>
          <Grid.Column width={7}>
            <ActivitiesList
              activities={filteredActivities}
              onLoadMore={() => loadMore()}
              onActivityClick={onActivityClick}
              isBetterlegal={isBetterlegal}
              hasNextPage={hasNextPage}
              searchFn={setSearchQuery}
              selectedActivityId={selectedActivity.id}
            />
          </Grid.Column>
        </Grid>
      </StyledContainer>
    </>
  )
}

export default ActivitiesContainer
