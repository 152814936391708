import { Elements } from 'react-stripe-elements'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import StripeFormLite from './StripeFormLite'
import StripeForm from './StripeForm'
import Bill from './Bill'
import Addons from './Addons'
import Badges from './Badges'
import { CallBanner } from 'components/UI/CallBanner'
import { PaymentUrlParamsType } from 'components/Payment/paymentUrlParams'

const CheckoutCaption = styled.h2`
  margin: 10px 0 0;

  font-size: 28px;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`

const CheckoutWrapper = styled.div`
  display: grid;
  grid-row-gap: 5px;

  padding: 15px;

  background-color: #fff;
  border-radius: 5px;
`

export function LightCheckout({ stateFee, serviceFee, stateFiled, modalHandler, ...props }) {
  return (
    <>
      <CheckoutWrapper>
        <CheckoutCaption>One Time Fees</CheckoutCaption>
        <Bill
          totalPrice={+(stateFee + serviceFee).toFixed(0)}
          stateFiled={stateFiled}
          serviceFee={serviceFee}
          stateFee={stateFee}
        />

        <CheckoutCaption>Payment</CheckoutCaption>
        <Elements>
          <StripeFormLite {...props} amount={serviceFee + stateFee} modalHandler={modalHandler} />
        </Elements>
      </CheckoutWrapper>
    </>
  )
}

interface CheckoutProps {
  urlData: PaymentUrlParamsType
  price: { serviceFee: number; stateFee: number }
  orgId: string
  stripe?: { createToken: ({ name: string }) => Promise<{ token: string }> }
}

function Checkout(props: CheckoutProps) {
  const paymentState = useSelector<any, any>(state => state.payment)

  const { price } = props

  const urlState = props.urlData && props.urlData.state

  const serviceFee = +price.serviceFee?.toFixed(0)
  const stateFee = +price.stateFee?.toFixed(0)
  const discount = +paymentState?.discount?.toFixed(0) || 0

  return (
    <>
      <CallBanner rounded />
      <CheckoutWrapper>
        <CheckoutCaption>One Time Fees</CheckoutCaption>
        <Bill
          serviceFee={serviceFee}
          stateFee={stateFee}
          discount={discount}
          stateFiled={paymentState?.data?.state_filed || urlState}
          {...props}
        />

        <CheckoutCaption>Optional Add-ons</CheckoutCaption>
        <Addons />

        <CheckoutCaption>Payment</CheckoutCaption>
        <Elements>
          <StripeForm {...props} amount={serviceFee + stateFee - discount} />
        </Elements>
      </CheckoutWrapper>
      <Badges />
    </>
  )
}

export default Checkout
