
import styled from 'styled-components'

import OrgCol from './OrgCol'

const StyledOrgListHeader = styled.div`
  display: grid;
  grid-template-columns: 100px 50px 1fr 150px 120px 150px 150px;
  align-items: center;
  grid-column-gap: 20px;

  width: 100%;
  height: 50px;
  padding: 5px 20px;

  border-radius: 4px 4px 0 0;
  background-color: #f7f8fb;

  font-size: 13px;
  color: #9b9b9c;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 75px 50px 1fr repeat(4, 1fr);
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`

const columns = ['structure', 'type', 'name', 'state', 'status', 'created', 'paid']

const OrgListHeader = () => {
  return (
    <StyledOrgListHeader>
      {columns.map(column => (
        <OrgCol key={column} value={column} header />
      ))}
    </StyledOrgListHeader>
  )
}

export default OrgListHeader
