export const SIGN_OFF = 'SIGN_OFF'
export const LOGOUT = 'LOGOUT'

// REGISTERED AGENT

export const REGISTERED_AGENT_PUT_SUCCESS = 'REGISTERED_AGENT_PUT_SUCCESS'

// PAYMENT

export const PAYMENT_GET_ORG = 'PAYMENT_GET_ORG'
export const PAYMENT_GET_ORG_START = 'PAYMENT_GET_ORG_START'
export const PAYMENT_GET_ORG_SUCCESS = 'PAYMENT_GET_ORG_SUCCESS'
export const PAYMENT_GET_ORG_FAIL = 'PAYMENT_GET_ORG_FAIL'

export const PAYMENT_MODE_SET = 'PAYMENT_MODE_SET'

export const PAYMENT_SEARCH_ORG = 'PAYMENT_SEARCH_ORG'
export const PAYMENT_SEARCH_ORG_START = 'PAYMENT_SEARCH_ORG_START'
export const PAYMENT_SEARCH_ORG_SUCCESS = 'PAYMENT_SEARCH_ORG_SUCCESS'
export const PAYMENT_SEARCH_ORG_FAIL = 'PAYMENT_SEARCH_ORG_FAIL'

export const PAYMENT_SEARCH_STATE_INFO = 'PAYMENT_SEARCH_STATE_INFO'
export const PAYMENT_GET_STATE_INFO = 'PAYMENT_GET_STATE_INFO'
export const PAYMENT_CHECK_COUPON = 'PAYMENT_CHECK_COUPON'

export const PAYMENT_APPLY_DISCOUNT = 'PAYMENT_APPLY_DISCOUNT'
export const PAYMENT_SET_COUPON = 'PAYMENT_SET_COUPON'

export const PAYMENT_PROCESS_PAYMENT = 'PAYMENT_PROCESS_PAYMENT'
export const PAYMENT_PROCESS_PAYMENT_START = 'PAYMENT_PROCESS_PAYMENT_START'
export const PAYMENT_PROCESS_PAYMENT_SUCCESS = 'PAYMENT_PROCESS_PAYMENT_SUCCESS'
export const PAYMENT_PROCESS_PAYMENT_FAIL = 'PAYMENT_PROCESS_PAYMENT_FAIL'

export const PAYMENT_CHECK_PARAMS_AFFILIATE = 'PAYMENT_CHECK_PARAMS_AFFILIATE'
export const PAYMENT_SET_PARAMS_AFFILIATE = 'PAYMENT_SET_PARAMS_AFFILIATE'

export const PAYMENT_SET_REGISTERED_AGENT = 'PAYMENT_SET_REGISTERED_AGENT'
export const PAYMENT_SWITCH_REGISTERED_AGENT_TYPE = 'PAYMENT_SWITCH_REGISTERED_AGENT_TYPE'

export const PAYMENT_SIMPLIFIED_LOAD = 'PAYMENT_SIMPLIFIED_LOAD'
export const PAYMENT_UPDATE_SPECIAL_REQUEST = 'PAYMENT_UPDATE_SPECIAL_REQUEST'

export const ADD_COUPON_NAME = 'ADD_COUPON_NAME'

// PASSWORD

export const PASSWORD_FORGOT_SUBMIT = 'PASSWORD_FORGOT_SUBMIT'
export const PASSWORD_RESET_SUBMIT = 'PASSWORD_RESET_SUBMIT'

// NEW EDIT INFO

export const NEW_EDIT_INFO_SUBMIT = 'NEW_EDIT_INFO_SUBMIT'
export const NEW_EDIT_INFO_UPDATE_PAYMENT = 'NEW_EDIT_INFO_UPDATE_PAYMENT'
export const NEW_EDIT_INFO_UPDATE_PROFILE = 'NEW_EDIT_INFO_UPDATE_PROFILE'

export const NEW_EDIT_AGENT_SUBMIT = 'NEW_EDIT_AGENT_SUBMIT'
export const NEW_EDIT_AGENT_UPDATE = 'NEW_EDIT_AGENT_UPDATE'

export const NEW_EDIT_AFFILIATE_SUBMIT = 'NEW_EDIT_AFFILIATE_SUBMIT'
export const NEW_EDIT_AFFILIATE_UPDATE = 'NEW_EDIT_AFFILIATE_UPDATE'
