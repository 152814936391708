import React, { useState } from 'react'
import { Input } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledActivitySearch = styled.div`
  width: 100%;
  padding: 5px 10px;

  border-bottom: 1px solid #eff1f6;

  div {
    width: 100%;

    input {
      border: none !important;
    }
  }
`

function ActivityListSearch({ searchFn }) {
  const [value, setValue] = useState('')

  const keydownEvent = e => {
    if (e.key === 'Enter') {
      searchFn(value)
    }
  }

  return (
    <StyledActivitySearch>
      <Input
        icon="search"
        color="grey"
        iconPosition="left"
        placeholder="Search activities"
        action
        onChange={e => setValue(e.target.value)}
        value={value}
        onKeyDown={keydownEvent}
      />
    </StyledActivitySearch>
  )
}

export default ActivityListSearch
