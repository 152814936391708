function getPartner(url) {
  const partner = 'betterlegal'
  // const regex = /(?:https?:\/\/)?(?:stage-)?(.*?)\.betterlegal.com/g if need href
  const regex = /((?:stage-)|(?:dev-))?(.*?)\.betterlegal.com/g

  const match = regex.exec(url)

  return match && match[2] !== 'app' ? match[2] : partner
}

export default getPartner
