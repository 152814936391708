import React, { useState } from 'react'
import axios from 'axios'

import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'
import _ from 'lodash'

const StyledDropdown = styled.div<{ hasValue?: boolean }>`
  width: 100%;

  .ui.dropdown {
    padding: 8px 10px !important;
    min-height: auto;
    height: 32px;

    border-radius: 2px !important;
    border: 1px solid #f5f5f5;

    font-size: 13px;

    transition: 0s !important;

    &:hover {
      border-color: #36978b;

      outline: none;
    }

    > .dropdown.icon {
      margin: 0;

      top: 50%;
      right: 0;

      transform: translateY(-50%);
    }

    & input {
      padding-left: 10px !important;
    }

    :not(.button) > .default.text {
      color: ${p => (p.hasValue ? 'rgba(0, 0, 0, 1)' : 'rgba(115,115,115,0.87)')};
    }
  }
`

export function AddressAutocompleteDropdown({
  wsHandler,
  readOnly,
  placeholder,
  value,
  style,
  isDefault,
  defaultOption,
}) {
  const [dropdownOptions, setdropdownOptions] = useState([])
  const [open, setopen] = useState(false)
  const [searchQuery, setsearchQuery] = useState('')

  const valueToDisplay = isDefault ? defaultOption.text : value

  const handleSearchChange = value => {
    setsearchQuery(value.searchQuery)
    if (value.searchQuery) {
      axios({
        method: 'post',
        url: 'https://api.lob.com/v1/us_autocompletions',
        data: {
          address_prefix: value.searchQuery,
        },
        auth: {
          username: 'live_pub_e0109672bad3eabb6771a5cb93768af',
          password: '',
        },
      })
        .then(res => {
          const { suggestions } = res.data

          const newOptions = suggestions.map(s => {
            const str = `${s.primary_line} ${s.city} ${s.state} ${s.zip_code}`
            return { key: str, value: str, text: str, lob: s }
          })

          setdropdownOptions(newOptions)
        })
        .catch(e => console.log(e.message))
    }
  }

  const handleFieldChange = (_, d) => {
    if (d.value && value !== d.value) {
      wsHandler(null)
    }
    setopen(false)
  }

  const handleFocus = e => {
    e.target.setAttribute('autocomplete', 'nope')
  }

  const onSearchChange = (_, d) => {
    if (handleSearchChange) {
      handleSearchChange(d)
    }
  }

  const handleAddressSelect = option => {
    const lobAddress = option.lob

    const updatedAddress = {
      address1: lobAddress.primary_line,
      address2: '',
      city: lobAddress.city,
      country: 'US',
      county: '',
      province: lobAddress.state,
      zipcode: lobAddress.zip_code,
    }

    wsHandler(updatedAddress)
    setopen(false)
    setsearchQuery('')
  }

  return (
    <StyledDropdown style={style} hasValue={Boolean(valueToDisplay)}>
      <Dropdown
        disabled={readOnly}
        placeholder={placeholder}
        text={valueToDisplay}
        onChange={handleFieldChange}
        onOpen={() => setopen(true)}
        onClose={() => setopen(false)}
        open={open}
        floating
        fluid
        search
        onFocus={handleFocus}
        noResultsMessage="Type street number and name"
        className="selection"
        onSearchChange={_.throttle(onSearchChange, 1000)}
        searchQuery={searchQuery}
      >
        <Dropdown.Menu>
          <Dropdown.Item value={defaultOption.value} active={isDefault} onClick={handleFieldChange}>
            {defaultOption.text}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Header
            icon="address card outline"
            content={
              dropdownOptions.length
                ? 'Suggested Custom Address options'
                : 'Begin typing your street address'
            }
          />

          {dropdownOptions.map((option, i) => (
            <Dropdown.Item key={i} onClick={() => handleAddressSelect(option)} value={option.value}>
              {option.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledDropdown>
  )
}
