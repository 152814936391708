
import styled from 'styled-components'

const StyledStatus = styled.span`
  display: flex;
  align-items: center;

  font-size: 12px;
  text-transform: uppercase;

  & > strong {
    margin-right: 5px;
  }

  & + & {
    margin-left: 15px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
`

function ProfileStatus(props) {
  return (
    <StyledStatus>
      <strong>{props.caption}</strong> {props.children}
    </StyledStatus>
  )
}

export default ProfileStatus
