
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

const StyledStakeWarning = styled.div`
  padding: 3px 8px;

  background-color: #f7f8fb;
  border-bottom: 1px dotted #e34626;

  font-size: 12px;
  font-weight: 600;
  color: #e34626;
  text-align: center;
`

export function StakeWarning() {
  return (
    <StyledStakeWarning>
      <Icon name="warning sign" />
      The total amount of members stakes should be equal to 100%
    </StyledStakeWarning>
  )
}
