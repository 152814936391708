import { loadingBarReducer } from 'react-redux-loading-bar'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import * as actionTypes from 'store/actions/actionTypes'
import auth from 'store/slices/authSlice'
import partnerSettings from 'store/slices/partnerSettingsSlice'
import org from 'store/slices/orgSlice'
import payment from 'store/reducers/payment'

export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    partnerSettings,

    org,

    payment,

    loadingBar: loadingBarReducer,
  })

export type ReducerType = Parameters<ReturnType<typeof createRootReducer>>[0]

const rootReducer = history => (state, action) => {
  if (action.type === actionTypes.LOGOUT) {
    state = undefined
  }

  return createRootReducer(history)(state, action)
}

export default rootReducer
