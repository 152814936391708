import { useDispatch, useSelector } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'
import { lighten, darken } from 'polished'

import { usePartner } from 'utils/hooks'
import themes from 'utils/themes'
import { paymentUpdateSpecialRequest } from 'store/actions/payment'

const StyledBill = styled.div`
  display: grid;
  grid-row-gap: 5px;
`

const BillRow = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 5px;

  border-radius: 4px;

  font-size: 18px;

  @media screen and (max-width: 768px) {
    padding: 0 5px;

    font-size: 16px;
  }
`

const DiscountBillRow = styled(BillRow)`
  color: ${p => p.theme.accent};
`

const TotalWithoutDiscount = styled.s`
  margin-right: 10px;

  opacity: 0.3;
`

const BillRowTotal = styled(BillRow)`
  margin-top: 5px;
  padding: 10px;

  background-color: #f5f5f5;
`

const FilingNotesWrapper = styled.div`
  padding: 0 20px;
  margin-left: 20px;

  border-left: 1px solid #cfcfcf;

  p {
    margin: 0 0 5px;

    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
`

const FilingNotes = styled.textarea`
  width: 100%;
  height: 65px;
  padding: 10px;

  background-color: ${lighten(0.22, '#ffd888')};
  border: 1px solid ${darken(0.3, '#ffd888')};
  border-radius: 2px;

  font-size: 15px;

  resize: none;

  &:focus,
  &:active {
    outline: 2px solid ${darken(0.3, '#ffd888')};
  }

  @media screen and (max-width: 768px) {
    height: 55px;

    font-size: 13px;
  }
`

interface BillProps {
  serviceFee: number
  stateFee: number
  stateFiled: string
  discount?: number
  totalPrice?: number
}

function Bill({ serviceFee, stateFee, discount = 0, stateFiled }: BillProps) {
  const [, isBetterlegal] = usePartner()
  const dispatch = useDispatch()
  const partnerName = useSelector<any, any>(state => state.auth.partnerInfo.name)

  const totalPrice = serviceFee + stateFee - discount

  // const { serviceFee, stateFee, discount, totalPrice } = useSelector<any, any>(
  //   state => state.payment
  // )

  // return stateFiled && stateFee ? (
  return (
    <StyledBill>
      <BillRow>
        <div>{partnerName} Service</div>
        <strong>{serviceFee}</strong>
      </BillRow>
      <BillRow>
        <div>State of {stateFiled} Filing</div>
        <strong>{stateFee || '-'}</strong>
      </BillRow>
      <FilingNotesWrapper>
        <p>Notes for Filing Team</p>
        <FilingNotes
          placeholder="e.g. Do not include an EIN"
          onChange={e => dispatch(paymentUpdateSpecialRequest(e.target.value))}
        ></FilingNotes>
      </FilingNotesWrapper>

      {!!discount && (
        <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
          <DiscountBillRow>
            <div>Coupon Discount</div>
            <strong>-{discount}</strong>
          </DiscountBillRow>
        </ThemeProvider>
      )}
      <BillRowTotal>
        <strong>Total</strong>
        <strong>
          {!!discount && <TotalWithoutDiscount>{stateFee + serviceFee}</TotalWithoutDiscount>}
          {totalPrice}
        </strong>
      </BillRowTotal>
    </StyledBill>
  )
}

export default Bill
