import React, { useState } from 'react'
import { Input, Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledOrgListSearch = styled.div`
  @media screen and (max-width: 992px) {
    max-width: 100%;
    width: 600px;
    margin-bottom: 10px;
  }

  & > .ui.input {
    width: 100%;
  }
`

const ClearSearchInput = styled(Icon)`
  position: absolute;
  top: 10px;
  left: -20px;

  color: #e24527;

  cursor: pointer;
`

function OrgListSearch({ searchQuery, searchFn }) {
  const [value, setValue] = useState('')

  const keydownEvent = e => {
    if (e.key === 'Enter') {
      searchFn(value)
    }
  }

  const clearValue = () => {
    setValue('')
    searchFn('')
  }

  return (
    <StyledOrgListSearch>
      <Input
        action
        placeholder="Search by name"
        onChange={e => setValue(e.target.value)}
        value={value}
        onKeyDown={keydownEvent}
      >
        {searchQuery && <ClearSearchInput name="close" onClick={clearValue} />}
        <input />
        <Button icon="search" onClick={() => searchFn(value)} />
      </Input>
    </StyledOrgListSearch>
  )
}

export default OrgListSearch
