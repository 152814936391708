
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

export const StyledErrorItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;

  color: #e34626;
`

export function ValidationError({ children }) {
  return (
    <StyledErrorItem>
      <Icon name="warning sign" style={{ color: '#e34626' }} />
      {children}
    </StyledErrorItem>
  )
}
