import { Accordion, Icon } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import queryString from 'query-string'
import TagManager from 'react-gtm-module'

import { formatOrgType } from 'utils/orgType'
import { usePartner } from 'utils/hooks'

const StyledAccordion = styled(Accordion)`
  width: 100% !important;

  box-shadow: none !important;
  border-radius: 0 !important;

  font-size: 16px;

  &.ui.styled.accordion .accordion .title,
  &.ui.styled.accordion .title {
    color: #252525;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`

const Note = styled.div`
  padding: 10px;
  margin-top: 10px;

  border-top: 1px dashed #919eab;

  font-size: 14px;
  color: #212b36;
`

const Label = styled.span`
  display: inline-block;

  margin-right: 10px;
  padding: 3px 10px;

  background-color: #b8e4de;
  border-radius: 4px;

  font-size: 13px;
  font-weight: 600;
`

const AsteriskDesc = styled.span`
  position: absolute;
  right: 0;
  bottom: -25px;

  font-size: 14px;
  color: #707070;
`

const Asterisk = () => (
  <span style={{ display: 'inline-block', marginLeft: 5, color: '#9F79A8' }}>*</span>
)

const LabelFree = () => <Label>FREE</Label>

const LabelRec = () => <Label style={{ backgroundColor: '#d2c0d5' }}>Recommended</Label>

function IncludedTab() {
  const paymentState = useSelector<any, any>(state => state.payment)
  const [, isBetterlegal] = usePartner()

  const orgTypeData = paymentState?.data?.orgType
  const state = paymentState?.data?.state_filed
  const turnaround = paymentState?.turnaround
  const filingType = paymentState?.filingType

  const urlParamsString = useSelector<any, any>(state => state.router.location.search)
  const orgTypeURL = queryString.parse(urlParamsString).type

  const orgType = orgTypeData || orgTypeURL

  const docType = orgType?.toLowerCase() === 'inc' ? 'Bylaws' : 'Operating Agreement'
  const operatorType = orgType?.toLowerCase() === 'inc' ? 'directors' : 'managers'

  const handleTitleClick = (event, data) => {
    if (isBetterlegal) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'interactionEvent',
          eventCategory: 'Payment Page',
          eventAction: `${formatOrgType(orgType)} - ${
            state || queryString.parse(urlParamsString).state
          }`,
          eventLabel: data?.content?.props?.children[1] || data?.content,
        },
      })
    }
  }

  const panels = [
    {
      key: 'filing-type',
      title: {
        content: (
          <>
            <Icon name="university" color="teal" />
            {filingType}
          </>
        ),
      },
      content: `This document officially establishes your ${orgType} as a separate legal entity in your State.
        `,
    },
    {
      key: 'ein',
      title: {
        content: (
          <>
            <Icon name="university" color="teal" />
            Employer Identification Number (EIN/Tax ID)
          </>
        ),
      },
      content:
        'Your EIN Number (or Tax ID) is obtained from the Internal Revenue Service. It will be required in order to set up a bank account, and is also necessary if you will have any W-2 employees.',
    },
    {
      key: 'doc-type',
      title: {
        content: (
          <>
            <Icon name="university" color="teal" />
            {docType}
          </>
        ),
      },
      content: `Your Company’s ${docType} sets forth the organizational rules of your ${orgType}, including ownership and profit distribution percentages, management rights and obligations, voting rights and procedures, and other matters concerning governance of your new business.`,
    },
    {
      key: 'banking-resolution',
      title: {
        content: (
          <>
            <Icon name="university" color="teal" />
            Banking Resolution
          </>
        ),
      },
      content:
        'Your Banking Resolution will identify who can open and access Company bank accounts.',
    },

    {
      key: 'bonus-business-forms',
      title: 'Bonus Business Forms (including S-Corp Election Form)',
      content: {
        content: (
          <>
            <p>
              We also include several optional business forms that you may find useful in running
              your new business. These include:
            </p>
            <ul>
              <li>
                Officer Designation, which will allow you to elect or appoint officers of your
                Company.
              </li>
              <li>
                Meeting Minutes Templates, which will allow you to memorialize important decisions
                and votes by the owners and {operatorType} of your Company.
              </li>
              <li>
                IRS S-Corporation Election Instructions and Form, which you may use if you would
                like your Company to be taxed as an S-Corporation (talk to your CPA before using).
              </li>
              <li>
                Ownership Amendment, which allows you to add or remove owners or change the
                ownership percentages of existing owners.
              </li>
            </ul>
          </>
        ),
      },
    },
    {
      key: 'website',
      title: {
        content: (
          <>
            <LabelFree />
            Website
            <Asterisk />
          </>
        ),
      },
      content:
        'Set up a free website or online store with inventory management. As your business grows, transition to a paid plan to unlock more features and customization.',
    },
    {
      key: 'business-checking',
      title: {
        content: (
          <>
            <LabelFree />
            Business Checking
            <Asterisk />
          </>
        ),
      },
      content:
        'No set up fee, minimum deposit, monthly minimum balances, and no overdraft fees. Online banking for the modern business with physical or virtual debit cards and ability to send physical checks with a click.',
    },
    {
      key: 'tax-analysis',
      title: {
        content: (
          <>
            <LabelFree />
            Tax Analysis
            <Asterisk />
          </>
        ),
      },
      content:
        'Get your business tax analysis with the leading virtual tax service for entrepreneurs.',
    },
    {
      key: 'payroll-benefits-hr',
      title: {
        content: (
          <>
            <LabelRec />
            Payroll, Benefits, and HR platform
            <Asterisk />
          </>
        ),
      },
      content:
        'Take care of your business and your team with affordable payroll, benefits, and more.',
    },

    {
      key: 'turnaround',
      title: `Turnaround in ${turnaround}`,
      content:
        "Our turnaround time is as fast as the state's, so you'll be ready to open a bank account and start doing business right away.",
    },
  ]

  const partnerPanels = [
    {
      key: 'filing-type',
      title: {
        content: (
          <>
            <Icon name="university" color="teal" />
            {filingType}
          </>
        ),
      },
      content: `This document officially establishes your ${orgType} as a separate legal entity in your State.
      `,
    },
    {
      key: 'ein',
      title: {
        content: (
          <>
            <Icon name="university" color="teal" />
            Employer Identification Number (EIN/Tax ID)
          </>
        ),
      },
      content:
        'Your EIN Number (or Tax ID) is obtained from the Internal Revenue Service. It will be required in order to set up a bank account, and is also necessary if you will have any W-2 employees.',
    },
    {
      key: 'doc-type',
      title: {
        content: (
          <>
            <Icon name="university" color="teal" />
            {docType}
          </>
        ),
      },
      content: `Your Company’s ${docType} sets forth the organizational rules of your ${orgType}, including ownership and profit distribution percentages, management rights and obligations, voting rights and procedures, and other matters concerning governance of your new business.`,
    },
    {
      key: 'banking-resolution',
      title: {
        content: (
          <>
            <Icon name="university" color="teal" />
            Banking Resolution
          </>
        ),
      },
      content:
        'Your Banking Resolution will identify who can open and access Company bank accounts.',
    },

    {
      key: 'bonus-business-forms',
      title: 'Bonus Business Forms',
      content: {
        content: (
          <>
            <p>
              We also include several optional business forms that you may find useful in running
              your new business. These include:
            </p>
            <ul>
              <li>
                Officer Designation, which will allow you to elect or appoint officers of your
                Company.
              </li>
              <li>
                Meeting Minutes Templates, which will allow you to memorialize important decisions
                and votes by the owners and {operatorType} of your Company.
              </li>
              <li>
                IRS S-Corporation Election Instructions and Form, which you may use if you would
                like your Company to be taxed as an S-Corporation (talk to your CPA before using).
              </li>
              <li>
                Ownership Amendment, which allows you to add or remove owners or change the
                ownership percentages of existing owners.
              </li>
            </ul>
          </>
        ),
      },
    },
    {
      key: 'turnaround',
      title: `Turnaround in ${turnaround}`,
      content:
        "Our turnaround time is as fast as the state's, so you'll be ready to open a bank account and start doing business right away.",
    },
  ]

  return (
    <>
      <StyledAccordion
        styled
        panels={isBetterlegal ? panels : partnerPanels}
        exclusive={false}
        onTitleClick={handleTitleClick}
      />
      <Note>
        <Icon name="university" color="teal" /> - Required to open a bank account
      </Note>

      {isBetterlegal ? (
        <AsteriskDesc>* Services provided by our BetterLegal partners</AsteriskDesc>
      ) : null}
    </>
  )
}

export default IncludedTab
