
import _ from 'lodash'

import { ProfileInfoHeader } from '../ProfileInfoHeader'
import { EditableAgent } from 'components/UI/Editable/EditableAgent'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import { EditablePrincipalAddress } from 'components/UI/Editable/EditablePrincipalAddress'
import { EditablePersonsList } from 'components/UI/Editable/EditablePersonsList'
import { businessPurposeSchema } from 'components/UI/Editable/editableSchemas'

import { WS_TIMEOUT } from 'utils/constants'
import { StyledProfilePanel, StyledProfileOverviewContainer, OrgInfoRow } from '../../UI/orgHelpers'

function ProfileInc({ org, readOnly, handlers }) {
  const { wsOrgInfo, wsAgent, wsAffiliate, wsRemoveAffiliate, wsAddAffiliate } = handlers

  return (
    <>
      <StyledProfilePanel>
        <ProfileInfoHeader caption="Info" />
        <StyledProfileOverviewContainer>
          <OrgInfoRow>
            <label>State Filed</label>
            <EditableSimpleField
              fieldName="state_filed"
              data={{ state_filed: org.state_filed }}
              readOnly={true}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Type</label>
            <EditableSimpleField
              fieldName="fields.corporation_type"
              data={{ fields: { corporation_type: org.fields.corporation_type } }}
              readOnly={true}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Business Purpose</label>
            <EditableSimpleField
              fieldName="fields.business_purpose"
              data={{ fields: { business_purpose: org.fields.business_purpose } }}
              readOnly={readOnly}
              isTextarea
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              validationSchema={businessPurposeSchema}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Principal Office Address</label>
            <EditablePrincipalAddress
              data={{ address: org.address }}
              readOnly={readOnly}
              wsHandler={wsOrgInfo}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Shares/Par Value</label>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              <EditableSimpleField
                fieldName="fields.shares"
                data={{ fields: { shares: org.fields.shares } }}
                readOnly={readOnly}
                wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              />
              <EditableSimpleField
                fieldName="fields.par_value"
                data={{ fields: { par_value: org.fields.par_value } }}
                readOnly={readOnly}
                wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              />
            </div>
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Registered Agent</label>
            <EditableAgent data={org.agent} readOnly={readOnly} wsHandler={wsAgent} />
          </OrgInfoRow>
        </StyledProfileOverviewContainer>
      </StyledProfilePanel>

      <StyledProfilePanel>
        <EditablePersonsList
          type="Director"
          data={org.directors}
          readOnly={readOnly}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
        />
      </StyledProfilePanel>

      <StyledProfilePanel>
        <EditablePersonsList
          type="Shareholder"
          data={org.shareholders}
          readOnly={readOnly}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
        />
      </StyledProfilePanel>
    </>
  )
}

export default ProfileInc
