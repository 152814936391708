import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Container, Select, Button } from 'semantic-ui-react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { useDispatch } from 'react-redux'

import axiosInstance from 'utils/axiosInstance'
import { CustomInput } from 'components/UI/Editable/CustomFields'

const StyledContainer = styled(Container)`
  margin-bottom: 50px;

  position: relative;

  @media screen and (max-width: 1200px) {
    &.ui.container {
      width: 100%;
      padding: 0 15px;
    }
  }
`

const StyledDocument = styled.div`
  display: flex;

  margin-bottom: 20px;

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
`

const StyledDocumentForm = styled.div<{ isEditing: boolean }>`
  padding: 10px 15px;
  width: 43.75% !important;

  border-right: 1px solid #efefef;

  border-left: 1px solid #efefef;
  margin: 0 auto;
`

const StyledDocumentFormRow = styled.div`
  display: grid;
  align-items: center;

  grid-row-gap: 10px;
  padding: 2px 0;
  margin-bottom: 15px;

  background-color: #fff;

  label {
    font-weight: bold;
    margin: 10px 0;
  }
  .ui.selection.dropdown {
    border-radius: 0;
    border: 1px solid #efefef;
    &:hover,
    &.active {
      border-color: #96c8da;
    }
  }
  textarea {
    min-height: 100px;
  }
`

const StyledDocumentFormEditing = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;

  h4 {
    margin-bottom: 0;
  }
`

function DocumentForm({ match }) {
  const dispatch = useDispatch()

  const [isSubmitting, setisSubmitting] = useState(false)
  const [fileUrl, setfileUrl] = useState('')

  const [templateFields, settemplateFields] = useState([])
  const [name, setname] = useState('')

  const documentId = match.params.id

  useEffect(() => {
    async function fetchDocuments() {
      dispatch(showLoading())
      const response = await axiosInstance({
        method: 'GET',
        url: `/documents/forms/${documentId}`,
      })

      dispatch(hideLoading())

      const keys = response.data.template_fields

      const fields = keys.map(key => ({ key, value: '' }))

      settemplateFields(fields)
      setname(response.data.name)
    }

    fetchDocuments()
  }, [documentId])

  const onSubmit = async () => {
    if (templateFields.some(({ value }) => !value)) {
      return toast.error('Fill all fields')
    }

    dispatch(showLoading())
    setisSubmitting(true)

    const fields = {}

    templateFields.forEach(({ key, value }) => {
      fields[key] = value
    })

    const response = await axiosInstance({
      method: 'POST',
      url: `/documents/forms/${documentId}/merge_form/?render_docx=true`,
      data: {
        form_type: 'PDF',
        fields: JSON.stringify(fields),
      },
    })
    dispatch(hideLoading())
    setisSubmitting(false)

    if (response.data.file) {
      setfileUrl(response.data.file)

      toast.success('Document has been generated')
    } else {
      toast.error('Something went wrong. Try again.')
    }
  }

  const onFieldChange = (i, value) => {
    const copy = _.cloneDeep(templateFields)

    copy[i].value = value

    settemplateFields(copy)
  }

  if (fileUrl) {
    return (
      <StyledContainer>
        <h3>{name}</h3>
        <a href={fileUrl} target="__blank">
          download link
        </a>
      </StyledContainer>
    )
  }

  return (
    <>
      <StyledContainer>
        <h3>{name}</h3>
        <StyledDocument>
          <StyledDocumentForm isEditing={false}>
            {templateFields.map(({ key, value }, i) => (
              <StyledDocumentFormRow key={i}>
                <label>{key}</label>
                <CustomInput
                  field={{
                    value,
                    onChange: e => onFieldChange(i, e.target.value),
                  }}
                  readOnly={false}
                />
                {/* for the future when we add select type
                <Select
                  options={[]}
                  // value={bState.value}
                  // onChange={(e, data) => handleStateChange(e, data)}
                  style={{ minWidth: 'auto' }}
                /> */}
              </StyledDocumentFormRow>
            ))}

            <StyledDocumentFormEditing>
              <Button
                color={!isSubmitting ? 'teal' : null}
                onClick={onSubmit}
                content="Submit"
                disabled={isSubmitting}
              />
            </StyledDocumentFormEditing>
          </StyledDocumentForm>
        </StyledDocument>
      </StyledContainer>
    </>
  )
}

export default DocumentForm
