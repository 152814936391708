import * as actionTypes from './actionTypes'

export const passwordForgotSubmit = email => {
  return {
    type: actionTypes.PASSWORD_FORGOT_SUBMIT,
    email
  }
}

export const passwordResetSubmit = (token, password) => {
  return {
    type: actionTypes.PASSWORD_RESET_SUBMIT,
    token,
    password
  }
}
