import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'

import { userRights } from 'utils/userRights'
import { WS_TIMEOUT } from 'utils/constants'

import { CustomInput } from 'components/UI/Editable/CustomFields'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import { EditablePrincipalAddress } from 'components/UI/Editable/EditablePrincipalAddress'
import { EditableAgent } from 'components/UI/Editable/EditableAgent'
import { EditablePersonsList } from 'components/UI/Editable/EditablePersonsList'
import { PaymentSeparator } from 'components/UI/PaymentSeparator'
import { businessPurposeSchema, orgNameSchema } from 'components/UI/Editable/editableSchemas'
import { StakeWarning } from 'components/UI/StakeWarning'
import { OrgInfoRow } from 'components/UI/orgHelpers'
import Tooltip from './Tooltip'
import { tooltipsContent } from './tooltipsContent'

function PaymentOrgLLC({ data, handlers, confirmTooltips }) {
  const { wsOrgInfo, wsAffiliate, wsAgent, wsRemoveAffiliate, wsAddAffiliate } = handlers
  const [stakeSum, setStakeSum] = useState(0)

  useEffect(() => {
    let sum = 0
    _.forEach(data?.members, member => (sum = sum + parseInt(member?.fields?.stake)))
    setStakeSum(sum)
  }, [data])

  const orgNameRef = useRef()
  const principalOfficeRef = useRef()
  const registeredAgentRef = useRef()
  const affiliatesRef = useRef()

  return (
    <>
      {confirmTooltips && (
        <>
          <Tooltip fieldRef={orgNameRef} content={tooltipsContent.orgName} />
          <Tooltip fieldRef={principalOfficeRef} content={tooltipsContent.principalOfficeAddress} />
          <Tooltip fieldRef={registeredAgentRef} content={tooltipsContent.registeredAgent} />
          <Tooltip fieldRef={affiliatesRef} content={tooltipsContent.affiliates} />
        </>
      )}

      <span id="tour-org-name">
        <OrgInfoRow payment ref={orgNameRef}>
          <label htmlFor="name">Organization Name</label>
          <div style={{ display: 'flex' }}>
            <EditableSimpleField
              fieldName="name"
              data={{ name: data?.name }}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              validationSchema={orgNameSchema}
            />
            <CustomInput
              name="orgType"
              field={{ value: data?.type }}
              disabled
              style={{ width: 100, marginLeft: 2 }}
            />
          </div>
        </OrgInfoRow>
      </span>
      <OrgInfoRow payment>
        <label htmlFor="state_filed">State Filed</label>
        <EditableSimpleField
          fieldName="state_filed"
          data={{ state_filed: data?.state_filed }}
          readOnly={true}
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label htmlFor="type">Type</label>
        <EditableSimpleField
          fieldName="fields.members_type"
          data={{ fields: { members_type: data?.fields?.members_type } }}
          readOnly={true}
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label htmlFor="taxes_manager">Taxes Manager</label>
        <EditableSimpleField
          fieldName="fields.taxes_manager"
          data={{ fields: { taxes_manager: data?.fields?.taxes_manager } }}
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
        />
      </OrgInfoRow>
      <OrgInfoRow payment>
        <label htmlFor="business_purpose">Business Purpose</label>
        <EditableSimpleField
          fieldName="fields.business_purpose"
          data={{ fields: { business_purpose: data?.fields?.business_purpose } }}
          isTextarea
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
          validationSchema={businessPurposeSchema}
        />
      </OrgInfoRow>

      <span id="tour-address">
        <OrgInfoRow payment ref={principalOfficeRef}>
          <label>Principal Office Address</label>
          <EditablePrincipalAddress
            data={{ address: data?.address }}
            readOnly={!userRights.public.editInfo}
            wsHandler={wsOrgInfo}
          />
        </OrgInfoRow>
      </span>

      <span id="tour-agent">
        <OrgInfoRow payment ref={registeredAgentRef}>
          <label>Registered Agent</label>
          <EditableAgent data={data?.agent} wsHandler={wsAgent} readOnly={false} />
        </OrgInfoRow>
      </span>

      <PaymentSeparator />

      <div id="tour-affiliates">
        <EditablePersonsList
          type="Manager"
          data={data?.managers}
          readOnly={!userRights.public.editInfo}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
        />

        <PaymentSeparator ref={affiliatesRef} />

        {stakeSum !== 100 && data?.members?.length > 0 && <StakeWarning />}
        <EditablePersonsList
          type="Member"
          data={data?.members}
          readOnly={!userRights.public.editInfo}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
        />
      </div>
    </>
  )
}

export default PaymentOrgLLC
