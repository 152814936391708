import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReducerType } from 'store/reducers'

import betterlegalLogo from 'img/affiliates/allstate/betterlegal_logotype-TM.svg'
import allstateLogo from 'img/affiliates/allstate/Allstate-logo.jpg'

const BlockForLogo = styled.div`
  max-height: 60px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.img`
  max-width: 158px;
  width: 100%;

  opacity: 1;

  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`

const LogoPartner = styled.img`
  max-width: 138px;
  width: 100%;

  opacity: 1;

  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`

const Ampersand = styled.p`
  font-family: 'Roboto', sans-serif;
  color: #563c5c;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 16px;
  font-weight: 550;
`

export const AllstateAndBetterlegalHeaderLogo = () => (
  <BlockForLogo>
    <a href="https://www.allstate.com/">
      <LogoPartner src={allstateLogo} alt="Allstate" />
    </a>
    <Ampersand>&</Ampersand>
    <Link to="/">
      <Logo src={betterlegalLogo} alt="BetterLegal" />
    </Link>
  </BlockForLogo>
)
