import _ from 'lodash'

import { ReducerType } from 'store/reducers'

export const getEmail = (state: ReducerType) => {
  const paymentData = state.payment.data

  let array

  if (paymentData.orgType.toLowerCase() === 'inc') {
    array = paymentData.directors
  } else if (paymentData.orgType.toLowerCase() === 'llc') {
    array = paymentData.managers
  } else {
    return
  }

  return _.find(array, _.matchesProperty('order', 1)).user.email
}

export const getPaymentState = (state: ReducerType) => state.payment

export const getPartnerInfo = (state: ReducerType) => state.auth.partnerInfo
