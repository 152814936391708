
import _ from 'lodash'

import { ProfileInfoHeader } from '../ProfileInfoHeader'
import { EditableAgent } from 'components/UI/Editable/EditableAgent'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import { EditablePrincipalAddress } from 'components/UI/Editable/EditablePrincipalAddress'
import { businessPurposeSchema } from 'components/UI/Editable/editableSchemas'
import { EditablePersonsList } from 'components/UI/Editable/EditablePersonsList'

import { WS_TIMEOUT } from 'utils/constants'
import { StyledProfileOverviewContainer, OrgInfoRow, StyledProfilePanel } from '../../UI/orgHelpers'

function ProfileSole({ org, readOnly, handlers }) {
  const { wsOrgInfo, wsAgent, wsAddAffiliate, wsRemoveAffiliate, wsAffiliate } = handlers

  return (
    <>
      <StyledProfilePanel>
        <ProfileInfoHeader caption="Info" />
        <StyledProfileOverviewContainer>
          <OrgInfoRow>
            <label>State Filed</label>
            <EditableSimpleField
              fieldName="state_filed"
              data={{ state_filed: org.state_filed }}
              readOnly={true}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Business Purpose</label>
            <EditableSimpleField
              fieldName="fields.business_purpose"
              data={{ fields: { business_purpose: org.fields.business_purpose } }}
              isTextarea
              readOnly={readOnly}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              validationSchema={businessPurposeSchema}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Principal Office Address</label>
            <EditablePrincipalAddress
              data={{ address: org.address }}
              readOnly={readOnly}
              wsHandler={wsOrgInfo}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Taxes Manager</label>
            <EditableSimpleField
              fieldName="fields.taxes_manager"
              data={{ fields: { taxes_manager: org.fields.taxes_manager } }}
              readOnly={readOnly}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Registered Agent</label>
            <EditableAgent data={org.agent} readOnly={readOnly} wsHandler={wsAgent} />
          </OrgInfoRow>
        </StyledProfileOverviewContainer>
      </StyledProfilePanel>
      <StyledProfilePanel>
        <EditablePersonsList
          type="SoleProprietor"
          data={org.sole_proprietor}
          readOnly={readOnly}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
        />
      </StyledProfilePanel>
    </>
  )
}

export default ProfileSole
