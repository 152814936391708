import Iframe from 'react-iframe'

import {
  SINGLE_MEMBER_LLC_BETTERLEGAL_LOCATION,
  SINGLE_MEMBER_LLC_PARTNER_LOCATION,
} from 'utils/constants'

import { usePartner } from 'utils/hooks'

function SingleMemberLLC(props) {
  const [partner, isBetterlegal] = usePartner()

  const typeformId = isBetterlegal
    ? SINGLE_MEMBER_LLC_BETTERLEGAL_LOCATION
    : SINGLE_MEMBER_LLC_PARTNER_LOCATION
  const params = props.location.search ? props.location.search + '&' : '?'

  return (
    <Iframe
      url={`https://poseidonimaging.typeform.com/to/${typeformId}${params}partner=${partner}`}
      width="100%"
      height="100%"
    />
  )
}

export default SingleMemberLLC
