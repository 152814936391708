import { takeEvery, takeLatest } from 'redux-saga/effects'

import * as actionTypes from 'store/actions/actionTypes'
import { authSaga, fetchUsernameSaga, fetchPartnerInfoSaga, logoutSaga } from 'store/sagas/authSaga'
import { settingsSubmitSaga } from 'store/sagas/partnerSettingsSaga'
import { passwordForgotSubmitSaga, passwordResetSubmitSaga } from 'store/sagas/passwordSaga'
import { fetchOrgSaga } from 'store/sagas/orgSaga'
import {
  paymentGetOrgSaga,
  paymentSearchOrgSaga,
  paymentCheckCouponSaga,
  paymentProcessPaymentSaga,
  checkParamsAffiliateSaga,
  paymentSearchStateInfoSaga,
} from 'store/sagas/paymentSaga'
import {
  newEditInfoSaga,
  newEditAgentSaga,
  newEditAffiliateSaga,
} from 'store/sagas/newEditInfoSaga'
import { fetchOrg } from 'store/slices/orgSlice'
import { authInit, fetchUsername, fetchPartnerInfo } from 'store/slices/authSlice'
import { partnerSettingsSubmit } from 'store/slices/partnerSettingsSlice'

export default function* rootSaga() {
  yield takeEvery(authInit.type, authSaga)
  yield takeEvery(fetchUsername.type, fetchUsernameSaga)
  yield takeLatest(fetchPartnerInfo.type, fetchPartnerInfoSaga)
  yield takeEvery(actionTypes.SIGN_OFF, logoutSaga)
  yield takeEvery(actionTypes.PASSWORD_FORGOT_SUBMIT, passwordForgotSubmitSaga)
  yield takeEvery(actionTypes.PASSWORD_RESET_SUBMIT, passwordResetSubmitSaga)

  yield takeEvery(partnerSettingsSubmit, settingsSubmitSaga)

  yield takeLatest(fetchOrg.type, fetchOrgSaga)

  yield takeLatest(actionTypes.PAYMENT_GET_ORG, paymentGetOrgSaga)
  yield takeEvery(actionTypes.PAYMENT_SEARCH_STATE_INFO, paymentSearchStateInfoSaga)
  yield takeLatest(actionTypes.PAYMENT_SEARCH_ORG, paymentSearchOrgSaga)
  yield takeEvery(actionTypes.PAYMENT_CHECK_COUPON, paymentCheckCouponSaga)

  yield takeEvery(actionTypes.PAYMENT_PROCESS_PAYMENT, paymentProcessPaymentSaga)

  yield takeEvery(actionTypes.NEW_EDIT_INFO_SUBMIT, newEditInfoSaga)
  yield takeEvery(actionTypes.NEW_EDIT_AGENT_SUBMIT, newEditAgentSaga)
  yield takeEvery(actionTypes.NEW_EDIT_AFFILIATE_SUBMIT, newEditAffiliateSaga)

  yield takeEvery(actionTypes.PAYMENT_CHECK_PARAMS_AFFILIATE, checkParamsAffiliateSaga)
}
