
import styled from 'styled-components'
import _ from 'lodash'

import { ProfileInfoHeader } from '../ProfileInfoHeader'
import { EditableAgentSimplified } from 'components/UI/Editable/EditableAgent'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import { EditablePrincipalAddressSimplified } from 'components/UI/Editable/EditablePrincipalAddress'
import { EditableDropdown } from 'components/UI/Editable/EditableDropdown'
import { businessPurposeSchema } from 'components/UI/Editable/editableSchemas'

import { states } from 'utils/states'
import { WS_TIMEOUT } from 'utils/constants'
import { isMatchRaAddress } from 'utils/simplifiedAddress'

import { OrgInfoPanel, StyledProfileOverviewContainer } from '../../UI/orgHelpers'

const OrgInfoRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 150px 1fr;

  padding: 2px 0;
  margin: 5px 0;

  background-color: #fff;

  label {
    font-weight: 600;
  }
`

function ProfileSole({ org, userRights, handlers, updateRAaddress }) {
  const { wsOrgInfo } = handlers

  return (
    <>
      <OrgInfoPanel>
        <ProfileInfoHeader caption="Info" />
        <StyledProfileOverviewContainer>
          <OrgInfoRow>
            <label>State Filed</label>
            <EditableDropdown
              fieldName="state_filed"
              options={states}
              data={{ state_filed: org.state_filed }}
              readOnly={!userRights.editInfo}
              wsHandler={wsOrgInfo}
              placeholder="Select State"
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Business Purpose</label>
            <EditableSimpleField
              fieldName="fields.business_purpose"
              data={{ fields: { business_purpose: org.fields.business_purpose } }}
              isTextarea
              readOnly={!userRights.editInfo}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              validationSchema={businessPurposeSchema}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Principal Office Address</label>
            <EditablePrincipalAddressSimplified
              data={{ address: org.address }}
              readOnly={!userRights.editInfo}
              wsHandler={wsOrgInfo}
              useRaAddress={isMatchRaAddress(org?.address, org?.agent?.address)}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Taxes Manager</label>
            <EditableSimpleField
              fieldName="fields.taxes_manager"
              data={{ fields: { taxes_manager: org.fields.taxes_manager } }}
              readOnly={!userRights.editInfo}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Registered Agent</label>
            <EditableAgentSimplified
              data={org.agent}
              readOnly={!userRights.editInfo}
              wsHandler={updateRAaddress}
            />
          </OrgInfoRow>
        </StyledProfileOverviewContainer>
      </OrgInfoPanel>
    </>
  )
}

export default ProfileSole
