import React, { useState, useEffect, useCallback, useMemo } from 'react'
import queryString from 'query-string'
import {
  Button,
  Card,
  Checkbox,
  Container,
  Grid,
  Header,
  Icon,
  Modal,
  Segment,
} from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'
import styled from 'styled-components'
import axios from 'axios'
import Helmet from 'react-helmet'
import { push } from 'connected-react-router'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Steps } from 'intro.js-react'

import { RootState } from 'store/rootState'
import axiosInstance from 'utils/axiosInstance'
import { formatOrgType } from 'utils/orgType'
import { getOrgNameWithAmpersand, transformSuffix } from 'utils/paymentUtils'
import { isProduction } from 'utils/isProduction'
import { ASSUMED_INSPECTLET_WID, PERMIT_AND_LICENSE_SEARCH_PRICE } from 'utils/constants'
import { usePartner } from 'utils/hooks'
import { useCancelToken } from 'utils/axiosCancelTokenHook'
import PaymentTabs from 'components/Payment/PaymentTabs'

type SCSPlan = 'SCS-MONTHLY' | 'SCS-YEARLY'

const StyledButton = styled(Button)`
  background-color: #563c5c !important;

  color: #fff !important;

  &:hover {
    background-color: #9f78a9 !important;
  }
`

const ServiceHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 20px;
    font-weight: 600;
    color: #35978b;

    @media screen and (max-width: 480px) {
      margin-top: 5px;
    }
  }

  h6 {
    margin: 0 10px;

    font-size: 17px;
    font-weight: 600;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

const StyledMessage = styled.div`
  padding: 2em;

  border-radius: 4px;
  background-color: #fff;

  font-size: 1.3em;
  line-height: 1.5;
`

const ConfirmSidebar = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }
`

const ButtonWrapper = styled.div`
  @media screen and (max-width: 991px) {
    margin: 20px 0 50px;
  }
`

const StyledDescription = styled(Card.Description)`
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    iframe {
      margin: 20px auto;
    }
  }

  @media screen and (max-width: 991px) {
    iframe {
      margin: 10px auto;
    }
  }

  @media screen and (max-width: 480px) {
    iframe {
      height: auto !important;
    }
  }
`

const iframeStyle = {
  width: 480,
  height: 270,
  maxWidth: '100%',
}

const scs: { monthly: SCSPlan; yearly: SCSPlan } = {
  monthly: 'SCS-MONTHLY',
  yearly: 'SCS-YEARLY',
}

function ConfirmFiling(props) {
  const [, isBetterlegal] = usePartner()
  const dispatch = useDispatch()

  const [licenses, setLicenses] = useState(false)
  const [SCS, setSCS] = useState(false)
  const [SCSType, setSCSType] = useState<SCSPlan>(scs.monthly)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [tourActive, setTourActive] = useState<Boolean>(true)
  const { newAxiosCancelToken } = useCancelToken()

  const [last4, setLast4] = useState('')

  const [stripe, setStripe] = useState(null)

  const scsFees = useSelector((state: RootState) => state.auth.partnerInfo.fees?.scs)

  useEffect(() => {
    if (window['Stripe']) {
      setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_TOKEN))
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_TOKEN))
      })
    }
  }, [])

  const orgId = props.match.params.id

  const [orgData, setOrgData] = useState(null)

  const getOrgInfo = async id => {
    try {
      const response = await axiosInstance.get(`business/organization/${id}`)

      setOrgData(response.data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (orgId) getOrgInfo(orgId)
  }, [orgId])

  // const urlParams = queryString.parse(props.location.search)
  const urlParams = useMemo(() => queryString.parse(props.location.search), [props.location])
  const { cid, state, type } = urlParams
  const name = getOrgNameWithAmpersand(props.location.search)

  const getLast4 = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/payment/card-number', {
        params: { customer_id: cid },
      })

      setLast4(response.data.last4)
    } catch (err) {
      console.error(err)
      toast.error('Some error happened, check console for details')
    }
  }, [cid])

  useEffect(() => {
    getLast4()
  }, [getLast4])

  const servicesObject = useMemo(
    () => ({
      licenses: {
        status: licenses,
      },
      scs: {
        status: SCS,
        type: SCSType,
      },
    }),
    [licenses, SCS, SCSType]
  )

  const processConfirm = useCallback(
    async data => {
      setSubmitLoading(true)

      try {
        await axiosInstance.post('/payment/process-second', {
          customer_id: cid,
          services: data,
        })
      } catch (e) {
        console.error(e)
        toast.error('Services Payment Error')
        setSubmitLoading(false)
      }

      const webhookData = {
        orgId: orgId || orgData?.id || 'NO ID',
        services: data,
        mode: isProduction() ? 'live' : 'test',
        org_name: name || orgData?.name,
        org_type: type || orgData?.type,
        state: state || orgData?.state_filed,
      }

      const wid = window['__insp'].wid || ASSUMED_INSPECTLET_WID // Use with caution
      const sid = window['__insp'].sid
      let link = `http://www.inspectlet.com/dashboard/watchsession/${wid}/${sid}`
      if (_.isUndefined(sid)) {
        link = 'No Inspectlet session ID was found'
      }

      try {
        await axiosInstance.post('/activity-inspectlet', {
          type: 'Inspectlet Link',
          org_id: orgId || orgData?.id || 'NO ID',
          fields: {
            link,
          },
          cancelToken: newAxiosCancelToken(),
        })
      } catch (e) {
        console.error(e)
        toast.error('Inspectlet Activity Error')
        setSubmitLoading(false)
      }

      try {
        if (isProduction()) {
          // Looks like this zap isn't used anywhere
          // await axios({
          //   method: 'post',
          //   headers: {
          //     'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          //   },
          //   url: 'https://hooks.zapier.com/hooks/catch/962269/xv0too/',
          //   data: webhookData,
          // })

          await axios({
            method: 'post',
            headers: {
              'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            url: 'https://hooks.zapier.com/hooks/catch/7052971/o7e9cu2/',
            data: webhookData,
          })
        }
      } catch (e) {
        console.error(e)
        toast.error('Zapier Services Error')
        setSubmitLoading(false)
      }

      const confirmationData = {
        org_id: orgId || orgData?.id || 'NO ID',
        org_name: name || orgData?.name,
        org_type: type || orgData?.type,
        state: state || orgData?.state_filed,
        mode: isProduction() ? 'live' : 'test',
      }

      // Confirmed info
      try {
        await axios({
          method: 'post',
          headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          url: 'https://hooks.zapier.com/hooks/catch/7052971/oj5m7a6/',
          data: confirmationData,
        })
      } catch (e) {
        console.error(e)
        toast.error('Zapier Confirmation Error')
        setSubmitLoading(false)
      }

      try {
        await axiosInstance.put(`business/organization/LLC/${orgId}`, { is_confirmed: true })
      } catch (e) {
        onerror(`Fail PUT business/organization/LLC/${orgId}`, e)
        console.error(e)
        toast.error('Update Org Confirmed Status Error')
        setSubmitLoading(false)
      }

      const redirectTo = isBetterlegal
        ? `/partner-service?${queryString.stringify(confirmationData)}`
        : '/payment-done'
      dispatch(push(redirectTo))
    },
    [cid, orgId, orgData, isBetterlegal, dispatch]
  )

  const searchForOrgId = async (name, state) => {
    if (orgId) return

    try {
      const response = await axiosInstance.get('/public/business/search', {
        params: { name: name, state_filed: state?.toString()?.replace('_', ' ') },
      })

      return response.data.id
    } catch (e) {
      console.error(e)
      toast.error('Some error happened, check console for details')
    }
  }

  useEffect(() => {
    searchForOrgId(name, state).then(id => {
      if (id) dispatch(push(`/confirm/${id}?cid=${cid}`))
    })
  }, [name, state, dispatch])

  // const [initialMessageOpen, setInitialMessageOpen] = useState(true)

  return (
    <StripeProvider stripe={stripe}>
      <Container style={{ paddingTop: 20 }}>
        <Helmet defer={false}>
          <title>Confirm Filing</title>
        </Helmet>
        <Grid doubling columns={2}>
          <Grid.Column width={9}>
            {orgId ? (
              <>
                <PaymentTabs orgId={orgId} onlyInfo confirmTooltips={false} />
                <Steps
                  enabled={tourActive}
                  steps={[
                    {
                      title: "We're almost done!",
                      intro: `<p>
                          Since we file immediately, we want to provide you a chance to confirm all
                          the information you provided us. Please go through the following steps and
                          verify all the information you entered, then click the purple "Confirm and
                          File" button.</p><p><strong>We will not file anything until you confirm this
                          information, but we will file within minutes of your confirmation.</strong>
                        </p>`,
                    },
                    {
                      title: 'Organization Name',
                      intro:
                        'Is this name exactly how it should be listed on your formation documents?',
                      element: '#tour-org-name',
                    },
                    {
                      title: 'Principal Office Address',
                      intro: 'Is this address correct?',
                      element: '#tour-address',
                    },
                    {
                      title: 'Registered Agent',
                      intro: 'In-state physical addresses required (no PO Boxes).',
                      element: '#tour-agent',
                    },
                    {
                      title: 'Managers and Members',
                      intro: `<p>Please confirm the names, addresses, and emails of all the Managers and Members.</P><p><strong>Many states only allow for a first and last name, which is why there are two spaces per name.</strong></p>`,
                      element: '#tour-affiliates',
                    },
                  ]}
                  initialStep={0}
                  onExit={() => {
                    setTourActive(false)
                  }}
                />
              </>
            ) : (
              // <PaymentTabs orgId={orgId} onlyInfo confirmTooltips={!initialMessageOpen} />
              <StyledMessage>
                <p>
                  Thanks, last step! We've got the info to file{' '}
                  <strong>
                    {name} {formatOrgType(type.toString())}
                  </strong>{' '}
                  with the state of <strong>{state}</strong> and we have the necessary info to file
                  your EIN (unless you didn't provide a SSN which we'll get from you over the
                  phone). Our team will double check that this information is correct and reach out
                  to you if there is anything that would prevent us from filing. If their check is
                  good, we'll:
                </p>
                <ol>
                  <li>File your LLC with the state</li>
                  <li>File your EIN with the IRS</li>
                  <li>Compile your legal documents</li>
                  <li>
                    Email steps 1-3 above to you along with bonus documents (like an SCorp tax
                    election form) and extra information about your state filing.{' '}
                  </li>
                </ol>

                <p> On this page you will find two additional services we provide.</p>

                <ol>
                  <li>
                    Every state requires you check in with them semi-regularly (usually annually).
                    If you want us to handle this for you, we can. You will still be responsible for
                    any fees to the state, but we'll collect that from you and then pay the state
                    ourselves when the time comes.
                  </li>
                  <li>
                    Some cities, townships, counties, and states have additional rules, regulations,
                    and permits that may be required for your specific business in your
                    jurisdiction. We can run a search for any possible things you may need and
                    provide you all the forms with instructions on how to fill them out.
                  </li>
                </ol>
              </StyledMessage>
            )}
          </Grid.Column>
          <Grid.Column width={7}>
            <ConfirmSidebar>
              <ButtonWrapper>
                <StyledButton
                  labelPosition="right"
                  size="huge"
                  loading={submitLoading}
                  disabled={submitLoading}
                  icon
                  fluid
                  onClick={() => processConfirm(servicesObject)}
                >
                  Confirm & File
                  <Icon name="arrow right" />
                </StyledButton>
                <small style={{ display: 'inline-block', width: '100%', textAlign: 'right' }}>
                  We wil charge your *{last4} card if you select additional services
                </small>
              </ButtonWrapper>
              <Segment>
                <Header>Additional Services</Header>
                <Card fluid>
                  <Card.Content>
                    <ServiceHeader>
                      <div>
                        <Checkbox checked={SCS} onClick={() => setSCS(!SCS)} />
                        <h6>State Compliance Service</h6>
                        <Button.Group size="mini">
                          <Button
                            color={SCSType === scs.monthly ? 'teal' : null}
                            content="Mo"
                            onClick={() => setSCSType(scs.monthly)}
                          />
                          <Button
                            color={SCSType === scs.yearly ? 'teal' : null}
                            content="Yr"
                            onClick={() => setSCSType(scs.yearly)}
                          />
                        </Button.Group>
                      </div>
                      <span>
                        {SCSType === scs.monthly
                          ? `$${scsFees?.monthly || 0}/mo`
                          : `$${scsFees?.yearly || 0}/yr`}
                      </span>
                    </ServiceHeader>
                    <StyledDescription>
                      <iframe
                        src="https://player.vimeo.com/video/421225438?color=69c6b9&title=0&byline=0&portrait=0"
                        style={iframeStyle}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="State Compliance Service"
                      ></iframe>
                      <p>
                        Keeping your business in good standing with your State is essential. If you
                        don’t, you’ll lose many of the benefits of your entity – including personal
                        liability protection – and the State will terminate your company’s
                        existence! Let us make sure you make the right filings at the right times
                        with the right people to keep your entity in good standing with the State.
                      </p>
                      <strong style={{ fontSize: 12 }}>
                        <Icon name="warning sign" /> Filing fees may be applicable in some states
                      </strong>
                    </StyledDescription>
                  </Card.Content>
                </Card>
                <Card fluid>
                  <Card.Content>
                    <ServiceHeader>
                      <div>
                        <Checkbox checked={licenses} onClick={() => setLicenses(!licenses)} />
                        <h6>Permit & License Search</h6>
                      </div>
                      <span>${PERMIT_AND_LICENSE_SEARCH_PRICE}</span>
                    </ServiceHeader>
                    <StyledDescription>
                      <iframe
                        src="https://player.vimeo.com/video/421225556?color=69c6b9&title=0&byline=0&portrait=0"
                        style={iframeStyle}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        title="Permit & License Search"
                      ></iframe>
                      <p>
                        There are many different levels of government and they all have unique rules
                        specific to the areas they serve. Navigating the state, county, and city
                        government labrynth to figure out what permits and licenses your specific
                        business may need can take a lot of your precious time as you're trying to
                        get things off the ground. Let us take care of this research for you, and
                        get back to growing your business. Once we're done, we'll send you a packet
                        of documents with all the necessary forms you need with instructions on how
                        to fill them out.
                      </p>
                      <strong style={{ fontSize: 12 }}>
                        <Icon name="warning sign" /> You may not need anything, but we'll find out
                        for you
                      </strong>
                    </StyledDescription>
                  </Card.Content>
                </Card>
              </Segment>
            </ConfirmSidebar>
          </Grid.Column>
        </Grid>
      </Container>
    </StripeProvider>
  )
}

export default ConfirmFiling
