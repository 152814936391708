
import styled, { ThemeProvider } from 'styled-components'

import { usePartner } from 'utils/hooks'
import themes from 'utils/themes'

const StyledProfileBtn = styled.button<{ grey?: boolean }>`
  width: 100%;
  padding: 10px;
  margin-top: 10px;

  border-radius: 4px;
  background-color: ${p => (p.grey ? '#f7f8fb' : 'white')};
  border: solid 2px;
  border-color: ${p => (p.grey ? '#f7f8fb' : p.theme.lightBright)};

  text-align: center;
  font-size: 12px;
  color: ${p => p.theme.accent};
  font-weight: bold;
  text-transform: uppercase;

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${p => (p.grey ? '#eaedf4' : p.theme.lightBright)};
    border-color: ${p => (p.grey ? '#eaedf4' : p.theme.lightBright)};
  }
`

function ProfileBtn(props) {
  const [, isBetterlegal] = usePartner()

  return (
    <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
      <StyledProfileBtn grey={props.grey}>{props.caption}</StyledProfileBtn>
    </ThemeProvider>
  )
}

export default ProfileBtn
