export const formatOrgType = (type: string = 'llc') => {
  const typeLowerCased = type.toLowerCase()
  if (typeLowerCased === 'inc') return 'Inc'
  if (typeLowerCased === 'llc') return 'LLC'
  if (typeLowerCased === 'lp') return 'LP'
}

export const incTypes = [
  {text: 'C Corp', value: 'C Corp'},
  {text: 'S Corp', value: 'S Corp'},
]