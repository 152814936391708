
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import TagManager from 'react-gtm-module'

import App from './components/App'
import store from './store/store'
import { unregister } from './registerServiceWorker'

import cookies from './utils/cookies'
import { checkCookies } from 'store/slices/authSlice'
import { GlobalStyle } from 'utils/globalStyle'

const token = cookies.get('jwt')
store.dispatch({ type: checkCookies.type, payload: { token } })

const tagManagerArgs = {
  // gtmId: 'GTM-W793TVD',
  gtmId: 'GTM-5CJR388',
}

function getPartner(url) {
  var partner = 'betterlegal'
  var regex = /((?:stage-)|(?:dev-))?(.*?)\.betterlegal.com/g

  var match = regex.exec(url)

  return match && match[2] !== 'app' ? match[2] : partner
}

if (getPartner(window.location.host) === 'betterlegal') {
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
  document.getElementById('root')
)

unregister()
