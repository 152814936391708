
import _ from 'lodash'

import { userRights } from 'utils/userRights'
import { WS_TIMEOUT } from 'utils/constants'
import { incTypes } from 'utils/orgType'
import { isMatchRaAddress } from 'utils/simplifiedAddress'

import { CustomInput } from 'components/UI/Editable/CustomFields'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import { EditableDropdown } from 'components/UI/Editable/EditableDropdown'
import { PaymentSeparator } from 'components/UI/PaymentSeparator'
import { businessPurposeSchema } from 'components/UI/Editable/editableSchemas'
import { EditablePersonsListSimplified } from 'components/UI/Editable/EditablePersonsList'
import { EditableAgentSimplified } from 'components/UI/Editable/EditableAgent'
import { EditablePrincipalAddressSimplified } from 'components/UI/Editable/EditablePrincipalAddress'
import { OrgInfoPanel, OrgInfoRow } from 'components/UI/orgHelpers'

function PaymentOrgInc({ data, handlers }) {
  const { wsOrgInfo, wsAffiliate, wsAgent, wsRemoveAffiliate, wsAddAffiliate } = handlers

  const updateRAaddress = newAgent => {
    const shouldUpdateOfficeAddress = isMatchRaAddress(data.address, data.agent.address)

    if (shouldUpdateOfficeAddress) {
      const orgUpdates = {
        address: newAgent.address,
      }

      wsOrgInfo(orgUpdates)
    }

    data.directors.forEach(director => {
      if (isMatchRaAddress(director.address, data.agent.address)) {
        wsAffiliate({ ...director, address: newAgent.address })
      }
    })

    wsAgent(newAgent)
  }

  return (
    <>
      <OrgInfoRow payment>
        <label htmlFor="name">Organization Name</label>
        <div style={{ display: 'flex' }}>
          <EditableSimpleField
            fieldName="name"
            data={{ name: data?.name || '' }}
            wsHandler={_.debounce(wsOrgInfo, WS_TIMEOUT)}
          />
          <CustomInput
            name="orgType"
            field={{ value: data?.type }}
            disabled
            style={{ width: 100, marginLeft: 2 }}
          />
        </div>
      </OrgInfoRow>
      <OrgInfoRow payment>
        <label htmlFor="state_filed">State Filed</label>
        <EditableSimpleField
          fieldName="state_filed"
          data={{ state_filed: data?.state_filed }}
          readOnly={true}
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label htmlFor="type">Type</label>
        <EditableDropdown
          fieldName="fields.corporation_type"
          data={{ fields: { corporation_type: data?.fields?.corporation_type } }}
          readOnly={false}
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
          options={incTypes}
          placeholder=""
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label htmlFor="business_purpose">Business Purpose</label>
        <EditableSimpleField
          fieldName="fields.business_purpose"
          data={{ fields: { business_purpose: data?.fields?.business_purpose } }}
          isTextarea
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
          validationSchema={businessPurposeSchema}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label>Principal Office Address</label>
        <EditablePrincipalAddressSimplified
          data={{ address: data?.address }}
          readOnly={false}
          wsHandler={wsOrgInfo}
          useRaAddress={isMatchRaAddress(data?.address, data?.agent?.address)}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label>Shares/Par Value</label>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
          <EditableSimpleField
            fieldName="fields.shares"
            data={{ fields: { shares: data?.shares || '' } }}
            wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
          />
          <EditableSimpleField
            fieldName="fields.par_value"
            data={{ fields: { par_value: data?.par_value || '' } }}
            wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
          />
        </div>
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label>Registered Agent</label>
        <EditableAgentSimplified
          data={data?.agent}
          readOnly={!userRights.public.editInfo}
          wsHandler={updateRAaddress}
        />
      </OrgInfoRow>

      <PaymentSeparator />
      <OrgInfoPanel>
        <EditablePersonsListSimplified
          type="Director"
          data={data?.directors}
          userRights={userRights.public}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
          raAddress={data?.agent?.address}
        />
      </OrgInfoPanel>

      {/* <PaymentSeparator />
      <StyledProfileInc>
        <EditablePersonsListSimplified
          type="Shareholder"
          data={data?.shareholders}
          managers={data.directors}
          userRights={userRights.public}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
        />
      </StyledProfileInc> */}
    </>
  )
}

export default PaymentOrgInc
