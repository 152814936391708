
import styled, { ThemeProvider } from 'styled-components'
import { Tab, Button } from 'semantic-ui-react'

import ActivitiesListSearch from './ActivitiesListSearch'

import themes from 'utils/themes'
import * as vars from 'utils/styledVariables'
import { Activity } from 'components/Activity/Activity'
import { useSelector } from 'react-redux'
import { createUserRightsObject } from 'utils/userRights'

const StyledActivityList = styled.div`
  box-shadow: ${vars.shadow};

  @media screen and (max-width: 767px) and (min-width: 480px) {
    width: 420px;
    margin: 0 auto;
  }
`

const StyledTabMenu = styled(Tab)`
  box-shadow: ${vars.shadow};

  & > .ui.pointing.secondary.menu {
    margin-bottom: 0;

    border-radius: 4px 4px 0 0;
    border: none;
    background-color: ${p => p.theme.lightBright};

    & > .item {
      height: 40px;
      margin: 0 20px;
      padding: 0;

      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      &.active {
        border-width: 3px;
        border-color: ${p => p.theme.accent};

        font-weight: 900;
      }
    }
  }

  & > .ui.segment {
    margin-top: 0;
    padding: 0;

    box-shadow: none;
    border-radius: 0 0 4px 4px;
    border: none;
  }
`

const ActivitiesWrapper = styled.div`
  padding: 10px;
`

const ActivityLoadMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`

function ActivityList({
  activities,
  onLoadMore,
  onActivityClick,
  isBetterlegal,
  hasNextPage,
  searchFn,
  selectedActivityId,
}) {
  const userRole = useSelector<any, any>(state => state.auth.userData.role)
  const userRights = createUserRightsObject(userRole)

  const panes = [
    {
      menuItem: 'All Activities',
      render: () => (
        <Tab.Pane attached={false}>
          <ActivitiesListSearch searchFn={searchFn} />

          <ActivitiesWrapper>
            {activities.map(activity => (
              <Activity
                data={activity}
                userRights={userRights}
                isActive={selectedActivityId === activity.id}
                key={activity.id}
                onClick={() => {
                  onActivityClick(activity)
                }}
                isActivitiesList={true}
              />
            ))}
          </ActivitiesWrapper>

          <ActivityLoadMore>
            <Button
              color={isBetterlegal ? 'teal' : null}
              onClick={onLoadMore}
              content="Load more"
              disabled={!hasNextPage}
            />
          </ActivityLoadMore>
        </Tab.Pane>
      ),
    },
  ]

  return (
    <StyledActivityList>
      <ThemeProvider theme={themes.standard}>
        <StyledTabMenu menu={{ secondary: true, pointing: true }} panes={panes} />
      </ThemeProvider>
    </StyledActivityList>
  )
}

export default ActivityList
