import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,

  data: {
    id: '',
    name: '',
    username: '',
    email: '',
    fee: '',
    address: {
      address1: '',
      city: '',
      province: '',
      zipcode: ''
    }
  }
}

const partnerSettings = createSlice({
  name: 'partnerSettings',
  initialState: initialState,
  reducers: {
    partnerSettingsSubmit: (state, action) => {

    }
    // settingsSubmitSuccess: (state, action) => {
    //   state.data = action.payload.data
    // }
  }
})

export const { partnerSettingsSubmit } = partnerSettings.actions

export default partnerSettings.reducer
