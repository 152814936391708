import * as actionTypes from './actionTypes'

export const logout = () => {
  return {
    type: actionTypes.LOGOUT
  }
}

export const signOff = () => {
  return {
    type: actionTypes.SIGN_OFF
  }
}
