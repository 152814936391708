
import styled from 'styled-components'

const StyledError = styled.div`
  margin-left: 10px;

  color: tomato;
`

function ValidationError(props) {
  return <StyledError style={props.style}>{props.content}</StyledError>
}

export default ValidationError
