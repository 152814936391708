import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Loader } from 'semantic-ui-react'

import * as vars from 'utils/styledVariables'

import Document from './Document'
import axiosInstance from 'utils/axiosInstance'

const StyledDocumentWrapper = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid ${vars.blueLightest};

  &:last-child {
    border: none;
  }
`

const Wrapper = styled.div`
  padding: 5px;
`

function ProfileDocs({ orgId }) {
  const [docs, setDocs] = useState([])
  const [docsLoaded, setDocsLoaded] = useState(false)

  async function fetchDocs(id) {
    const response = await axiosInstance.get(`/business/organization/${id}/document`)

    setDocs(response.data)
    setDocsLoaded(true)
  }

  useEffect(() => {
    fetchDocs(orgId)
  }, [orgId])

  return (
    <Wrapper>
      {docs.length > 0 ? (
        docs.map((doc, index) => (
          <StyledDocumentWrapper key={`${doc.name}-${index}`}>
            <Document data={doc} />
          </StyledDocumentWrapper>
        ))
      ) : docsLoaded ? (
        'No documents'
      ) : (
        <Loader active inline="centered" />
      )}
    </Wrapper>
  )
}

export default ProfileDocs
