import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Form, Input, Button, Checkbox } from 'semantic-ui-react'
import { injectStripe, CardElement } from 'react-stripe-elements'
import { useSelector, useDispatch } from 'react-redux'
import TagManager from 'react-gtm-module'
import queryString from 'query-string'

import { paymentProcessPayment } from 'store/actions/payment'
import { usePartner } from 'utils/hooks'
import themes from 'utils/themes'
import { formatOrgType } from 'utils/orgType'
import { PaymentUrlParamsType } from 'components/Payment/paymentUrlParams'

const StyledForm = styled(Form)`
  margin: 0;
  padding: 15px;

  background-color: ${p => p.theme.lightBright};
  border-radius: 4px;
`

const CardElementWrapper = styled.div`
  margin: 5px 0 15px;
  padding: 10px;

  background-color: #fff;
  border-radius: 4px;
`

interface StripeFormProps {
  amount: number
  urlData: PaymentUrlParamsType
  stripe: { createToken: ({ name: string }) => Promise<{ token: string }> }
  orgId: string
}

function StripeForm(props: StripeFormProps) {
  const [name, setName] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [cardComplete, setCardComplete] = useState(false)
  const [, isBetterlegal] = usePartner()
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const error = useSelector<any, any>(state => state.payment.stripeError)
  const orgType = useSelector<any, any>(state => state.payment.data.orgType)
  const state = useSelector<any, any>(state => state.payment.data.state_filed)
  const urlParams = queryString.parse(useSelector<any, any>(state => state.router.location.search))

  useEffect(() => {
    if (error) {
      setLoading(false)
    }
  }, [error])

  const handleSubmit = event => {
    setLoading(true)
    event.preventDefault()

    props.stripe.createToken({ name }).then(({ token }) => {
      dispatch(paymentProcessPayment(name, token, props.amount, props.urlData, props.orgId))
    })
  }

  const handleNameChange = (_, data) => setName(data.value.replace(/\d+/g, ''))

  const handleNameClick = () => {
    if (isBetterlegal) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'interactionEvent',
          eventCategory: 'Payment Page',
          eventAction: 'Initiate Payment',
          eventLabel: `${formatOrgType(orgType || urlParams.type)} - ${state || urlParams.state}`,
        },
      })
    }
  }

  const handleCheckboxChange = (_, data) => setTermsAccepted(data.checked)
  const handleCardDataChange = e => setCardComplete(e.complete)

  const formValid = cardComplete && name

  return (
    <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
      <StyledForm onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Name"
          fluid
          onChange={handleNameChange}
          value={name}
          onClick={handleNameClick}
        />
        <CardElementWrapper>
          <CardElement onChange={handleCardDataChange} />
        </CardElementWrapper>

        {isBetterlegal && (
          <Checkbox
            label={
              <label>
                I agree with{' '}
                <a href="https://betterlegal.com/terms" target="_blank noopener noreferrer">
                  BetterLegal Terms of Use
                </a>
              </label>
            }
            checked={termsAccepted}
            onChange={handleCheckboxChange}
            style={{ marginBottom: 15 }}
          />
        )}

        <Button
          primary
          fluid
          disabled={(isBetterlegal && !termsAccepted) || !formValid || loading}
          loading={loading}
        >
          Submit payment
        </Button>
      </StyledForm>
    </ThemeProvider>
  )
}

export default injectStripe(StripeForm)
