import React from 'react'
import styled from 'styled-components'

interface StyledOrgColProps {
  bold: boolean
  header: boolean
  name: string
}

const StyledOrgCol = styled.div<StyledOrgColProps>(
  p => `
  display: flex;
  align-items: center;
  height: 100%;

  font-weight: ${p.bold || p.header ? 'bold' : 'normal'};
  text-transform: ${p.header ? 'uppercase' : 'none'};
  font-size: ${p.name === 'type' ? '18px' : '14px'};

  @media screen and (max-width: 992px) {
    display: flex;
    align-items: center;

    order: ${p.name === 'name' ? 0 : 1};
    width: 100%;
    height: 25px;
    margin-bottom: ${p.name === 'name' ? '10px' : 0};

    font-size: ${p.name === 'name' ? '18px' : '14px'};

    &:before {
      content: '${p.name}';

      display: ${p.name === 'name' ? 'none' : 'block'};
      width: 100px;

      text-transform: capitalize;
    }
  }
`
)

function OrgCol(props) {
  return <StyledOrgCol {...props}>{props.value}</StyledOrgCol>
}

export default OrgCol
