
import styled from 'styled-components'

import { Scrollbars } from 'react-custom-scrollbars'

const StyledOrgSearchNoResult = styled.div`
  padding: 15px 0 0;

  font-size: 12px;
  color: #db2828;
`

const StyledOrgSearchResults = styled.div`
  padding: 15px 0 0;
`

const StyledOrgSearchResultsItem = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 140px;
  gap: 10px;

  padding: 10px;

  border: 1px solid #ddd;
  border-radius: 2px;

  cursor: pointer;

  & + & {
    margin-top: 5px;
  }

  &:hover {
    background-color: #fbfbfb;
  }

  p {
    margin-bottom: 0;
    font-size: 15px;
  }
`

export default function ActivityOrgSearchResults({ searchResults, assignOrg }) {
  if (!searchResults.length) return <StyledOrgSearchNoResult>Not found</StyledOrgSearchNoResult>

  return (
    <StyledOrgSearchResults>
      <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={700} autoHide>
        {searchResults.map(org => (
          <StyledOrgSearchResultsItem onClick={() => assignOrg(org)} key={org.id}>
            <p>{org.type}</p>
            <p style={{ fontWeight: 600 }}>{org.name}</p>
            <p>{org.state_filed}</p>
          </StyledOrgSearchResultsItem>
        ))}
      </Scrollbars>
    </StyledOrgSearchResults>
  )
}
