const themes = {
  standard: {
    font: '"Lato", sans-serif',
    main: '#82d3c9',
    accent: '#36978b',
    lightBright: '#c3eae4',
    lightDimmed: '#c0e0dc',
    createLLC: {
      font: '"Lato", sans-serif',
      regularText: '#645862',
      lightText: '#645862',
      background: '#eaedf4',
      buttonColor: '#82d3c9'
    }
  },
  partner: {
    font: '"Lato", sans-serif',
    main: '#f5f5f5',
    accent: '#7b7b7b',
    lightBright: '#e2e2e2',
    lightDimmed: '#f7f8fb',
    createLLC: {
      font: '"Arial", sans-serif',
      regularText: '#37404A',
      lightText: '#5C5C5C',
      background: '#fff',
      buttonColor: '#3b3e43'
    }
  }
}

export default themes
