import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Form, Input, Button, Checkbox } from 'semantic-ui-react'
import { injectStripe, CardElement } from 'react-stripe-elements'
// import TagManager from 'react-gtm-module'

import { usePartner } from 'utils/hooks'
import themes from 'utils/themes'

const StyledForm = styled.div`
  margin: 0;
  padding: 15px;

  background-color: ${p => p.theme.lightBright};
  border-radius: 4px;
`

const CardElementWrapper = styled.div`
 margin: 5px 0 15px;
    padding: 10px;

    background-color: #fff;
    border-radius: 4px;
`

function StripeFormLite(props) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [cardComplete, setCardComplete] = useState(false)
  const [, isBetterlegal] = usePartner()

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)

    props.stripe.createToken({ name }).then(({ token }) => {
      // dispatch(paymentProcessPayment(name, token, props.amount, props.urlData))
      setLoading(false)
      props.modalHandler(token, name)
    })
  }

  const handleNameChange = (_, data) => setName(data.value.replace(/\d+/g, ''))

  // const handleNameClick = () => {
  //   TagManager.dataLayer({
  //     dataLayer: {
  //       event: 'interactionEvent',
  //       eventCategory: 'Payment Page',
  //       eventAction: 'Initiate Payment',
  //       eventLabel: `${formatOrgType(orgType || urlParams.type)} - ${state || urlParams.state}`,
  //     },
  //   })
  // }

  const handleCheckboxChange = (_, data) => setTermsAccepted(data.checked)
  const handleCardDataChange = e => setCardComplete(e.complete)

  const formValid = cardComplete && name

  return (
    <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
      <StyledForm>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Name"
            fluid
            onChange={handleNameChange}
            value={name}
            // onClick={handleNameClick}
          />
          <CardElementWrapper>
            <CardElement onChange={handleCardDataChange} />
          </CardElementWrapper>

          {isBetterlegal && (
            <Checkbox
              label={
                <label>
                  I agree with{' '}
                  <a href="https://betterlegal.com/terms" target="_blank noopener noreferrer">
                    BetterLegal Terms of Use
                  </a>
                </label>
              }
              checked={termsAccepted}
              onChange={handleCheckboxChange}
              style={{ marginBottom: 15 }}
            />
          )}

          <Button
            primary
            fluid
            disabled={(isBetterlegal && !termsAccepted) || !formValid || loading}
            loading={loading}
          >
            Submit payment
          </Button>
        </Form>
      </StyledForm>
    </ThemeProvider>
  )
}

export default injectStripe(StripeFormLite)
