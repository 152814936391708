import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'

import rootSaga from 'store/sagas/index'
import createRootReducer from 'store/reducers/index'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: [routerMiddleware(history), sagaMiddleware],
})

sagaMiddleware.run(rootSaga)

export default store
