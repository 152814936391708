import { useEffect, useState } from 'react'
import { Modal, Icon, Form, Button } from 'semantic-ui-react'
import { Formik, Field, FieldArray } from 'formik'
import styled from 'styled-components'
import _ from 'lodash'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Activity } from 'components/Activity/Activity'

import axiosInstance from 'utils/axiosInstance'
import { UserRights } from 'utils/userRights'
import { toast } from 'react-toastify'

const ModalWrapper = styled.div`
  padding: 20px;
`

const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 30px;
`

const StyledLinkIcon = styled(Icon)`
  cursor: pointer;

  &:hover {
    color: #03b5ad;
  }
`

interface EditActivityModalType {
  userRights: UserRights
  afterSubmit: () => void
  onClose: () => void
  data: Activity
  active: boolean
  setModalActive: React.Dispatch<boolean>
}

export function ProfileEditActivityModal({
  userRights,
  afterSubmit,
  data,
  onClose,
  active,
  setModalActive,
}: EditActivityModalType) {
  // const [activityTypes, setActivityTypes] = useState([])

  // const fetchActivityTypes = async () => {
  //   const response = await axiosInstance.get('/activity/type')

  //   return response.data.results
  // }

  // useEffect(() => {
  //   fetchActivityTypes().then(types => {
  //     const options = types?.map(type => ({
  //       key: type.id,
  //       text: type.name,
  //       value: type.id,
  //     }))

  //     setActivityTypes(options)
  //   })
  // }, [])

  const submitActivity = async data => {
    try {
      axiosInstance.put(`/activity/${data.id}`, data)
    } catch {}
  }

  const [editableData, setEditableData] = useState({
    name: '',
    fields: [],
  })

  useEffect(() => {
    setEditableData({ name: data?.name, fields: _.toPairs(data?.fields) })
  }, [data])

  const editable = userRights.controlActivities

  return (
    <>
      <Modal open={active} closeIcon onClose={onClose} size="small">
        <ModalWrapper>
          <ModalHeader>
            <h1 style={{ margin: '0' }}>Edit an activity - {data?.type}</h1>
            <CopyToClipboard
              text={`${window.location.host}/activity/${data?.id}`}
              onCopy={() => toast.success('Copied activity URL to your clipboard')}
            >
              <StyledLinkIcon name="linkify" />
            </CopyToClipboard>
          </ModalHeader>
          <Formik
            initialValues={editableData}
            enableReinitialize
            onSubmit={async values => {
              const payload = { ...data, name: values.name, fields: _.fromPairs(values.fields) }
              submitActivity(payload)
                .then(() => setModalActive(false))
                .finally(() => afterSubmit())
            }}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form>
                  {/* <Form.Field required>
                      <label>Activity Type</label>
                      <Dropdown
                        placeholder="Select Type"
                        fluid
                        selection
                        options={activityTypes}
                        onChange={(_, option) => setFieldValue('typeId', option.value)}
                        value={_.find(activityTypes, ['text', values.type])}
                      />
                    </Form.Field> */}
                  <Form.Field required>
                    <label>Name</label>
                    <Field type="text" name="name" placeholder="Name" disabled={!editable} />
                  </Form.Field>

                  {values.fields && (
                    <FieldArray
                      name="fields"
                      render={arrayHelpers => {
                        return (
                          <Form.Field required>
                            <label>Fields</label>
                            {values.fields.map((field, index) => {
                              return (
                                <Form.Group key={index} style={{ margin: '0 0 10px' }}>
                                  <Field name={`fields[${index}][0]`} placeholder="Field Name"  disabled={!editable} />
                                  <Field
                                    name={`fields.${index}][1]`}
                                    placeholder="Field Value"
                                    style={{ margin: '0 10px' }}
                                  />

                                  <Button
                                    icon="trash alternate"
                                    onClick={() => arrayHelpers.remove(index)}
                                    disabled={!editable}
                                  />
                                </Form.Group>
                              )
                            })}

                            <Form.Button onClick={() => arrayHelpers.push(['', ''])} disabled={!editable}>
                              Add fields
                            </Form.Button>
                          </Form.Field>
                        )
                      }}
                    />
                  )}

                  <Form.Field>
                    <Form.Button
                      floated="right"
                      color="teal"
                      style={{ margin: '50px auto 20px' }}
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Form.Button>
                  </Form.Field>
                </Form>
              )
            }}
          </Formik>
        </ModalWrapper>
      </Modal>
    </>
  )
}

// TODO:
// Load actual data
