import { CancelToken } from 'axios'
import { PaymentUrlParamsType } from 'components/Payment/paymentUrlParams'
import { toast } from 'react-toastify'
import axiosInstance from 'utils/axiosInstance'

export const replaceAtSymbolToAtInEmailBetterlegal = (someString: string): string =>
  someString.replace(
    /([0-9a-z]*@betterlegal\.com)/s,
    emailBetterlegal =>
      `${
        emailBetterlegal.charAt(0).toUpperCase() + emailBetterlegal.split('@')[0].slice(1)
      } at BetterLegal`
  )

export const searchForGetOrgId = async (
  name: PaymentUrlParamsType['name'],
  state: PaymentUrlParamsType['state'],
  orgId: string,
  newAxiosCancelToken: () => CancelToken
): Promise<string | undefined> => {
  if (!name || !state || orgId || Array.isArray(name) || Array.isArray(state)) {
    return
  }

  try {
    const response = await axiosInstance.get('/public/business/search', {
      params: { name: name, state_filed: state?.toString()?.replace('_', ' ') },
      cancelToken: newAxiosCancelToken(),
    })

    return response.data.id
  } catch (e) {
    console.error(e)
    toast.error('Some error happened, check console for details')
  }
}
