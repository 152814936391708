import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import styled, { ThemeProvider } from 'styled-components'
import { Button } from 'semantic-ui-react'

import 'react-datepicker/dist/react-datepicker.css'

import { usePartner } from 'utils/hooks'
import themes from 'utils/themes'

const StyledCustomInput = styled.div`
  margin: 0 5px;
  border-bottom: 1px dashed ${p => p.theme.accent};

  font-size: 12px;

  cursor: pointer;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

// This should be a class, because of refs stuff in Datepicker library
class CustomInput extends Component<{ value?: any; onClick?: any }> {
  render() {
    const { value, onClick } = this.props
    return <StyledCustomInput onClick={onClick}>{value ? value : 'Set Date'}</StyledCustomInput>
  }
}

function EditableDate({ data, readOnly, wsHandler }) {
  const [, isBetterlegal] = usePartner()

  const date = data ? moment(data, 'M/D/YYYY').toDate() : null

  function DatePickerContainer({ className, children }) {
    return (
      <StyledContainer>
        <div className={className}>{children}</div>

        {date && (
          <Button
            content="Reset"
            icon="cancel"
            onClick={() => wsHandler({ date_filed: null })}
            style={{
              cursor: 'pointer',
              marginTop: 5,
              fontSize: 14,
              padding: '5px 15px',
              backgroundColor: '#e34626',
              color: '#fff',
            }}
            size="mini"
            disabled={readOnly}
          />
        )}
      </StyledContainer>
    )
  }

  return (
    <>
      <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
        <DatePicker
          customInput={<CustomInput />}
          dateFormat="M/d/yyyy"
          selected={date}
          disabled={readOnly}
          onChange={data => wsHandler({ date_filed: moment(data).format('M/D/YYYY') })}
          calendarContainer={DatePickerContainer}
        />
      </ThemeProvider>
    </>
  )
}

export default EditableDate
