import React, { useState } from 'react'
import { Formik } from 'formik'
import styled from 'styled-components'
import _ from 'lodash'

import { WS_TIMEOUT } from 'utils/constants'
import { isMatchRaAddress } from 'utils/simplifiedAddress'

import { EditableMember } from './EditablePerson/EditableMember'
import { EditablePerson } from './EditablePerson/EditablePerson'
import { EditableShareholder } from './EditablePerson/EditableShareholder'
import { EditableMemberSimplified } from './EditablePersonSimplified/EditableMemberSimplified'
import { EditablePersonSimplified } from './EditablePersonSimplified/EditablePersonSimplified'
import { EditableShareholderSimplified } from './EditablePersonSimplified/EditableShareholderSimplified'
import { ProfileInfoHeader } from 'components/Profile/ProfileInfoHeader'

import { personSchema, personSchemaSimplified } from './editableSchemas'

const StyledForm = styled.form`
  width: 100%;

  position: relative;

  &:hover input:not(:disabled),
  &:hover .ui.dropdown:not(:disabled) {
    border-color: #36978b;
  }
`

const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;

  p {
    margin: 0 10px 0 0;

    color: #999;
  }

  button {
    padding: 5px 15px;

    background-color: #83d2c8;
    border: none;
    border-radius: 4px;

    color: #fff;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: #00b5ad;
    }
  }
`

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.8);

  z-index: 5;

  > button {
    padding: 10px 20px;

    border: none;
    background-color: #ed5e68;
    border-radius: 4px;

    color: #fff;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: #e34626;
    }
  }
`

const PersonWrapper = styled.div`
  position: relative;
`

const ConfirmLayout = styled.div`
  color: #fff;
  font-size: 14px;
  text-align: center;

  h5 {
    margin: 0 0 1.2em;

    font-size: 14px;
  }

  button {
    margin: 0 5px;
    width: 140px;
    height: 40px;

    border: none;
    border-radius: 4px;

    font-weight: 600;
    cursor: pointer;
  }
`

const ConfirmCancel = styled.button`
  background-color: #fff;

  color: #000;

  &:hover {
    background-color: #eee;
  }
`

const ConfirmDelete = styled.button`
  background-color: #ed5e68;

  color: #fff;

  &:hover {
    background-color: #e34626;
  }
`

export interface Props {
  data: any
  userRights: any
  managers?: any
  type: string
  wsHandler: (e) => void
  wsRemoveAffiliate: (type, id) => void
  wsAddAffiliate: (e) => void
  raAddress?: boolean
}

export function EditablePersonsList({ data, readOnly, ...props }) {
  const [removeMode, setRemoveMode] = useState(false)
  const [confirmRemove, setConfirmRemove] = useState(null)

  const generateEmptyListMessage = () => {
    switch (props.type) {
      case 'GeneralPartner':
        return 'There are no General Partners yet'
      case 'LimitedPartner':
        return 'There are no Limited Partners yet'
      case 'SoleProprietor':
        return 'There is no Sole Proprietor yet'
      default:
        return `There are no ${props.type}s yet`
    }
  }

  if (data == null) return null
  if (data.length === 0)
    return (
      <EmptyList>
        <p>{generateEmptyListMessage()}</p>
        <div>
          <ConfirmCancel onClick={() => props.wsAddAffiliate(props.type)}>
            Add the first one
          </ConfirmCancel>
        </div>
      </EmptyList>
    )

  const sorted = _.sortBy(data, ['order'])

  const toggleRemoveMode = (mode: boolean) => {
    setRemoveMode(mode)
    setConfirmRemove(null)
  }

  const handleRemoveAffiliate = (type, id) => {
    props.wsRemoveAffiliate(type, id)
    setConfirmRemove(null)
    setRemoveMode(false)
  }

  const generateCaption = () => {
    switch (props.type) {
      case 'GeneralPartner':
        return 'General Partners'
      case 'LimitedPartner':
        return 'Limited Partners'
      case 'SoleProprietor':
        return 'Sole Proprietor'
      default:
        return `${props.type}s`
    }
  }

  return (
    <>
      <ProfileInfoHeader
        caption={generateCaption()}
        members={data?.length}
        toggleRemoveMode={toggleRemoveMode}
        removeMode={removeMode}
        addAffiliate={() => props.wsAddAffiliate(props.type)}
        readOnly={readOnly}
      />
      <>
        {sorted.map(person => (
          <PersonWrapper key={`${person.type}-${person.id}`}>
            {removeMode && (
              <Overlay>
                {confirmRemove === person.id ? (
                  <ConfirmLayout>
                    <h5>Are you sure you want to delete this person?</h5>
                    <div>
                      <ConfirmCancel onClick={() => setConfirmRemove(null)}>Cancel</ConfirmCancel>
                      <ConfirmDelete onClick={() => handleRemoveAffiliate(props.type, person.id)}>
                        Delete
                      </ConfirmDelete>
                    </div>
                  </ConfirmLayout>
                ) : (
                  <button onClick={() => setConfirmRemove(person.id)}>
                    Remove {person?.user?.full_name}
                  </button>
                )}
              </Overlay>
            )}

            <EditablePersonContainer
              person={person}
              readOnly={readOnly}
              wsHandler={props.wsHandler}
            />
          </PersonWrapper>
        ))}
      </>
    </>
  )
}

export function EditablePersonsListSimplified({ data, userRights, managers, ...props }: Props) {
  const [removeMode, setRemoveMode] = useState(false)
  const [confirmRemove, setConfirmRemove] = useState(null)

  if (data == null) return null
  if (data.length === 0)
    return (
      <EmptyList>
        <p>There are no {props.type?.toLowerCase()}s yet</p>
        <div>
          <ConfirmCancel onClick={() => props.wsAddAffiliate(props.type)}>
            Add the first one
          </ConfirmCancel>
        </div>
      </EmptyList>
    )

  const sorted = _.sortBy(data, ['order'])

  const toggleRemoveMode = (mode: boolean) => {
    setRemoveMode(mode)
    setConfirmRemove(null)
  }

  const handleRemoveAffiliate = (type, id) => {
    props.wsRemoveAffiliate(type, id)
    setConfirmRemove(null)
    setRemoveMode(false)
  }

  return (
    <>
      <ProfileInfoHeader
        caption={`${props.type}s`}
        members={data?.length}
        toggleRemoveMode={toggleRemoveMode}
        removeMode={removeMode}
        addAffiliate={() => props.wsAddAffiliate(props.type)}
      />
      <>
        {sorted.map(person => (
          <PersonWrapper key={`${person.type}-${person.id}`}>
            {removeMode && (
              <Overlay>
                {confirmRemove === person.id ? (
                  <ConfirmLayout>
                    <h5>Are you sure you want to delete this person?</h5>
                    <div>
                      <ConfirmCancel onClick={() => setConfirmRemove(null)}>Cancel</ConfirmCancel>
                      <ConfirmDelete onClick={() => handleRemoveAffiliate(props.type, person.id)}>
                        Delete
                      </ConfirmDelete>
                    </div>
                  </ConfirmLayout>
                ) : (
                  <button onClick={() => setConfirmRemove(person.id)}>
                    Remove {person?.user?.full_name}
                  </button>
                )}
              </Overlay>
            )}

            <EditablePersonSimplifiedContainer
              person={person}
              managers={managers}
              userRights={userRights}
              wsHandler={props.wsHandler}
              useRaAddress={isMatchRaAddress(person.address, props.raAddress)}
            />
          </PersonWrapper>
        ))}
      </>
    </>
  )
}

function EditablePersonContainer({ person, ...props }) {
  const [t, setT] = useState(null)

  let PersonComponent

  switch (person.type) {
    case 'Member':
      PersonComponent = EditableMember
      break
    case 'Shareholder':
      PersonComponent = EditableShareholder
      break
    case 'Manager':
    case 'Director':
    case 'GeneralPartner':
    case 'LimitedPartner':
    case 'SoleProprietor':
    default:
      PersonComponent = EditablePerson
      break
  }

  return (
    <Formik
      initialValues={person}
      onSubmit={data => props.wsHandler(data)}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={personSchema}
      {...props}
    >
      {({ handleSubmit, validateForm }) => {
        return (
          <StyledForm
            onInput={() => {
              if (t) clearTimeout(t)
              setT(setTimeout(() => handleSubmit(), WS_TIMEOUT))
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            onPaste={validateForm}
          >
            <PersonComponent readOnly={props.readOnly} />
          </StyledForm>
        )
      }}
    </Formik>
  )
}

function EditablePersonSimplifiedContainer({ person, managers, ...props }) {
  const [t, setT] = useState(null)

  let PersonComponent

  switch (person.type) {
    case 'Member':
      PersonComponent = EditableMemberSimplified
      break
    case 'Shareholder':
      PersonComponent = EditableShareholderSimplified
      break
    case 'Manager':
    case 'Director':
    case 'GeneralPartner':
    case 'LimitedPartner':
    case 'SoleProprietor':
    default:
      PersonComponent = EditablePersonSimplified
      break
  }

  return (
    <Formik
      initialValues={person}
      onSubmit={_.debounce(data => props.wsHandler(data), WS_TIMEOUT)}
      {...props}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={personSchemaSimplified}
    >
      {({ handleSubmit, setValues, validateForm, setFieldValue, values }: any) => {
        return (
          <StyledForm
            onInput={() => {
              if (t) clearTimeout(t)
              setT(setTimeout(() => handleSubmit(), WS_TIMEOUT))
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                handleSubmit()
              }
            }}
            onPaste={validateForm}
          >
            <PersonComponent
              address={values.address}
              userRights={props.userRights}
              managers={managers}
              setValues={setValues}
              onChange={(key, value) => {
                setFieldValue(key, value).then(handleSubmit)
              }}
              useRaAddress={props.useRaAddress}
            />
          </StyledForm>
        )
      }}
    </Formik>
  )
}
