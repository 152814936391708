import React, { useState, useEffect } from 'react'
import { Modal, Icon, Button } from 'semantic-ui-react'
import { StripeProvider } from 'react-stripe-elements'
import styled from 'styled-components'

import { LightCheckout } from 'components/Payment/Checkout/Checkout'

const ModalLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
`

const Description = styled.div`
  padding: 10px 15px 50px;
  margin-bottom: 10px;

  background-color: #f8f8f9;
  border-radius: 4px;
  border: 1px solid #e8e8e8;

  font-size: 16px;
  color: #57585d;
`

const Turnaround = styled.p`
  margin-bottom: 20px;

  font-size: 18px;
  font-weight: 600;
`

const UploadWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  align-items: center;
`

const CustomFileUpload = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 20px;

  border: 1px solid black;
  border-radius: 4px;

  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  > input {
    display: none;
  }
`

export function ProfilePaymentModal({ data, visible, onClose, modalHandler, uploadHandler }) {
  const [stripe, setStripe] = useState(null)
  const [fileToUpload, setFileToUpload] = useState(null)

  useEffect(() => {
    if (window['Stripe']) {
      setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_TOKEN))
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_TOKEN))
      })
    }
  }, [])

  const uploadFile = () => {
    uploadHandler(fileToUpload)
  }

  return (
    <StripeProvider stripe={stripe}>
      <Modal open={visible} closeIcon={true} onClose={onClose}>
        <Modal.Header>{data.title}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <ModalLayout>
              <div>
                <Description>{data.description}</Description>
                <Turnaround>
                  Turnaround Time: <strong>{data.turnaround}</strong>
                </Turnaround>
                <p>
                  <Icon name="warning sign" color="yellow" />
                  Not filing this will revert your changes, if you have already executed the filing
                  yourself, <em>upload it here:</em>.
                </p>

                <UploadWrapper>
                  <CustomFileUpload>
                    Choose File
                    <input
                      type="file"
                      onChange={e => {
                        setFileToUpload(e.target.files[0])
                      }}
                    />
                  </CustomFileUpload>

                  {fileToUpload && (
                    <>
                      <span
                        style={{
                          color: '#3ead9d',
                          fontWeight: 600,
                        }}
                      >
                        {fileToUpload.name}
                      </span>
                      <Button
                        color="teal"
                        onClick={uploadFile}
                        content="Upload"
                        icon="cloud upload"
                        labelPosition="right"
                        style={{ gridColumn: '1/3' }}
                      />
                    </>
                  )}
                </UploadWrapper>
              </div>
              <div>
                <LightCheckout
                  stateFee={data.stateFee}
                  serviceFee={data.serviceFee}
                  stateFiled={data.stateFiled}
                  modalHandler={modalHandler}
                />
              </div>
            </ModalLayout>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </StripeProvider>
  )
}
