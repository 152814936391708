import React, { useState } from 'react'
import { Formik, Field } from 'formik'
import styled from 'styled-components'
import _ from 'lodash'

import { CustomInput } from './CustomFields'
import { AddressAutocompleteDropdown } from './AddressAutocompleteDropdown'

import { WS_TIMEOUT } from 'utils/constants'
import { usePartner } from 'utils/hooks'
import { isDefaultAddress } from 'utils/simplifiedAddress'

const StyledForm = styled.form<{ showFields?: boolean }>`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: ${p => (p.showFields ? '1fr 1fr 1fr' : '1fr')};
  gap: 2px;

  width: 100%;

  position: relative;

  &:hover input:not(:disabled),
  &:hover .ui.dropdown:not(:disabled) {
    border-color: #36978b !important;
    transition: 0s !important;
  }
`

export function EditableAgent({ data, readOnly, ...props }) {
  const [t, setT] = useState(null)

  return (
    <Formik initialValues={data} onSubmit={props.wsHandler} enableReinitialize={true}>
      {({ handleSubmit }) => (
        <StyledForm
          onInput={() => {
            if (t) clearTimeout(t)
            setT(setTimeout(() => handleSubmit(data), WS_TIMEOUT))
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              handleSubmit(data)
            }
          }}
        >
          <Field
            disabled={readOnly}
            name="full_name"
            component={CustomInput}
            style={{ gridColumn: '1/4' }}
            placeholder="Full Name"
          />
          <Field
            disabled={readOnly}
            name="email"
            component={CustomInput}
            style={{ gridColumn: '1/4' }}
            placeholder="Email"
          />
          <Field
            disabled={readOnly}
            name="address.address1"
            component={CustomInput}
            style={{ gridColumn: '1/4' }}
            placeholder="Address"
          />
          <Field
            disabled={readOnly}
            name="address.city"
            component={CustomInput}
            placeholder="City"
          />
          <Field
            disabled={readOnly}
            name="address.province"
            component={CustomInput}
            placeholder="State"
          />
          <Field
            disabled={readOnly}
            name="address.zipcode"
            component={CustomInput}
            placeholder="Zipcode"
          />
          <Field
            disabled
            name="address.county"
            component={CustomInput}
            placeholder="County"
            style={{ gridColumn: '1/4' }}
          />
        </StyledForm>
      )}
    </Formik>
  )
}

export function EditableAgentSimplified({ data, readOnly, ...props }) {
  const [, isBetterlegal] = usePartner()

  return (
    <Formik
      initialValues={data || {}}
      onSubmit={_.debounce(data => props.wsHandler(data), WS_TIMEOUT)}
      enableReinitialize={true}
    >
      {/* https://github.com/jaredpalmer/formik/pull/2384 */}
      {({ handleSubmit, setFieldValue, values }: any) => {
        const isDefault = isDefaultAddress(values.address)

        return (
          <StyledForm onChange={handleSubmit} showFields={!isDefault}>
            {!isDefault && (
              <>
                <Field
                  disabled={readOnly}
                  name="full_name"
                  component={CustomInput}
                  style={{ gridColumn: '1/4' }}
                />
                <Field
                  disabled={readOnly}
                  name="email"
                  component={CustomInput}
                  style={{ gridColumn: '1/4' }}
                />
              </>
            )}
            <Field name="address.address1">
              {({ field }) => (
                <AddressAutocompleteDropdown
                  defaultOption={{
                    key: 'BetterLegal',
                    value: 'BetterLegal',
                    text: isBetterlegal ? 'BetterLegal' : 'Provide a Registered Agent to me',
                  }}
                  isDefault={isDefault}
                  placeholder="Registered Agent"
                  value={field.value}
                  wsHandler={address => {
                    // default option selected
                    if (!address) {
                      setFieldValue('address', null).then(handleSubmit)
                    } else {
                      setFieldValue('address', address).then(handleSubmit)
                    }
                  }}
                  style={
                    !isDefault ? { marginBottom: '2px', gridColumn: '1/4' } : { gridColumn: '1/4' }
                  }
                  readOnly={readOnly}
                />
              )}
            </Field>

            {!isDefault && (
              <>
                <Field disabled={readOnly} name="address.city" component={CustomInput} />
                <Field disabled={readOnly} name="address.province" component={CustomInput} />
                <Field disabled={readOnly} name="address.zipcode" component={CustomInput} />
                <Field
                  disabled
                  name="address.county"
                  component={CustomInput}
                  placeholder="County"
                  style={{ gridColumn: '1/4' }}
                />
              </>
            )}
          </StyledForm>
        )
      }}
    </Formik>
  )
}
