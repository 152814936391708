import { AxiosResponse } from 'axios'
import { push } from 'connected-react-router'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { toast } from 'react-toastify'
import { Button, Confirm, Container, Grid, Icon, Label } from 'semantic-ui-react'
import styled from 'styled-components'

import ProfileContact from 'components/Profile/ProfileContact'
import ProfileInc from 'components/Profile/ProfileInfo/ProfileInc'
import ProfileLLC from 'components/Profile/ProfileInfo/ProfileLLC'
import ProfileLP from 'components/Profile/ProfileInfo/ProfileLP'
import ProfileSole from 'components/Profile/ProfileInfo/ProfileSole'
import { ProfilePaymentModal } from 'components/Profile/ProfilePaymentModal'
import ProfileStatus from 'components/Profile/ProfileStatus'
import {
  StyledProfileHeader,
  StyledProfileName,
  StyledProfileStatuses,
} from 'components/Profile/profileStyles'
import ProfileSidebar from 'components/Profile/Sidebar/ProfileSidebar'
import EditableDate from 'components/UI/Editable/EditableDate'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import EditableStatus from 'components/UI/Editable/EditableStatus'

import axiosInstance from 'utils/axiosInstance'
import { WS_TIMEOUT } from 'utils/constants'
import { useOrgWebSocket, usePartner } from 'utils/hooks'
import { formatOrgType } from 'utils/orgType'
import { brown } from 'utils/styledVariables'
import { createUserRightsObject } from 'utils/userRights'
import { useCancelToken } from 'utils/axiosCancelTokenHook'

const NoteWrapper = styled.div<{ hasNote?: boolean }>`
  padding: 10px;
  margin: 0 0 20px;

  background-color: ${p => (p.hasNote ? '#ffb922' : '#f7f8fb')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  h3 {
    margin: 0 0 10px;

    border-radius: 4px;

    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
`

const StyledButton = styled(Button)`
  margin-bottom: 20px !important;

  background-color: #563c5c !important;

  color: #fff !important;

  &:hover {
    background-color: #9f78a9 !important;
  }
`

export interface AffiliateType {
  id: string
  type: string
  user: any
  order: number
  address: any
  fields: any
}

function Profile(props) {
  const [paymentModalVisible, setPaymentModalVisible] = useState(false)
  const [confirmActive, setConfirmActive] = useState(false)
  const [stripeCustomerLink, setStripeCustomerLink] = useState('')
  const { newAxiosCancelToken } = useCancelToken()

  const dispatch = useDispatch()
  const { partnerInfo, userData } = useSelector<any, any>(state => state.auth)

  const userRights = createUserRightsObject(userData?.role)

  const [partner, isBetterlegal] = usePartner()

  const currentOrgId = props.match.params.id
  const [orgData, handlers] = useOrgWebSocket(currentOrgId)

  const editable =
    orgData?.status?.toLowerCase() === 'active' || orgData?.status?.toLowerCase() === 'draft'

  const fetchCustomerStripeLink = async (
    orgId: string,
    returnUrl: string
  ): Promise<AxiosResponse<{ url: string }> | undefined> => {
    try {
      const response = await axiosInstance.post(
        '/payment/stripe/customer/',
        {
          org_id: orgId,
          return_url: returnUrl,
        },
        { cancelToken: newAxiosCancelToken() }
      )
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const handleOpenStripeLinkNewTab = async () => {
    const response = await fetchCustomerStripeLink(currentOrgId, window.location.href)
    window.open(response.data.url, '_blank noopener noreferrer')
    setStripeCustomerLink(response.data.url)
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetchCustomerStripeLink(currentOrgId, window.location.href)
      setStripeCustomerLink(response?.data?.url || '')
    })()
  }, [])

  useEffect(() => {
    setPaymentModalVisible(orgData?.pm.active)
  }, [orgData])

  const { wsOrgInfo, wsPaymentRevert, wsPaymentConfirm } = handlers

  const getModalData = () => {
    return {
      title: orgData.pm?.action,
      description: orgData.pm?.summary,
      turnaround: orgData.pm?.turnaround,
      serviceFee: orgData.pm?.fees?.admin_fee,
      stateFee: orgData.pm?.fees?.filing_fee,
      stateFiled: orgData.state_filed,
    }
  }

  const getStripeEmail = useCallback(() => {
    switch (orgData?.type.toLowerCase()) {
      case 'llc':
        return _.find(orgData.managers, { order: 1 })?.user?.email
      case 'inc':
        return _.find(orgData.directors, { order: 1 })?.user?.email
      default:
        return 'itsNotAnLLCorInc@mail.com'
    }
  }, [orgData])

  function renderProfile(orgData, handlers, userRights) {
    let Component

    switch (orgData.type.toLowerCase()) {
      case 'sole':
        Component = ProfileSole
        break
      case 'inc':
        Component = ProfileInc
        break
      case 'lp':
        Component = ProfileLP
        break
      case 'llc':
      default:
        Component = ProfileLLC
        break
    }
    return (
      <Component org={orgData} readOnly={!editable && !userRights.editInfo} handlers={handlers} />
    )
  }

  const handleConfirmCancel = () => {
    wsPaymentRevert()
    setPaymentModalVisible(false)
    setConfirmActive(false)
  }

  const uploadFile = useCallback(
    async file => {
      dispatch(showLoading())

      try {
        const data = new FormData()
        data.append('file', file)
        data.append('org_id', orgData.id)
        data.append('org_type', orgData.type)
        await axiosInstance.post('/app/storage/upload', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          cancelToken: newAxiosCancelToken(),
        })

        wsPaymentConfirm()
        toast.success('File was uploaded successfully!')
        dispatch(hideLoading())
      } catch (error) {
        toast.error(error)
        console.log(error)
      }
    },
    [dispatch, orgData, wsPaymentConfirm]
  )

  const processModalPayment = useCallback(
    async (token, name) => {
      dispatch(showLoading())

      try {
        const response = await axiosInstance.post(
          `/payment/process`,
          {
            email: getStripeEmail() || 'noManagerOrDirectorWithOrder1@mail.com',
            token: token,
            amount: (orgData.pm?.fees?.admin_fee + orgData.pm?.fees?.filing_fee) * 100,
            metadata: {
              name: name,
              org_name: orgData.name,
              org_id: orgData.id,
              org_type: orgData.type,
              province: orgData.state_filed,
              partner: orgData.partner.username,
              service: orgData.pm?.action,
            },
          },
          { cancelToken: newAxiosCancelToken() }
        )

        dispatch(hideLoading())
        wsPaymentConfirm(response.data.customer_id, response.data.transaction_id)
      } catch (error) {
        console.error(error.response)
        toast.error(error.response?.data?.details[0]?.title)
      }
    },
    [dispatch, orgData, wsPaymentConfirm, getStripeEmail]
  )

  return (
    orgData && (
      <Container>
        <Helmet defer={false}>
          <title>
            {partnerInfo?.name} - {orgData?.name || ''}
          </title>
        </Helmet>
        <ProfilePaymentModal
          visible={paymentModalVisible}
          onClose={() => setConfirmActive(true)}
          data={getModalData()}
          modalHandler={processModalPayment}
          uploadHandler={uploadFile}
        />
        <Confirm
          open={confirmActive}
          onCancel={() => setConfirmActive(false)}
          onConfirm={handleConfirmCancel}
          content="Are you sure want to close the payment dialog and revert the changes?"
          size="tiny"
          confirmButton={<Button style={{ backgroundColor: '#3ead9d' }}>Confirm</Button>}
        />
        <StyledProfileHeader>
          <StyledProfileName>
            <EditableSimpleField
              fieldName="name"
              data={{ name: orgData.name }}
              readOnly={!editable && !userRights.editInfo}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              style={{ fontSize: '16px' }}
            />
            {orgData?.type?.toLowerCase() !== 'sole' && (
              <span style={{ marginLeft: 10 }}>{` ${formatOrgType(orgData.type)}`}</span>
            )}

            {partnerInfo.username === 'betterlegal' && <ProfileContact org={orgData} />}

            <Button
              onClick={handleOpenStripeLinkNewTab}
              disabled={!stripeCustomerLink}
              content="Update your card"
              icon="external square"
              labelPosition="right"
              size="tiny"
              style={{ maxWidth: '120px', marginLeft: '15px' }}
            />
          </StyledProfileName>

          <StyledProfileStatuses>
            {orgData.partner.username !== partner && (
              <ProfileStatus>
                <Label color="blue">
                  Partner
                  <Label.Detail>{orgData.partner.username}</Label.Detail>
                </Label>
              </ProfileStatus>
            )}

            {userRights.seeConfirmedStatus && (
              <ProfileStatus caption="Confirmed">
                <div>
                  {orgData.is_confirmed ? <Icon name="check" color="teal" /> : <Icon name="x" />}
                </div>
              </ProfileStatus>
            )}

            <ProfileStatus caption="Status">
              <EditableStatus
                data={orgData.status}
                orgId={orgData.id}
                readOnly={!userRights.editInfo}
                wsHandler={wsOrgInfo}
              />
            </ProfileStatus>

            <ProfileStatus caption="Date filed">
              <EditableDate
                data={orgData.date_filed}
                readOnly={!userRights.editInfo}
                wsHandler={wsOrgInfo}
              />
            </ProfileStatus>
          </StyledProfileStatuses>
        </StyledProfileHeader>

        <Grid doubling columns={2}>
          <Grid.Column width={9}>{renderProfile(orgData, handlers, userRights)}</Grid.Column>
          <Grid.Column width={7}>
            {orgData.status === 'Draft' && (
              <StyledButton
                labelPosition="right"
                size="large"
                onClick={() => dispatch(push(`/payment/${orgData.id}`))}
                icon
                fluid
              >
                Checkout
                <Icon name="shopping cart" />
              </StyledButton>
            )}
            {isBetterlegal && userRights.orgNote && (
              <NoteWrapper hasNote={!!orgData.note}>
                <h3>Org Note</h3>
                <EditableSimpleField
                  fieldName="note"
                  data={{ note: orgData.note }}
                  isTextarea
                  wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
                />
              </NoteWrapper>
            )}
            <ProfileSidebar orgData={orgData} userRights={userRights} />
          </Grid.Column>
        </Grid>
      </Container>
    )
  )
}

export default Profile
