import React, { useState } from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import _ from 'lodash'

import { CustomInput } from './CustomFields'
import { AddressAutocompleteDropdown } from './AddressAutocompleteDropdown'
import { ValidationError } from './ValidationError'

import { WS_TIMEOUT } from 'utils/constants'
import { addressSchema } from './editableSchemas'

const Wrapper = styled.div`
  &:hover input:not(:disabled),
  &:hover .ui.dropdown:not(:disabled) {
    border-color: #36978b !important;
  }
`

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2px;

  width: 100%;

  position: relative;
`

export function EditablePrincipalAddress({ data, readOnly, ...props }) {
  const [t, setT] = useState(null)

  return (
    <Formik
      initialValues={data}
      onSubmit={props.wsHandler}
      {...props}
      enableReinitialize={true}
      validationSchema={addressSchema}
    >
      {({ handleSubmit }) => {
        return (
          <Wrapper>
            <StyledForm
              onInput={() => {
                if (t) clearTimeout(t)
                setT(setTimeout(() => handleSubmit(data), WS_TIMEOUT))
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSubmit(data)
                }
              }}
            >
              <Field
                disabled={readOnly}
                name="address.address1"
                component={CustomInput}
                style={{ gridColumn: '1/4' }}
                placeholder="Address"
              />
              <Field
                disabled={readOnly}
                name="address.city"
                component={CustomInput}
                placeholder="City"
              />
              <Field
                disabled={readOnly}
                name="address.province"
                component={CustomInput}
                placeholder="State"
              />
              <Field
                disabled={readOnly}
                name="address.zipcode"
                component={CustomInput}
                placeholder="Zipcode"
              />
              <Field
                disabled
                name="address.county"
                component={CustomInput}
                placeholder="County"
                style={{ gridColumn: '1/4' }}
              />
            </StyledForm>

            <ul style={{ padding: '0 5px', listStyle: 'none' }}>
              <ErrorMessage name="address.address1" component={ValidationError} />
              <ErrorMessage name="address.city" component={ValidationError} />
              <ErrorMessage name="address.province" component={ValidationError} />
              <ErrorMessage name="address.zipcode" component={ValidationError} />
            </ul>
          </Wrapper>
        )
      }}
    </Formik>
  )
}

export function EditablePrincipalAddressSimplified({ data, readOnly, useRaAddress,  ...props }) {
  return (
    <Formik
      initialValues={data}
      onSubmit={_.debounce(data => props.wsHandler(data), WS_TIMEOUT)}
      {...props}
      enableReinitialize={true}
      validationSchema={addressSchema}
    >
      {/* https://github.com/jaredpalmer/formik/pull/2384 */}
      {({ handleSubmit, values, setFieldValue }: any) => {
        return (
          <Wrapper>
            <AddressAutocompleteDropdown
              defaultOption={{ value: 'agent', text: 'Use Registered Agent Address' }}
              isDefault={useRaAddress}
              placeholder="Principal Office Address"
              value={values.address?.address1}
              readOnly={readOnly}
              style={useRaAddress ? {} : { marginBottom: '2px' }}
              wsHandler={address => {
                // default option selected
                if (!address) {
                  // do nothing if ra selected and user click on it
                  if (!useRaAddress) {
                    setFieldValue('address', null).then(() => {
                      props.wsHandler({ address: null })
                    })
                  }
                } else {
                  setFieldValue('address', address).then(handleSubmit)
                }
              }}
            />
            {!useRaAddress && (
              <>
                <StyledForm onChange={handleSubmit}>
                  <Field disabled={readOnly} name="address.city" component={CustomInput} />
                  <Field disabled={readOnly} name="address.province" component={CustomInput} />
                  <Field disabled={readOnly} name="address.zipcode" component={CustomInput} />
                  <Field
                    disabled
                    name="address.county"
                    component={CustomInput}
                    placeholder="County"
                    style={{ gridColumn: '1/4' }}
                  />
                </StyledForm>

                <ul style={{ padding: '0 5px', listStyle: 'none' }}>
                  <ErrorMessage name="address.address1" component={ValidationError} />
                  <ErrorMessage name="address.city" component={ValidationError} />
                  <ErrorMessage name="address.province" component={ValidationError} />
                  <ErrorMessage name="address.zipcode" component={ValidationError} />
                </ul>
              </>
            )}
          </Wrapper>
        )
      }}
    </Formik>
  )
}
