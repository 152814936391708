import { produce } from 'immer'
// import _ from 'lodash'

import * as actionTypes from 'store/actions/actionTypes'
// import { calculateServiceFee, HARDCODED_FEES } from 'utils/paymentUtils'

export type Coupon = {
  id: string
  code: string
  discount: number
  name: string | null
  email: string | null
}

const initialState = {
  data: {
    name: '',
    id: '',
    members: [],
    address: {
      id: '',
      address1: '',
      address2: '',
      city: '',
      zipcode: '',
      county: '',
      province: '',
      country: '',
    },
    orgType: '',
    fields: {
      members_type: '',
    },
    state_filed: '',
    agent: {
      address: {
        id: '',
        address1: '',
        address2: '',
        city: '',
        zipcode: '',
        county: '',
        province: '',
        country: '',
      },
    },
    partner: {
      username: '',
      fee: undefined,
    },

    directors: [],
    managers: [],
  },

  paramsAffiliate: {
    code: '',
    name: '',
  },
  orgId: '',
  loading: false,
  stripeError: false,
  mode: 'standard',

  turnaround: '',
  filingType: '',
  specialRequest: '',
  coupon: '',
  couponName: '',

  discount: null,
  regularStateFee: 0,

  registeredAgentAddon: false,
  registeredAgentPlan: 'RA-MONTHLY',
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.PAYMENT_GET_ORG_START:
      case actionTypes.PAYMENT_SEARCH_ORG_START:
        draft.loading = true
        return

      case actionTypes.PAYMENT_SIMPLIFIED_LOAD:
        draft.loading = false
        return

      case actionTypes.PAYMENT_GET_ORG_SUCCESS:
      case actionTypes.PAYMENT_SEARCH_ORG_SUCCESS:
        draft.data = action.data
        draft.loading = false
        return

      case actionTypes.PAYMENT_GET_STATE_INFO:
        draft.loading = false
        draft.turnaround = action.data.turnaround
        draft.filingType = action.data.filing_type
        draft.regularStateFee = action.data.filing_fee
        return

      case actionTypes.PAYMENT_APPLY_DISCOUNT:
        draft.discount = action.discount
        return

      case actionTypes.ADD_COUPON_NAME:
        draft.couponName = action.name
        return

      case actionTypes.PAYMENT_SET_REGISTERED_AGENT:
        draft.registeredAgentAddon = action.checked
        return

      case actionTypes.PAYMENT_SWITCH_REGISTERED_AGENT_TYPE:
        draft.registeredAgentPlan = action.plan
        return

      case actionTypes.PAYMENT_PROCESS_PAYMENT_FAIL:
        draft.stripeError = true
        return

      case actionTypes.PAYMENT_PROCESS_PAYMENT_START:
        draft.stripeError = false
        return

      case actionTypes.NEW_EDIT_INFO_UPDATE_PAYMENT:
        draft.data = action.data
        return

      case actionTypes.NEW_EDIT_AGENT_UPDATE:
        draft.data.agent = action.data
        return

      case actionTypes.PAYMENT_UPDATE_SPECIAL_REQUEST:
        draft.specialRequest = action.value
        return

      case actionTypes.PAYMENT_MODE_SET:
        draft.mode = action.mode
        return

      case actionTypes.PAYMENT_SET_COUPON:
        draft.coupon = action.coupon
        return

      case actionTypes.PAYMENT_SET_PARAMS_AFFILIATE:
        draft.paramsAffiliate = {
          code: action.paramsAffiliate.code,
          name: action.paramsAffiliate.name || '',
        }
        return

      default:
        return
    }
  })
}

export default reducer
