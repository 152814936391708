interface TooltipContentItem {
  header: string
  desc: string
}

type keysUnion = "orgName" | "principalOfficeAddress" | "registeredAgent" | "affiliates"

type TooltipContent = {
  [key in keysUnion]: TooltipContentItem
}

export const tooltipsContent: TooltipContent = {
  orgName: {
    header: 'Organization Name',
    desc: 'Is this name exactly how it should be listed on your formation documents?',
  },

  principalOfficeAddress: {
    header: 'Principal Office Address',
    desc: 'Is this address correct?',
  },

  registeredAgent: {
    header: 'Registered Agent',
    desc: 'In-state physical addresses required (no PO Boxes).',
  },

  affiliates: {
    header: 'Managers and Members',
    desc: 'Please confirm the names, addresses, and emails of all the Managers and Members.',
  },
}