
import { Formik, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
// import _ from 'lodash'

import { CustomInput, CustomTextarea } from './CustomFields'
import { ValidationError } from './ValidationError'

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`

interface EditableSimpleFieldProps {
  fieldName: string
  data: any
  readOnly?: boolean
  isTextarea?: boolean
  wsHandler?: any
  validationSchema?: any
  style?: any
}

export const EditableSimpleField: React.FC<EditableSimpleFieldProps> = ({
  fieldName,
  data,
  readOnly,
  isTextarea,
  wsHandler,
  validationSchema,
  ...props
}) => {
  return (
    <Formik
      initialValues={data}
      onSubmit={wsHandler}
      enableReinitialize={true}
      validationSchema={validationSchema}
      {...props}
    >
      {({ handleSubmit, errors }) => {
        // const hasErrors = !_.isEmpty(errors)
        return (
          <StyledForm onChange={handleSubmit}>
            <Field
              name={fieldName}
              component={isTextarea ? CustomTextarea : CustomInput}
              disabled={readOnly}
              style={props.style}
            />
            <ul style={{ margin: 0, padding: '0 5px', listStyle: 'none' }}>
              <ErrorMessage name={fieldName} component={ValidationError} />
            </ul>
          </StyledForm>
        )
      }}
    </Formik>
  )
}
