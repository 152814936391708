
import { Radio, Form } from 'semantic-ui-react'
import { Formik } from 'formik'

import ValidationError from '../../UI/ValidationError'
import { afterpaymentMembersSchema } from './afterpaymentHelpers'

function AfterpaymentMembers({ data, updateData }) {
  // const values: AfterpaymentMembersType = props.data

  return (
    <Formik initialValues={data} onSubmit={() => null} validationSchema={afterpaymentMembersSchema}>
      {({ errors, touched, values, setFieldValue }) => {
        const radioHanlder = (e, { name, value }) => {
          setFieldValue(name, value)
          updateData({ ...values, [name]: value })
        }

        return (
          <Form
            onChange={e => {
              values[e.target.name] = e.target.value
              updateData(values)
            }}
          >
            <Form.Field required>
              <label>Are you and the other member of your LLC married to each other?</label>
            </Form.Field>
            <Form.Field>
              <Radio
                label="Yes"
                name="married"
                value="yes"
                checked={values.married === 'yes'}
                onChange={radioHanlder}
              />
              {errors.married && touched.married && <ValidationError content={errors.married} />}
            </Form.Field>
            <Form.Field>
              <Radio
                label="No"
                name="married"
                value="no"
                checked={values.married === 'no'}
                onChange={radioHanlder}
              />
            </Form.Field>
            {values.married === 'yes' && (
              <>
                <Form.Field required>
                  <label>
                    Do you want your LLC to be treated as a single-member LLC (which will be
                    disregarded for tax purposes) or a multi-member LLC (which will be taxed as a
                    partnership)?
                  </label>
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Single-member LLC"
                    name="llcType"
                    value="single"
                    checked={values.llcType === 'single'}
                    onChange={radioHanlder}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Multi Member LLC"
                    name="llcType"
                    value="multi"
                    checked={values.llcType === 'multi'}
                    onChange={radioHanlder}
                  />
                </Form.Field>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default AfterpaymentMembers
