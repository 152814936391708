export const blueLightest = '#eaedf4'

export const greenLight = '#c3eae4'
export const greenDark = '#246b61'

export const brown = '#645862'
export const brownDark = '#333f52'

export const shadow = '0 2px 4px rgba(0,0,0,.075)'

export const mainFont = '"Lato", sans-serif'
