import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer } from 'react-toastify'
import LoadingBar from 'react-redux-loading-bar'
import Helmet from 'react-helmet'

import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'intro.js/introjs.css'

import cookies from 'utils/cookies'
import { history } from 'store/store'
import AppRouter from './AppRouter'
import { fetchUsername, fetchPartnerInfo } from 'store/slices/authSlice'
import { usePartner } from 'utils/hooks'

function App(props) {
  const [partner, isBetterlegal] = usePartner()

  const { fetchPartnerInfo, fetchUsername } = props

  useEffect(() => {
    fetchPartnerInfo(partner)
  }, [fetchPartnerInfo, partner])

  useEffect(() => {
    if (cookies.get('jwt')) fetchUsername()
  }, [fetchUsername])

  const partnerName = useSelector<any, any>(state => state.auth.partnerInfo.name)

  useEffect(() => {
    console.log(process.env.REACT_APP_MODE)
  }, [])

  return (
    <div style={{ height: '100%' }}>
      <Helmet defer={false}>
        <title>{partnerName} Entity Formation</title>
        {isBetterlegal ? (
          <link rel="shortcut icon" href="/favicon.ico" />
        ) : (
          <link rel="shortcut icon" href="/favicon-partner.ico" />
        )}
      </Helmet>
      <LoadingBar
        style={{ backgroundColor: '#36978b', height: '5px', zIndex: 6, position: 'fixed', top: 0 }}
        updateTime={100}
        maxProgress={95}
        progressIncrease={10}
      />

      <ConnectedRouter history={history}>
        <AppRouter token={props.token} />
      </ConnectedRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        closeOnClick
        draggable={false}
        pauseOnHover
      />
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.auth.token,

  message: state.message,
})

const mapDispatchToProps = dispatch => ({
  fetchUsername: () => dispatch(fetchUsername()),
  fetchPartnerInfo: partner => dispatch(fetchPartnerInfo(partner)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
