export const CONNECT_ORG_INFO = "CONNECT_ORG_INFO"
export const ORG_INFO = "ORG_INFO"

export const FETCH_ACTIVITIES = "FETCH_ACTIVITIES"
export const ACTIVITIES = "ACTIVITIES"

export const EDIT_INFO = "EDIT_INFO"
export const EDIT_AGENT = "EDIT_AGENT"
export const EDIT_AFFILIATE = "EDIT_AFFILIATE"

export const REMOVE_AFFILIATE = "REMOVE_AFFILIATE"
export const ADD_AFFILIATE = "ADD_AFFILIATE"

export const ERROR = "ERROR"
export const MODAL_CONFIRM = "MODAL_CONFIRM"
export const MODAL_REVERT = "MODAL_REVERT"