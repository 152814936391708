export const states = [
  { key: 'Alabama', value: 'Alabama', text: 'Alabama' },
  { key: 'Alaska', value: 'Alaska', text: 'Alaska' },
  { key: 'Arizona', value: 'Arizona', text: 'Arizona' },
  { key: 'Arkansas', value: 'Arkansas', text: 'Arkansas' },
  { key: 'California', value: 'California', text: 'California' },
  { key: 'Colorado', value: 'Colorado', text: 'Colorado' },
  { key: 'Connecticut', value: 'Connecticut', text: 'Connecticut' },
  { key: 'Delaware', value: 'Delaware', text: 'Delaware' },
  { key: 'Florida', value: 'Florida', text: 'Florida' },
  { key: 'Georgia', value: 'Georgia', text: 'Georgia' },
  { key: 'Hawaii', value: 'Hawaii', text: 'Hawaii' },
  { key: 'Idaho', value: 'Idaho', text: 'Idaho' },
  { key: 'Illinois', value: 'Illinois', text: 'Illinois' },
  { key: 'Indiana', value: 'Indiana', text: 'Indiana' },
  { key: 'Iowa', value: 'Iowa', text: 'Iowa' },
  { key: 'Kansas', value: 'Kansas', text: 'Kansas' },
  { key: 'Kentucky', value: 'Kentucky', text: 'Kentucky' },
  { key: 'Louisiana', value: 'Louisiana', text: 'Louisiana' },
  { key: 'Maine', value: 'Maine', text: 'Maine' },
  { key: 'Maryland', value: 'Maryland', text: 'Maryland' },
  { key: 'Massachusetts', value: 'Massachusetts', text: 'Massachusetts' },
  { key: 'Michigan', value: 'Michigan', text: 'Michigan' },
  { key: 'Minnesota', value: 'Minnesota', text: 'Minnesota' },
  { key: 'Mississippi', value: 'Mississippi', text: 'Mississippi' },
  { key: 'Missouri', value: 'Missouri', text: 'Missouri' },
  { key: 'Montana', value: 'Montana', text: 'Montana' },
  { key: 'Nebraska', value: 'Nebraska', text: 'Nebraska' },
  { key: 'Nevada', value: 'Nevada', text: 'Nevada' },
  { key: 'New_Hampshire', value: 'New_Hampshire', text: 'New Hampshire' },
  { key: 'New_Jersey', value: 'New_Jersey', text: 'New Jersey' },
  { key: 'New_Mexico', value: 'New_Mexico', text: 'New Mexico' },
  { key: 'New_York', value: 'New_York', text: 'New York' },
  { key: 'North_Carolina', value: 'North_Carolina', text: 'North Carolina' },
  { key: 'North_Dakota', value: 'North_Dakota', text: 'North Dakota' },
  { key: 'Ohio', value: 'Ohio', text: 'Ohio' },
  { key: 'Oklahoma', value: 'Oklahoma', text: 'Oklahoma' },
  { key: 'Oregon', value: 'Oregon', text: 'Oregon' },
  { key: 'Pennsylvania', value: 'Pennsylvania', text: 'Pennsylvania' },
  { key: 'Rhode_Island', value: 'Rhode_Island', text: 'Rhode Island' },
  { key: 'South_Carolina', value: 'South_Carolina', text: 'South Carolina' },
  { key: 'South_Dakota', value: 'South_Dakota', text: 'South Dakota' },
  { key: 'Tennessee', value: 'Tennessee', text: 'Tennessee' },
  { key: 'Texas', value: 'Texas', text: 'Texas' },
  { key: 'Utah', value: 'Utah', text: 'Utah' },
  { key: 'Vermont', value: 'Vermont', text: 'Vermont' },
  { key: 'Virginia', value: 'Virginia', text: 'Virginia' },
  { key: 'Washington', value: 'Washington', text: 'Washington' },
  { key: 'West_Virginia', value: 'West_Virginia', text: 'West Virginia' },
  { key: 'Wisconsin', value: 'Wisconsin', text: 'Wisconsin' },
  { key: 'Wyoming', value: 'Wyoming', text: 'Wyoming' }
]
