
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledNoMatch = styled.div`
  padding: 20px;

  text-align: center;
`

function NoMatch() {
  return (
    <StyledNoMatch>
      <h1>Not Found!</h1>
      <Link to="/">Go to homepage</Link>
    </StyledNoMatch>
  )
}

export default NoMatch
