import _ from 'lodash'

import { userRights } from 'utils/userRights'
import { WS_TIMEOUT } from 'utils/constants'
import { useOrgWebSocket } from 'utils/hooks'

import { CustomInput } from 'components/UI/Editable/CustomFields'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import { EditablePrincipalAddress } from 'components/UI/Editable/EditablePrincipalAddress'
import { EditableAgent } from 'components/UI/Editable/EditableAgent'
import { EditablePersonsList } from 'components/UI/Editable/EditablePersonsList'
import { PaymentSeparator } from 'components/UI/PaymentSeparator'
import { businessPurposeSchema, orgNameSchema } from 'components/UI/Editable/editableSchemas'
import { OrgInfoRow } from 'components/UI/orgHelpers'

type PaymentOrgIncProps = {
  data: ReturnType<typeof useOrgWebSocket>[0]
  handlers: ReturnType<typeof useOrgWebSocket>[1]
}

const PaymentOrgInc: React.FC<PaymentOrgIncProps> = ({ data, handlers }: PaymentOrgIncProps) => {
  const { wsOrgInfo, wsAffiliate, wsAgent, wsRemoveAffiliate, wsAddAffiliate } = handlers
  return (
    <>
      <OrgInfoRow payment>
        <label htmlFor="name">Organization Name</label>
        <div style={{ display: 'flex' }}>
          <EditableSimpleField
            fieldName="name"
            data={{ name: data?.name }}
            wsHandler={_.debounce(wsOrgInfo, WS_TIMEOUT)}
            validationSchema={orgNameSchema}
          />
          <CustomInput
            name="orgType"
            field={{ value: data?.type }}
            disabled
            style={{ width: 100, marginLeft: 2 }}
          />
        </div>
      </OrgInfoRow>
      <OrgInfoRow payment>
        <label htmlFor="state_filed">State Filed</label>{' '}
        <EditableSimpleField
          fieldName="state_filed"
          data={{ state_filed: data?.state_filed }}
          readOnly={true}
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label htmlFor="type">Type</label>
        <EditableSimpleField
          fieldName="fields.corporation_type"
          data={{ fields: { corporation_type: data?.fields?.corporation_type } }}
          readOnly={true}
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label htmlFor="business_purpose">Business Purpose</label>
        <EditableSimpleField
          fieldName="fields.business_purpose"
          data={{ fields: { business_purpose: data?.fields?.business_purpose } }}
          isTextarea
          wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
          validationSchema={businessPurposeSchema}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label>Principal Office Address</label>
        <EditablePrincipalAddress
          data={{ address: data?.address }}
          readOnly={false}
          wsHandler={wsOrgInfo}
        />
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label>Shares/Par Value</label>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
          <EditableSimpleField
            fieldName="fields.shares"
            data={{ fields: { shares: data?.fields?.shares } }}
            wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
          />
          <EditableSimpleField
            fieldName="fields.par_value"
            data={{ fields: { par_value: data?.fields?.par_value } }}
            wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
          />
        </div>
      </OrgInfoRow>

      <OrgInfoRow payment>
        <label>Registered Agent</label>
        <EditableAgent
          data={data?.agent}
          readOnly={!userRights.public.editInfo}
          wsHandler={wsAgent}
        />
      </OrgInfoRow>

      <PaymentSeparator />
      <EditablePersonsList
        data={data?.directors}
        type="Director"
        readOnly={!userRights.public.editInfo}
        wsHandler={wsAffiliate}
        wsRemoveAffiliate={wsRemoveAffiliate}
        wsAddAffiliate={wsAddAffiliate}
      />

      <PaymentSeparator />
      <EditablePersonsList
        type="Shareholder"
        data={data.shareholders}
        readOnly={!userRights.public.editInfo}
        wsHandler={wsAffiliate}
        wsRemoveAffiliate={wsRemoveAffiliate}
        wsAddAffiliate={wsAddAffiliate}
      />
    </>
  )
}

export default PaymentOrgInc
