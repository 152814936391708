import * as actionTypes from './actionTypes'

export const newEditInfoSubmit = (orgId, data) => {
  return {
    type: actionTypes.NEW_EDIT_INFO_SUBMIT,
    orgId,
    data
  }
}

export const newEditInfoUpdatePayment = data => {
  return {
    type: actionTypes.NEW_EDIT_INFO_UPDATE_PAYMENT,
    data
  }
}

export const newEditInfoUpdateProfile = data => {
  return {
    type: actionTypes.NEW_EDIT_INFO_UPDATE_PROFILE,
    data
  }
}

export const newEditAgentSubmit = (orgId, agentId, data, cb) => {
  return {
    type: actionTypes.NEW_EDIT_AGENT_SUBMIT,
    orgId,
    agentId,
    data,
    cb
  }
}

export const newEditAgentUpdate = data => {
  return {
    type: actionTypes.NEW_EDIT_AGENT_UPDATE,
    data
  }
}

export const newEditAffiliateSubmit = (orgId, affiliateId, data) => {
  return {
    type: actionTypes.NEW_EDIT_AFFILIATE_SUBMIT,
    orgId,
    affiliateId,
    data
  }
}

export const newEditAffiliateUpdate = data => {
  return {
    type: actionTypes.NEW_EDIT_AFFILIATE_UPDATE,
    data
  }
}
