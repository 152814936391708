import React from 'react'
import styled from 'styled-components'

import DocumentCol from './DocumentCol'

const StyledDocumentListHeader = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  grid-column-gap: 20px;

  width: 100%;
  height: 50px;
  padding: 5px 20px;

  border-radius: 4px 4px 0 0;
  background-color: #f7f8fb;

  font-size: 13px;
  color: #9b9b9c;


  @media screen and (max-width: 992px) {
    display: none;
  }
`

const columns = ['name', 'created']

const DocumentListHeader = () => {
  return (
    <StyledDocumentListHeader>
      {columns.map(column => (
        <DocumentCol key={column} value={column} header />
      ))}
    </StyledDocumentListHeader>
  )
}

export default DocumentListHeader
