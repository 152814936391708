import Iframe from 'react-iframe'
import {
  MULTI_MEMBER_LLC_BETTERLEGAL_LOCATION,
  MULTI_MEMBER_LLC_PARTNER_LOCATION,
} from 'utils/constants'

import { usePartner } from 'utils/hooks'

function MultiMemberLLC(props) {
  const [partner, isBetterlegal] = usePartner()

  const typeformId = isBetterlegal
    ? MULTI_MEMBER_LLC_BETTERLEGAL_LOCATION
    : MULTI_MEMBER_LLC_PARTNER_LOCATION
  const params = props.location.search ? props.location.search + '&' : '?'

  return (
    <Iframe
      url={`https://poseidonimaging.typeform.com/to/${typeformId}${params}partner=${partner}`}
      width="100%"
      height="100%"
    />
  )
}

export default MultiMemberLLC
