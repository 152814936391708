import MaskedInput from 'react-text-mask'
import styled from 'styled-components'
import { Message, Form, Dropdown } from 'semantic-ui-react'
import { Formik, Field } from 'formik'

import { PHONE_NUMBER } from 'utils/constants'
import { phoneMask, SSNMask } from 'utils/masks'
import ValidationError from 'components/UI/ValidationError'
import {
  afterpaymentEINSchema,
  AfterpaymentEINType,
} from 'components/Payment/Afterpayment/afterpaymentHelpers'

const options = [
  { key: 0, text: 'None', value: '' },
  { key: 1, text: 'DDS', value: 'DDS' },
  { key: 2, text: 'MD', value: 'MD' },
  { key: 3, text: 'PHD', value: 'PHD' },
  { key: 4, text: 'JR', value: 'JR' },
  { key: 5, text: 'SR', value: 'SR' },
  { key: 6, text: 'I', value: 'I' },
  { key: 7, text: 'II', value: 'II' },
  { key: 8, text: 'III', value: 'III' },
  { key: 9, text: 'IV', value: 'IV' },
  { key: 10, text: 'V', value: 'V' },
  { key: 11, text: 'VI', value: 'VI' },
]

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 10px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0;
  }
`

function AfterpaymentEIN({ affiliates, updateData, data }) {
  const values: AfterpaymentEINType = data
  const handleDropdownChange = (_, data) => updateData({ ...values, suffix: data.value })

  return (
    <div>
      <Message warning style={{ maxWidth: '100%' }}>
        <p>
          {`We need a little more info to obtain an EIN/TaxID for your company. Please provide the name, phone number, and social security number of one of your company ${affiliates}. If you are not comfortable leaving that information here, please give us a call at ${PHONE_NUMBER} (but please note, this may slow down our processing time).`}
        </p>
      </Message>
      <Formik initialValues={values} onSubmit={() => null} validationSchema={afterpaymentEINSchema}>
        {({ values, handleChange, handleBlur, errors, touched }) => (
          <Form
            onChange={e => {
              values[e.target.name] = e.target.value
              updateData(values)
            }}
          >
            <Wrapper>
              <Form.Field required>
                <label>First name</label>
                <Field type="text" name="firstName" placeholder="First Name" />
                {errors.firstName && touched.firstName && (
                  <ValidationError content={errors.firstName} />
                )}
              </Form.Field>
              <Form.Field required>
                <label>Last name</label>
                <Field type="text" name="lastName" placeholder="Last Name" />
                {errors.lastName && touched.lastName && (
                  <ValidationError content={errors.lastName} />
                )}
              </Form.Field>
              <Form.Field>
                <label>Suffix</label>
                <Dropdown
                  compact
                  clearable
                  options={options}
                  selection
                  placeholder="Jr, Sr. etc."
                  onChange={handleDropdownChange}
                />
              </Form.Field>
            </Wrapper>
            <Form.Field required>
              <label>Phone Number</label>

              <MaskedInput
                type="text"
                name="phone"
                placeholder="Phone Number"
                mask={phoneMask}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
              />
              {errors.phone && touched.phone && <ValidationError content={errors.phone} />}
            </Form.Field>
            <Form.Field>
              <label>Social Security Number</label>
              <MaskedInput
                type="text"
                name="ssn"
                placeholder="Social Security Number"
                mask={SSNMask}
                className="inspectlet-sensitive"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ssn}
              />
            </Form.Field>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AfterpaymentEIN
