
import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Icon, Container } from 'semantic-ui-react'
import { useSelector } from 'react-redux'

import { usePartner } from 'utils/hooks'
import { RootState } from 'store/rootState'

import WelcomeBackground from 'img/welcome-bg.jpg'
import { CallBanner } from './CallBanner'

const StyledWelcome = styled.div`
  width: 750px;
  max-width: 100%;
  margin: 0 auto 50px;
  padding: 80px 0 0;

  position: relative;

  text-align: center;

  z-index: 5;
`

const Controls = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  margin-top: 40px;

  @media screen and (max-width: 660px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
  }
`

const Caption = styled.h1`
  font-size: 30px;
  font-weight: 600;
`

const WelcomeText = styled.div`
  margin-top: 40px;
  padding: 20px;

  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  font-size: 18px;
  color: #fff;
  text-align: justify;

  strong {
    font-weight: 900;
  }
`

const BodyBackground = createGlobalStyle`
  body {
    background-image: url(${WelcomeBackground});
    background-position: 50% 50%;
    background-size: cover;
  }
`

function Welcome() {
  const urlParamsString = useSelector<any, any>(state => state.router.location.search)
  const [, isBetterlegal] = usePartner()
  // const welcomeText = useSelector((state: RootState) => state.auth.partnerInfo.welcome_text)

  // function createMarkup() {
  //   return { __html: welcomeText }
  // }

  return (
    <>
      <div style={{ marginTop: -20 }}>
        <CallBanner />
      </div>
      <Container>
        {isBetterlegal && <BodyBackground />}
        <StyledWelcome>
          <Caption style={{ color: isBetterlegal ? '#fff' : '#000' }}>
            What type of business would you like to set up?
          </Caption>
          <Controls>
            <Link to={{ pathname: '/create-llc', search: urlParamsString }}>
              <Button fluid color={isBetterlegal ? 'teal' : 'grey'} size="massive" animated="fade">
                <Button.Content visible>Form an LLC</Button.Content>
                <Button.Content hidden>
                  <Icon name="file text" />
                </Button.Content>
              </Button>
            </Link>
            <Link to={{ pathname: '/create-inc', search: urlParamsString }}>
              <Button fluid color={isBetterlegal ? 'teal' : 'grey'} size="massive" animated="fade">
                <Button.Content visible>Form a Corporation</Button.Content>
                <Button.Content hidden>
                  <Icon name="building" />
                </Button.Content>
              </Button>
            </Link>
          </Controls>
          {/* {welcomeText && <WelcomeText dangerouslySetInnerHTML={createMarkup()} />} */}
        </StyledWelcome>
      </Container>
    </>
  )
}

export default Welcome
