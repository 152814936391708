import styled from 'styled-components';

import axiosInstance from "utils/axiosInstance"

export const fetchActivities = async orgId => {
  try {
    const response = await axiosInstance.get(`/activity?org_id=${orgId}&feeds=true`)
    return response.data.results
  } catch (err) {
    console.log(err)
  }
}

export const StyledProfilePanel = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const StyledProfileOverviewContainer = styled.div`
  padding: 10px 15px;

  & .ui.fluid.dropdown {
    padding: 10px !important;
    margin-bottom: 2px;
    /* margin-bottom: {(officeAddress || registeredAddress) ? '':'2px}; */

    border-radius: 0 !important;
    border: 1px solid #efefef !important;

    transition: 0s !important;

    &:hover {
      border-color: #36978b !important;

      outline: none;
    }

    & input {
      padding-left: 10px !important;
    }
  }
`

export const OrgInfoRow = styled.div<{ payment?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 150px 1fr;

  padding: 2px ${p => p.payment ? `15px` : 0};
  margin: 5px 0;

  background-color: #fff;

  label {
    font-weight: 600;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: 10px;
  }
`

export const OrgInfoPanel = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`