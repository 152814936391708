export const WS_TIMEOUT = 3000
export const ACITIVITY_VOICE_RECORDING_LIMIT = 512
export const EMPTY_URL_PARAM = '_____'

export const DEFAULT_INC_SHARES = 100000
export const DEFAULT_INC_SHARE_VALUE = 0.001
export const DEFAULT_INC_TYPE = 'C Corp'

export const ASSUMED_INSPECTLET_WID = 1575657182

export const DOMAIN = 'betterlegal.com'
export const PHONE_NUMBER = '(855) 612-0480'

export const PERMIT_AND_LICENSE_SEARCH_PRICE = 125

/* TypeForm */
export const MULTI_MEMBER_LLC_BETTERLEGAL_LOCATION = 'qBwktzE9'
export const MULTI_MEMBER_LLC_PARTNER_LOCATION = 'ZsedmaOY'

export const ALLSTATE = 'Allstate'
export const SINGLE_MEMBER_LLC_BETTERLEGAL_LOCATION = 'zUTNHZ8t'
export const SINGLE_MEMBER_LLC_PARTNER_LOCATION = 'jdD36Ap9'

export const SINGLE_DIRECTOR_INC_BETTERLEGAL_LOCATION = 'X85lpIhT'
export const SINGLE_DIRECTOR_INC_PARTNER_LOCATION = 's815Gb'

export const MULTI_DIRECTOR_INC_BETTERLEGAL_LOCATION = 'GVyiFQgK'
export const MULTI_DIRECTOR_INC_PARTNER_LOCATION = 'XkLpGD'
