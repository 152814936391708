import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { usePartner } from 'utils/hooks'

const StyledCallBanner = styled.div<{ rounded?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;
  margin: 0 0 10px;

  background-color: #563d5c;
  border-radius: ${p => (p.rounded ? '4px' : '0')};

  color: #fff;
  font-weight: 400;
  font-size: 1.35em;
  text-align: center;

  a {
    margin: 0 0.4em;

    font-weight: 600;
    font-size: 1.2em;
    color: #fff;
  }
`

interface CallBannerPropsType {
  rounded?: boolean
}

// export function CallBanner({ rounded }: CallBannerPropsType) {
//   const [, isBetterlegal] = usePartner()
//   const [agentAvailable, setAgentAvailable] = useState(false)

//   useEffect(() => {
//     if (isBetterlegal) {
//       fetch('https://almond-lobster-2643.twil.io/is-sales-agent-online')
//         .then(response => response.json())
//         .then(data => setAgentAvailable(data.isAvailable))
//     }
//   }, [isBetterlegal])

//   return (
//     <>
//       {isBetterlegal && agentAvailable && (
//         <StyledCallBanner rounded={rounded}>
//           Call <a href="tel:8556120480">(855) 612-0480</a> for any questions
//         </StyledCallBanner>
//       )}
//     </>
//   )
// }

export function CallBanner({ rounded }: CallBannerPropsType) {
  return null
}
