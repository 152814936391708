import React, { useState, useEffect, useRef, useCallback } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import ReactMarkdown from 'react-markdown'
import ReactMde from 'react-mde'
import { Link } from 'react-router-dom'
import { Container, Select, Button, Dropdown, Menu } from 'semantic-ui-react'

import LoadingBar, { showLoading, hideLoading } from 'react-redux-loading-bar'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'

import axiosInstance from 'utils/axiosInstance'
import { createUserRightsObject } from 'utils/userRights'

import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'

import 'react-mde/lib/styles/css/react-mde-all.css'

const StyledActivitiesListControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 10px 0 20px;

  h3 {
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;

    button {
      height: 2.85714286em;
    }

    .ui.menu {
      margin: 0 0 0 1rem;

      border: none;
    }
  }
`

const StyledContainer = styled(Container)`
  margin-bottom: 50px;

  position: relative;

  @media screen and (max-width: 1200px) {
    &.ui.container {
      width: 100%;
      padding: 0 15px;
    }
  }
`

const StyledDocument = styled.div`
  display: flex;

  margin-bottom: 20px;

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
`

const StyledDocumentForm = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px 15px;
  margin: 0 auto;
  width: 27.5% !important;

  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;

  .ui.button {
    margin: 5px auto 20px;
  }
`

const StyledDocumentFormRow = styled.div`
  display: grid;
  align-items: center;

  grid-row-gap: 10px;
  padding: 2px 0;
  margin-bottom: 15px;

  background-color: #fff;

  label {
    font-weight: bold;
    margin: 10px 0;
  }
  .ui.selection.dropdown {
    border-radius: 0;
    border: 1px solid #efefef;
    &:hover,
    &.active {
      border-color: #96c8da;
    }
  }
  textarea {
    min-height: 100px;
  }
`

const StyledDocumentText = styled.div<{ isPreview: boolean }>`
  position: relative;
  padding: 20px 30px;
  margin: ${p => (p.isPreview ? 'auto' : '0')};
  width: 72.5%;
  min-height: 100%;
  p {
    font-size: 16px;
  }
  span {
    background-color: #d8f0ed;
    font-style: italic;
  }
  ul {
    margin: 0;
    padding: 0;

    list-style: none;
    li {
      margin-top: 10px;
      text-indent: 20px;
    }
  }
  hr {
    max-width: 150px;
    margin: 0;
  }
  .react-mde {
    position: sticky;
    top: 20px;

    .mde-tabs {
      display: none;
    }
    .mde-header {
      display: ${p => (p.isPreview ? 'none' : 'block')};
    }
    .mde-preview-content {
      span {
        color: purple;
        background-color: #c7acff;
      }
      a {
        color: #000;
        text-decoration: none;
        &:hover,
        &.active {
          color: #000;
          text-decoration: none;
        }
      }
    }
  }
`

function escapeRegExp(stringToGoIntoTheRegex) {
  return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}

function Document({ match }) {
  const [value, setValue] = React.useState('')

  const [templateFields, settemplateFields] = useState([])
  const [name, setname] = useState('')
  const [isEditing, setisEditing] = useState(false)
  const [isSubmitting, setisSubmitting] = useState(false)

  const [key, setkey] = useState(Date.now())

  const timeout = useRef(null)
  const someref = useRef(null)

  const dispatch = useDispatch()

  const userRole = useSelector<any, any>(state => state.auth.userData.role)
  const userRights = createUserRightsObject(userRole)

  const documentId = match.params.id

  useEffect(() => {
    async function fetchDocument() {
      try {
        const response = await axiosInstance({
          method: 'GET',
          url: `/documents/forms/${documentId}`,
        })

        const keys = response.data.template_fields || []

        const fields = keys.map(key => ({ key, value: '' }))

        if (fields.length) {
          settemplateFields(fields)
        }

        setname(response.data.name)
        setValue(response.data.markdown_content)
      } catch (err) {
        toast.error('Document not found')
        dispatch(push('/documents'))
      }
    }

    fetchDocument()
  }, [documentId])

  const saveMarkdown = async newMarkdown => {
    const data = new FormData()
    data.append('name', name)
    data.append('markdown_content', newMarkdown)

    dispatch(showLoading())

    const response = await axiosInstance({
      method: 'PATCH',
      url: `/documents/forms/${documentId}/`,
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    })

    dispatch(hideLoading())

    const keys = response.data.template_fields || []

    const fields = keys.map(key => ({ key, value: '' }))

    if (fields.length) {
      settemplateFields(fields)
    }
  }

  const onChange = e => {
    setValue(e)

    const invalidFields = e.match(/\[\[\d[^\[]*\]\]/g)
    const isValid = !Boolean(invalidFields)

    if (isValid) {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      // throttle requests to 3000ms
      timeout.current = setTimeout(() => {
        saveMarkdown(e)
      }, 3000)
    } else {
      toast.error('Field name cannot start with a number.')
    }
  }

  const onFieldChange = (index, newValue) => {
    const copy = _.cloneDeep(templateFields)

    copy[index].value = newValue

    settemplateFields(copy)

    // we need to trigger re-render of ReactMde preview
    setkey(Date.now())
  }

  const deleteDocument = async () => {
    dispatch(showLoading())

    try {
      await axiosInstance({
        method: 'DELETE',
        url: `/documents/forms/${documentId}`,
      })

      toast.success('Document has been deleted successfully')
      dispatch(push('/documents'))
    } catch (err) {
      console.error(err)
    }

    dispatch(hideLoading())
  }

  const generateMarkdownPreview = useCallback(
    markdown => {
      let sanitizedMarkdown = markdown
        .replace(/\\n/gi, '\n &nbsp;')
        .replace(/\\\\\\\\/gi, '')
        .replace(/\\/gi, '\n &nbsp;')

      // replace preview with filled fields
      templateFields.forEach(field => {
        const strForRegex = escapeRegExp('[[' + field.key + ']]')
        const regex = new RegExp(strForRegex, 'gi')
        const hasValue = Boolean(field.value)

        sanitizedMarkdown = sanitizedMarkdown.replace(
          regex,
          `<span>${hasValue ? field.value : '[[' + field.key + ']]'}</span>`
        )
      })

      return Promise.resolve(<ReactMarkdown source={sanitizedMarkdown} allowDangerousHtml={true} />)
    },
    [templateFields]
  )

  const onDownload = useCallback(
    async format => {
      dispatch(showLoading('editDocument'))
      setisSubmitting(true)

      const fields = {}

      templateFields.forEach(({ key, value }) => {
        fields[key] = value
      })

      try {
        const response = await axiosInstance({
          method: 'POST',
          url: `/documents/forms/${documentId}/merge_form/?render_docx=true`,
          data: {
            form_type: format,
            fields: JSON.stringify(fields),
          },
        })
        dispatch(hideLoading('editDocument'))
        setisSubmitting(false)

        if (response.data.file) {
          const element = document.createElement('a')
          element.setAttribute('href', response.data.file)
          element.setAttribute('download', name)

          element.style.display = 'none'
          document.body.appendChild(element)

          element.click()

          document.body.removeChild(element)

          toast.success('Document has been generated')
        } else {
          toast.error('Something went wrong. Try again.')
        }
      } catch (error) {
        toast.error('Error generating document. Try again.')
        setisSubmitting(false)
        dispatch(hideLoading('editDocument'))
      }
    },
    [templateFields, name]
  )

  return (
    <>
      <LoadingBar
        scope="editDocument"
        style={{ backgroundColor: '#36978b', height: '5px', zIndex: 6, position: 'fixed', top: 0 }}
        updateTime={1000}
        maxProgress={95}
        progressIncrease={5}
      />
      <StyledContainer>
        <Link to="/documents">
          <Button color="grey" icon="arrow left" content="Back" />
        </Link>
        <StyledActivitiesListControls>
          <h3>{name}</h3>
          <div>
            {/* <Button
              color={isEditing ? 'blue' : 'teal'}
              onClick={() => setisEditing(!isEditing)}
              content={isEditing ? 'Preview' : "Edit"}
              icon={isEditing ? null : 'pencil alternate'}
            /> */}
            {userRights.removeDocument && (
              <Button
                color="teal"
                onClick={deleteDocument}
                content="Remove Document"
                icon="trash alternate"
              />
            )}
            <Menu>
              <Dropdown text="Download" simple item>
                <Dropdown.Menu>
                  <Dropdown.Item
                    disabled={isSubmitting}
                    icon="file text"
                    text="docx"
                    onClick={() => onDownload('DOCX')}
                  />
                  <Dropdown.Item
                    disabled={isSubmitting}
                    icon="file pdf"
                    text="pdf"
                    onClick={() => onDownload('PDF')}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </div>
        </StyledActivitiesListControls>
        <StyledDocument>
          <StyledDocumentForm>
            {templateFields.map((field, i) => (
              <StyledDocumentFormRow key={i}>
                <label>{field.key}</label>

                <EditableSimpleField
                  fieldName={field.key}
                  data={{ [field.key]: field.value }}
                  readOnly={isEditing}
                  wsHandler={data => {
                    onFieldChange(i, data[field.key])
                  }}
                />

                {/* <Select
                  options={[]}
                  // value={bState.value}
                  // onChange={(e, data) => handleStateChange(e, data)}
                  style={{ minWidth: 'auto' }}
                /> */}
              </StyledDocumentFormRow>
            ))}
            <Menu>
              <Dropdown text="Download" simple item style={{ width: '100%' }}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    disabled={isSubmitting}
                    icon="file text"
                    text="docx"
                    onClick={() => onDownload('DOCX')}
                  />
                  <Dropdown.Item
                    disabled={isSubmitting}
                    icon="file pdf"
                    text="pdf"
                    onClick={() => onDownload('PDF')}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </StyledDocumentForm>
          <StyledDocumentText isPreview={!isEditing}>
            <ReactMde
              key={key}
              ref={c => {
                someref.current = c
              }}
              value={value}
              onChange={onChange}
              selectedTab={isEditing ? 'write' : 'preview'}
              onTabChange={value => {
                setisEditing(value === 'write')
              }}
              generateMarkdownPreview={generateMarkdownPreview}
              minEditorHeight={600}
            />
          </StyledDocumentText>
        </StyledDocument>
      </StyledContainer>
    </>
  )
}

export default Document
