import React from 'react'
import styled from 'styled-components'

const OneMenuWrapper = styled.div`
  background-color: #fff;
  width: 212px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 3px 2px #22242626;
  border-radius: 0 0 0.28571429 rem 0.28571429 rem;
  font-family: ${p => p.theme.createLLC.font};
`

const CopyLinkButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  height: 42px;
  background-color: #fff;
  border: none;
  font-size: 15px;
  padding: 0;

  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`

const ShareIconsWrapper = styled(OneMenuWrapper)`
  display: none;
  width: 170px;
  position: absolute;
  left: 212px;
  top: 0;
`

const ShareMyLink = styled.div`
  width: 100%;
  display: flex;
  height: 42px;
  background-color: #fff;
  border: none;
  font-size: 15px;

  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }

  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &:hover ${ShareIconsWrapper} {
    display: block;
  }
`

const ShareViaButton = styled(CopyLinkButton)`
  padding-left: 10px;
`

const ShareMyLinkParagraph = styled.p`
  padding-left: 16px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Title = styled.h5`
  font-weight: 900;
  margin-top: 0;
  margin-left: 16px;
  margin-bottom: 16px;
`

const Steps = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const StepItem = styled.li`
  margin-left: 16px;
  margin-bottom: 16px;
`

const StepItemTitle = styled.span`
  color: #36978b;
  font-weight: 900;
  margin-right: 4px;
`

export const ReferralDropdownMenu = ({
  onClickCopyLink: handleClickCopyLink = () => {},
  // onClickShareViaFacebook: handleClickShareViaFacebook = () => {},
  onClickShareViaLinkedin: handleClickShareViaLinkedin = () => {},
  onClickShareViaTwitter: handleClickShareViaTwitter = () => {},
  onClickShareViaEmail: handleClickShareViaEmail = () => {},
}) => {
  return (
    <OneMenuWrapper>
      <CopyLinkButton onClick={handleClickCopyLink}>
        <p style={{ paddingLeft: 16 }}>
          <i className="linkify icon black"></i>
          Copy link
        </p>
      </CopyLinkButton>
      <ShareMyLink>
        <ShareMyLinkParagraph>
          <span>
            <i className="share icon black"></i>
            Share my link
          </span>
          <i className="chevron right icon black small"></i>
        </ShareMyLinkParagraph>
        <ShareIconsWrapper>
          {/* <ShareViaButton onClick={handleClickShareViaFacebook}>Share via Facebook</ShareViaButton> */}
          <ShareViaButton onClick={handleClickShareViaLinkedin}>Share via LinkedIn</ShareViaButton>
          <ShareViaButton onClick={handleClickShareViaTwitter}>Share via Twitter</ShareViaButton>
          <ShareViaButton onClick={handleClickShareViaEmail}>Share via Email</ShareViaButton>
        </ShareIconsWrapper>
      </ShareMyLink>
      <div className="ui divider" style={{ marginTop: 5, marginBottom: 16 }}></div>
      <Title>HOW IT WORKS</Title>
      <Steps>
        <StepItem>
          <p>
            <StepItemTitle>Step 1.</StepItemTitle>Refer your friends
          </p>
        </StepItem>
        <StepItem>
          <p>
            <StepItemTitle>Step 2.</StepItemTitle>Friends will get $100 coupon
          </p>
        </StepItem>
        <StepItem>
          <p>
            <StepItemTitle>Step 3.</StepItemTitle>
            You get a $100 check after your friend's order is delivered.
          </p>
        </StepItem>
      </Steps>
    </OneMenuWrapper>
  )
}
