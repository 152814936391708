import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'

import { Label, Icon } from 'semantic-ui-react'

const StyledProfileInfoHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 15px;

  border-radius: 4px 4px 0 0;
  background-color: #f7f8fb;

  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: black;

  & > span {
    display: flex;
    align-items: center;

    & > img {
      margin-left: 5px;

      opacity: 0.33;

      transition: opacity 0.2s;

      &:hover {
        opacity: 0.66;
      }
    }

    &:not(:first-child) {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }
`

const AffiliateBtn = styled.button`
  padding: 0;
  margin: 0 2px;

  background: none;
  border: none;

  opacity: 0.65;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

const CancelButton = styled.button`
  padding: 3px 10px;

  background-color: #e8e8e8;
  border: none;
  border-radius: 4px;

  font-size: 11px;
  font-weight: 600;

  cursor: pointer;

  &:hover {
    background-color: ${darken(0.1, '#e8e8e8')};
  }
`

function MembersCount({ members }) {
  return members ? (
    <Label size="mini" style={{ marginLeft: 5 }}>
      {members}
    </Label>
  ) : null
}

interface ProfileInfoHeaderProps {
  caption: string
  members?: number
  toggleRemoveMode?: Dispatch<SetStateAction<boolean>>
  removeMode?: boolean
  addAffiliate?: () => void
  readOnly?: boolean
}

export function ProfileInfoHeader({
  caption,
  members,
  toggleRemoveMode,
  removeMode,
  addAffiliate,
  readOnly,
}: ProfileInfoHeaderProps) {
  return (
    <StyledProfileInfoHeader>
      <div>
        {caption}
        <MembersCount members={members} />
      </div>
      {members && (
        <>
          {removeMode ? (
            <CancelButton onClick={() => toggleRemoveMode(false)}>Cancel</CancelButton>
          ) : (
            <div>
              <AffiliateBtn onClick={() => toggleRemoveMode(true)} disabled={readOnly}>
                <Icon name="minus" />
              </AffiliateBtn>
              <AffiliateBtn onClick={addAffiliate} disabled={readOnly}>
                <Icon name="plus" />
              </AffiliateBtn>
            </div>
          )}
        </>
      )}
    </StyledProfileInfoHeader>
  )
}
