import { put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import _ from 'lodash';

import axiosInstance from 'utils/axiosInstance'
import {
  newEditInfoUpdatePayment, newEditInfoUpdateProfile
  // newEditAffiliateUpdate
} from '../actions/newEditInfo'
import { paymentGetOrg } from '../actions/payment'
import { mapOrgData } from 'utils/paymentUtils'
import { fetchOrg } from 'store/slices/orgSlice'
import { isProduction } from 'utils/isProduction';


export function* newEditInfoSaga(action) {
  const { orgId, data } = action

  const isStatus = Object.keys(data).length === 1 && Object.keys(data)[0] === 'status'

  if (isStatus && isProduction()) {
    try {
      const hookData = {
        organizationId: orgId,
        status: data.status
      }

      fetch('https://hooks.zapier.com/hooks/catch/962269/lcwf5t/', {
        method: 'POST',
        body: JSON.stringify(hookData)
      })
    } catch (err) {
      yield console.error('Zapier Error: ', err)
    }
  }

  try {
    const response = yield axiosInstance.request({
      method: 'put',
      url: `/business/organization/LLC/${orgId}`,
      data
    })

    yield put(newEditInfoUpdatePayment(mapOrgData(response.data)))
    yield put(newEditInfoUpdateProfile(response.data))
  } catch (err) {
    const errorsObject = err.response.data
    let errors = ''

    Object.keys(errorsObject).map(key => {
      if (key === 'address') {
        Object.keys(errorsObject.address).map(
          key => (errors += `${key} : ${errorsObject.address[key]}\n`)
        )
      } else {
        errors += `${key} : ${errorsObject[key]}\n`
      }
      return undefined
    })

    yield toast.error(errors)
  }
}

export function* newEditAgentSaga(action) {
  const { orgId, agentId, data, cb } = action;

  try {
    const response = yield axiosInstance.request({
      method: 'put',
      url: `/business/organization/${orgId}/agent/${agentId}`,
      data
    })
    yield put(cb(response.data))
  } catch (err) {
    const errorsObject = err.response.data
    let errors = ''

    Object.keys(errorsObject).map(key => {
      if (key === 'address') {
        Object.keys(errorsObject.address).map(
          key => (errors += `${key} : ${errorsObject.address[key]}\n`)
        )
      } else {
        errors += `${key} : ${errorsObject[key]}\n`
      }
      return undefined
    })

    yield toast.error(errors)
  }
}

export function* newEditAffiliateSaga(action) {
  const { orgId, affiliateId, data } = action

  data.fields.invested = _.replace(data.fields.invested, /[$,]/g, '')
  data.fields.stake = _.replace(data.fields.stake, /[,%]/g, '')

  try {
    // const response = yield axiosInstance.request({
    yield axiosInstance.request({
      method: 'put',
      url: `/business/organization/${orgId}/affiliate/${affiliateId}`,
      data
    })

    yield put(fetchOrg(orgId))
    yield put(paymentGetOrg(orgId))
  } catch (err) {
    const errorsObject = err.response.data
    let errors = ''

    Object.keys(errorsObject).map(key => {
      if (key === 'address') {
        Object.keys(errorsObject.address).map(
          key => (errors += `${key} : ${errorsObject.address[key]}\n`)
        )
      } else {
        errors += `${key} : ${errorsObject[key]}\n`
      }
      return undefined
    })

    yield toast.error(errors)
  }
}
