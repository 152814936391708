import { Tab } from 'semantic-ui-react'
import styled, { ThemeProvider } from 'styled-components'

import IncludedTab from 'components/Payment/IncludedTab'
import PaymentOrgInfo from 'components/Payment/PaymentOrgInfo/PaymentOrgInfo'
import { usePartner } from 'utils/hooks'
import themes from 'utils/themes'
import * as vars from 'utils/styledVariables'

const StyledTabMenu = styled(Tab)`
  /* box-shadow: ${vars.shadow}; */

  & > .ui.pointing.secondary.menu {
    margin-bottom: 0;

    border-radius: 4px 4px 0 0;
    border: none;
    background-color: ${p => p.theme.lightBright};

    & > .item {
      height: 40px;
      margin: 0 20px;
      padding: 0;

      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (max-width: 480px) {
        margin: 0 10px;
      }

      &.active {
        border-width: 3px;
        border-color: ${p => p.theme.accent};

        font-weight: 900;
      }
    }
  }

  & > .ui.segment {
    margin-top: 0;
    padding: 0;

    box-shadow: none;
    border-radius: 0 0 4px 4px;
    border: none;
  }
`

export default function PaymentTabs(props) {
  const [, isBetterlegal] = usePartner()

  const { orgId } = props

  const panes = []

  if (!props.simplified) {
    panes.push({
      menuItem: 'Organization Info',
      render: () => (
        <Tab.Pane attached={false}>
          <PaymentOrgInfo orgId={orgId} confirmTooltips={props.confirmTooltips} />
        </Tab.Pane>
      ),
    })
  }

  if (!props.onlyInfo) {
    panes.push({
      /* eslint-disable-next-line quotes */
      menuItem: "What's Included",
      render: () => (
        <Tab.Pane attached={false}>
          <IncludedTab />
        </Tab.Pane>
      ),
    })
  }

  return (
    <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
      <StyledTabMenu menu={{ secondary: true, pointing: true }} panes={panes} />
    </ThemeProvider>
  )
}
