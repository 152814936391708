import * as Yup from 'yup'

export const partnerSettingsSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  address: Yup.object()
    .shape({
      address1: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      province: Yup.string().required('Required'),
      zipcode: Yup.string().required('Required')
    })
    .required('Required'),
  email: Yup.string()
    .email('Incorrect email')
    .required('Required'),
  fee: Yup.number()
    .typeError('Should be a number')
    .moreThan(-1, 'Should be positive number or zero')
    .required('Required'),
  fees: Yup.object().shape({
    scs: Yup.object().shape({
      monthly: Yup.number()
        .typeError('Should be a number')
        .moreThan(9, "Can't be less than 10")
        .required('Required'),
      yearly: Yup.number()
        .typeError('Should be a number')
        .moreThan(89, "Can't be less than 90")
        .required('Required'),
    }),
    agent: Yup.object().shape({
      monthly: Yup.number()
        .typeError('Should be a number')
        .moreThan(9, "Can't be less than 10")
        .required('Required'),
      yearly: Yup.number()
        .typeError('Should be a number')
        .moreThan(89, "Can't be less than 90")
        .required('Required'),
    })
  }),
  welcome_text: Yup.string()
})