
import { Field, ErrorMessage } from 'formik'
import styled from 'styled-components'

import { AddressAutocompleteDropdown } from '../AddressAutocompleteDropdown'

import { CustomInput } from '../CustomFields'
import { ValidationError } from '../ValidationError'

import { isDefaultAddress } from 'utils/simplifiedAddress'

const StyledPerson = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas:
    'fullName fullName fullName fullName address1 address1 address1 address1'
    'email email email email city city state zipcode'
    'phone phone phone phone . . . .';
  gap: 2px;

  padding: 8px;

  @media screen and (max-width: 480px) {
    grid-template-areas:
      'fullName fullName fullName fullName'
      'email email email email'
      'phone phone phone phone'
      'address1 address1 address1 address1'
      'city city state zip';
  }
`

export function EditablePersonSimplified({
  userRights,
  loading,
  editMode,
  address,
  onChange,
  useRaAddress
}) {
  const isDefault = useRaAddress

  return (
    <>
      <StyledPerson>
        <Field
          disabled={loading || !userRights.editInfo}
          active={editMode}
          name={'user.full_name'}
          placeholder="Full name"
          component={CustomInput}
          style={{ gridArea: 'fullName' }}
        />

        <Field
          disabled={loading || !userRights.editInfo}
          active={editMode}
          name={'user.email'}
          placeholder="Email"
          component={CustomInput}
          style={{ gridArea: 'email' }}
        />
        <Field
          disabled={loading || !userRights.editInfo}
          active={editMode}
          placeholder="Phone"
          name={'user.phone'}
          component={CustomInput}
          style={{ gridArea: 'phone' }}
        />

        <Field name="address">
          {() => {
            return (
              <AddressAutocompleteDropdown
                defaultOption={{
                  key: 'agent',
                  value: 'agent',
                  text: 'Use Registered Agent Address',
                }}
                isDefault={isDefault}
                placeholder="Registered Agent Address"
                style={{ gridArea: 'address1' }}
                value={address?.address1}
                readOnly={!userRights.editInfo}
                wsHandler={address => {
                  if (!address) {
                    // do nothing if ra selected and user click on it
                    if (!isDefault) {
                      onChange('address', null)
                    }
                  } else {
                    onChange('address', address)
                  }
                }}
              />
            )
          }}
        </Field>

        {!isDefault && (
          <>
            <Field
              disabled={loading || !userRights.editInfo}
              active={editMode}
              placeholder="City"
              name={'address.city'}
              component={CustomInput}
              style={{ gridArea: 'city' }}
            />
            <Field
              disabled={loading || !userRights.editInfo}
              active={editMode}
              placeholder="State"
              name={'address.province'}
              component={CustomInput}
              style={{ gridArea: 'state' }}
            />
            <Field
              disabled={loading || !userRights.editInfo}
              active={editMode}
              placeholder="Zipcode"
              name={'address.zipcode'}
              component={CustomInput}
              style={{ gridArea: 'zipcode' }}
            />
          </>
        )}
      </StyledPerson>

      <ul style={{ padding: '0 5px', listStyle: 'none' }}>
        <ErrorMessage name="address.address1" component={ValidationError} />
        <ErrorMessage name="address.city" component={ValidationError} />
        <ErrorMessage name="address.province" component={ValidationError} />
        <ErrorMessage name="address.zipcode" component={ValidationError} />
        <ErrorMessage name="user.first_name" component={ValidationError} />
        <ErrorMessage name="user.last_name" component={ValidationError} />
        <ErrorMessage name="user.email" component={ValidationError} />
        <ErrorMessage name="user.phone" component={ValidationError} />
      </ul>
    </>
  )
}
