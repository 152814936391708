import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import moment from 'moment'
import { useSelector } from 'react-redux'

import themes from 'utils/themes'
import { ReducerType } from 'store/reducers'
import { useUrlParams } from 'utils/hooks'

const StyledCouponBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 65px;

  position: fixed;
  top: 0;
  left: 0;

  background: ${p => p.theme.main};

  font-size: 21px;
  color: #fff;

  z-index: 10;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    height: auto;
    min-height: 65px;
    padding: 15px;

    position: relative;
  }

  > strong {
    margin-right: 10px;

    font-weight: 700;
  }

  > span {
    margin: 0 10px;
    padding: 2px 10px;

    border: 1px dashed #fff;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.2);
  }
`

interface CouponBannerProps {
  couponCode?: string
  orgType?: string
  discount?: number
  couponTimeDiff?: moment.Duration
  affiliate?: string
  chamber?: string
  couponName?: string
  paramsAffiliate: ReducerType['payment']['paramsAffiliate']
}

export function CouponBanner({
  affiliate,
  chamber,
  couponCode,
  orgType,
  couponTimeDiff,
  discount,
  couponName,
  paramsAffiliate,
}: CouponBannerProps) {
  const [couponTimeLabel, setCouponTimeLabel] = useState<string>('')

  useEffect(() => {
    if (couponTimeDiff && couponTimeDiff.as('milliseconds') > 0) {
      setCouponTimeLabel(
        `${couponTimeDiff.days() > 0 ? couponTimeDiff.days() + ' days' : ''} ${
          couponTimeDiff.hours() > 0 ? couponTimeDiff.hours() + ' hours' : ''
        } ${couponTimeDiff.minutes() > 0 ? couponTimeDiff.minutes() + ' minutes' : ''}`
      )
    }
  }, [couponTimeDiff])

  if (!discount) return null

  if (affiliate) {
    return (
      <ThemeProvider theme={themes.standard}>
        <StyledCouponBanner>
          <span>$100 off</span> from <strong style={{ marginLeft: '.25em' }}>{affiliate}</strong>
        </StyledCouponBanner>
      </ThemeProvider>
    )
  }

  if (paramsAffiliate.code) {
    return (
      <StyledCouponBanner style={{ backgroundColor: '#397ab8' }}>
        <span>${discount} off</span> from{' '}
        <strong style={{ marginLeft: '.25em', marginRight: '.25em' }}>
          {paramsAffiliate.name}
        </strong>
        at
        <strong style={{ marginLeft: '.25em' }}>Allstate</strong>
      </StyledCouponBanner>
    )
  }

  if (chamber) {
    return (
      <ThemeProvider theme={themes.standard}>
        <StyledCouponBanner>
          <span>$100 off</span> from <strong style={{ marginLeft: '.25em' }}>{chamber}</strong>
        </StyledCouponBanner>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={themes.standard}>
      <StyledCouponBanner>
        <strong>{couponCode}</strong> added for <span>${discount}</span> off your {orgType}{' '}
        {couponName && `from ${couponName}`}
        {!!couponTimeDiff && <span>{couponTimeLabel} left</span>}
      </StyledCouponBanner>
    </ThemeProvider>
  )
}
