import React, { useEffect, useState } from 'react'
import { Container, Grid, Label } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { useOrgWebSocket, usePartner } from 'utils/hooks'
import { formatOrgType } from 'utils/orgType'
import { createUserRightsObject } from 'utils/userRights'
import { WS_TIMEOUT } from 'utils/constants'
import { isMatchRaAddress } from 'utils/simplifiedAddress'
import ProfileStatus from 'components/Profile/ProfileStatus'
import ProfileInc from 'components/Profile/ProfileInfoSimplified/ProfileIncSimplified'
import ProfileLLC from 'components/Profile/ProfileInfoSimplified/ProfileLLCSimplified'
import ProfileContact from 'components/Profile/ProfileContact'
import ProfileSidebar from 'components/Profile/Sidebar/ProfileSidebar'
import EditableDate from 'components/UI/Editable/EditableDate'
import EditableStatus from 'components/UI/Editable/EditableStatus'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import ProfileSole from 'components/Profile/ProfileInfoSimplified/ProfileSoleSimplified'
import { fetchActivities } from 'components/UI/orgHelpers'
import {
  StyledProfileHeader,
  StyledProfileName,
  StyledProfileStatuses,
} from 'components/Profile/profileStyles'

export interface AffiliateType {
  id: string
  type: string
  user: any
  order: number
  address: any
  fields: any
}

function Profile(props) {
  const [activities, setActivities] = useState([])
  const currentOrgId = props.match.params.id
  const [orgData, handlers] = useOrgWebSocket(currentOrgId)

  const { partnerInfo, userData } = useSelector<any, any>(state => state.auth)

  const { wsOrgInfo } = handlers

  const userRights = createUserRightsObject(userData?.role)

  const [partner] = usePartner()

  useEffect(() => {
    fetchActivities(currentOrgId).then(result => setActivities(result))
  }, [currentOrgId, orgData])

  const updateRAaddress = newAgent => {
    const shouldUpdateOfficeAddress = isMatchRaAddress(orgData.address, orgData.agent.address)

    if (shouldUpdateOfficeAddress) {
      const orgUpdates = {
        address: newAgent.address,
      }
      wsOrgInfo(orgUpdates)
    }

    orgData.directors.forEach(director => {
      if (isMatchRaAddress(director.address, orgData.agent.address)) {
        handlers.wsAffiliate({ ...director, address: newAgent.address })
      }
    })

    handlers.wsAgent(newAgent)
  }

  function renderProfile(orgData, handlers, userRights) {
    let Component

    switch (orgData.type.toLowerCase()) {
      case 'sole':
        Component = ProfileSole
        break
      case 'inc':
        Component = ProfileInc
        break
      case 'llc':
      default:
        Component = ProfileLLC
        break
    }

    return (
      <Component
        org={orgData}
        userRights={userRights}
        handlers={handlers}
        updateRAaddress={updateRAaddress}
      />
    )
  }

  return (
    orgData && (
      <Container>
        <StyledProfileHeader>
          <StyledProfileName>
            <EditableSimpleField
              fieldName="name"
              data={{ name: orgData.name }}
              readOnly={!userRights.editInfo}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              style={{ fontSize: '16px' }}
            />
            {orgData?.type?.toLowerCase() !== 'sole' && (
              <span style={{ marginLeft: 10 }}>{` ${formatOrgType(orgData.type)}`}</span>
            )}
            {partnerInfo.username === 'betterlegal' && <ProfileContact org={orgData} />}
          </StyledProfileName>

          <StyledProfileStatuses>
            {orgData.partner.username !== partner && (
              <ProfileStatus>
                <Label color="blue">
                  Partner
                  <Label.Detail>{orgData.partner.username}</Label.Detail>
                </Label>
              </ProfileStatus>
            )}
            <ProfileStatus caption="Status">
              <EditableStatus
                data={orgData.status}
                orgId={orgData.id}
                readOnly={!userRights.editInfo}
                wsHandler={wsOrgInfo}
              />
            </ProfileStatus>

            <ProfileStatus caption="Date filed">
              <EditableDate
                data={orgData.date_filed}
                readOnly={!userRights.editInfo}
                wsHandler={wsOrgInfo}
              />
            </ProfileStatus>
          </StyledProfileStatuses>
        </StyledProfileHeader>

        <Grid doubling columns={2}>
          <Grid.Column width={9}>{renderProfile(orgData, handlers, userRights)}</Grid.Column>
          <Grid.Column width={7}>
            <ProfileSidebar orgData={orgData} userRights={userRights} />
          </Grid.Column>
        </Grid>
      </Container>
    )
  )
}

export default Profile
