import React, { useState } from 'react'
import { Popup } from 'semantic-ui-react'

export default function Tooltip({ fieldRef, content }) {
  const [open, setOpen] = useState(true)

  return (
    // <div onClick={() => setOpen(false)}>
    <Popup
      context={fieldRef}
      header={content.header}
      content={content.desc}
      position="left center"
      open={open}
      style={{ cursor: 'pointer' }}
    />
    // </div>
  )
}
