import React, { useState, useEffect, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { StripeProvider } from 'react-stripe-elements'

import TagManager from 'react-gtm-module'

import moment from 'moment'
import styled from 'styled-components'
import { Container, Grid, Loader } from 'semantic-ui-react'
import queryString from 'query-string'

import {
  paymentGetOrg,
  paymentSearchOrg,
  paymentCheckCoupon,
  paymentApplyDiscount,
  paymentSetCoupon,
  paymentCheckParamsAffiliate,
} from 'store/actions/payment'

import {
  AFFILIATE_DISCOUNT,
  checkAffiliate,
  checkChamber,
  CHAMBER_DISCOUNT,
} from 'utils/paymentUtils'
import { RootState } from 'store/rootState'
import { formatOrgType } from 'utils/orgType'
import axiosInstance from 'utils/axiosInstance'
import { usePartner, useUrlParams } from 'utils/hooks'
import { EMPTY_URL_PARAM } from 'utils/constants'
import { ReducerType } from 'store/reducers'
import { getPaymentState } from 'store/sagas/selectors/payment'
import { CouponBanner } from 'components/Payment/CouponBanner'
import Checkout from 'components/Payment/Checkout/Checkout'
import PaymentTabs from 'components/Payment/PaymentTabs'

const LinkWithId = styled.p`
  opacity: 0.3;

  font-size: 12px;
  text-align: center;
  color: #7b7b7b;

  &:hover {
    opacity: 1;
  }
`

function Payment(props) {
  const [, isBetterlegal] = usePartner()
  const [stripe, setStripe] = useState(null)
  const [couponTimeDiff, setCouponTimeDiff] = useState(null)
  const [affiliate, setAffiliate] = useState(null)
  const [chamber, setChamber] = useState(null)

  const partnerInfo = useSelector((state: RootState) => state.auth.partnerInfo)
  const { username } = partnerInfo

  const urlParamsString = useSelector<any, any>(state => state.router.location.search)

  const [params] = useUrlParams(urlParamsString, username)

  const [price, setPrice] = useState({ serviceFee: null, stateFee: null })

  useEffect(() => {
    if (window['Stripe']) {
      setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_TOKEN))
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window['Stripe'](process.env.REACT_APP_STRIPE_TOKEN))
      })
    }
  }, [])

  const dispatch = useDispatch()

  const orgId = props.match.params.id
  const paymentData = useSelector<any, any>(state => state.payment.data)
  const stateFee = useSelector<any, any>(state => state.payment.regularStateFee)
  const couponName = useSelector<any, any>(state => state.payment.couponName)

  const [loading, setLoading] = useState(true)

  const fetchPrice = useCallback(async () => {
    const state = params.state || paymentData.state_filed
    const type = params.orgType || paymentData.orgType

    try {
      if (!state || !type) {
        throw new Error('no state or type')
      }
      const response = await axiosInstance.get(`/public/formation?state=${state}&org_type=${type}`)

      setPrice({ serviceFee: response.data?.admin_fee, stateFee: response.data?.filing_fee })

      setLoading(false)
    } catch (error) {}
  }, [params.state, params.orgType, paymentData.state_filed, paymentData.orgType])

  useEffect(() => {
    if (isBetterlegal) {
      fetchPrice()
    } else {
      setPrice({ serviceFee: partnerInfo.fee > 0 ? partnerInfo.fee : stateFee * 0.06, stateFee })
      setLoading(false)
    }
  }, [fetchPrice, params, paymentData, isBetterlegal, partnerInfo.fee, stateFee])

  useEffect(() => {
    if (params.aff && params.aff !== EMPTY_URL_PARAM) {
      checkAffiliate(params.aff).then(affiliateFullName => {
        setAffiliate(affiliateFullName)
        dispatch(paymentApplyDiscount(AFFILIATE_DISCOUNT))
        dispatch(paymentSetCoupon(`aff-${params.aff}`))
      })
    }
  }, [dispatch, params.aff, checkAffiliate])

  useEffect(() => {
    if (params.chamber && params.chamber !== EMPTY_URL_PARAM) {
      checkChamber(params.chamber).then(chamberName => {
        setChamber(chamberName)
        dispatch(paymentApplyDiscount(CHAMBER_DISCOUNT))
        dispatch(paymentSetCoupon(`cbr-${params.chamber}`))
      })
    }
  }, [dispatch, params.chamber, checkChamber])

  useEffect(() => {
    const checkParamsAffiliate = (
      paramsAffiliateCode: ReducerType['payment']['paramsAffiliate']['code']
    ) => dispatch(paymentCheckParamsAffiliate(paramsAffiliateCode))

    const partnerId = params.allstate as string

    if (partnerId && partnerId !== EMPTY_URL_PARAM) {
      checkParamsAffiliate(partnerId)
    }
  }, [dispatch, params.allstate])

  useEffect(() => {
    const urlParams = queryString.parse(urlParamsString)

    const getOrg = id => dispatch(paymentGetOrg(id))
    const searchOrg = urlParams => dispatch(paymentSearchOrg(urlParams))

    if (orgId) {
      getOrg(orgId)
    } else if (urlParams.type && urlParams.name && urlParams.state) {
      searchOrg(urlParamsString)
    }
  }, [urlParamsString, orgId, dispatch])

  useEffect(() => {
    const checkCoupon = coupon => dispatch(paymentCheckCoupon(coupon))

    if (params.coupon && params.coupon !== EMPTY_URL_PARAM) {
      dispatch(paymentSetCoupon(params.coupon))

      if (params.couponTime && params.couponTime !== EMPTY_URL_PARAM) {
        const now = moment()
        const couponDate = moment.unix(+params.couponTime)
        setCouponTimeDiff(moment.duration(couponDate.diff(now)))

        const expired = couponTimeDiff?.asMilliseconds() <= 0

        if (!expired) {
          checkCoupon(params.coupon)
        }
      } else {
        checkCoupon(params.coupon)
      }
    }
  }, [params.coupon, params.couponTime, dispatch, couponTimeDiff])

  useEffect(() => {
    if (isBetterlegal && paymentData.orgType && paymentData.state_filed)
      TagManager.dataLayer({
        dataLayer: {
          event: 'interactionEvent',
          eventCategory: 'Form',
          eventAction: 'Complete',
          eventLabel: `${formatOrgType(paymentData.orgType)} - ${paymentData.state_filed}`,
        },
      })
  }, []) // eslint-disable-line

  const orgIdDB = useSelector<any, any>(state => state.payment.data.id)
  const discount = useSelector<any, any>(state => state.payment.discount)
  const { paramsAffiliate } = useSelector<ReducerType, ReducerType['payment']>(getPaymentState)

  return (
    <StripeProvider stripe={stripe}>
      <Container style={{ paddingTop: discount || affiliate ? 80 : 20 }}>
        <Grid doubling columns={2}>
          <CouponBanner
            couponCode={params.coupon?.toString()}
            couponTimeDiff={couponTimeDiff}
            orgType={formatOrgType(paymentData?.orgType)}
            discount={discount}
            affiliate={affiliate}
            chamber={chamber}
            couponName={couponName}
            paramsAffiliate={paramsAffiliate}
          />
          <Grid.Column width={9}>
            <PaymentTabs orgId={orgId} />
          </Grid.Column>
          <Grid.Column width={7}>
            {loading ? (
              <Loader
                inline="centered"
                active={true}
                size="large"
                style={{ marginTop: 80, marginBottom: 40 }}
              />
            ) : (
              // TODO: take orgId
              <Checkout urlData={params} price={price} orgId={orgId} />
            )}
            {orgIdDB && (
              <LinkWithId>
                <a href={`${window.location.origin}/payment/${orgIdDB}`}>Link to this page</a>
              </LinkWithId>
            )}
          </Grid.Column>
        </Grid>
      </Container>
    </StripeProvider>
  )
}

export default Payment
