import { useState } from 'react'

import ActivityChat from 'components/Profile/Sidebar/ActivityChat'

import { Icon } from 'semantic-ui-react'

import { replaceAtSymbolToAtInEmailBetterlegal as replaceAtSymbolToAt } from 'utils/helpers'
import { UserRights } from 'utils/userRights'
import {
  ActivityListItem,
  EditIconWrapper,
  StyledActivityContent,
  StyledActivityDate,
  StyledActivityDesc,
  StyledActivityHeader,
  StyledActivityItem,
  StyledCompanyName,
} from './ActivityStyles'

interface ActivityOrg {
  id: string
  name: string
  type: string
  status: string
  state_filed: string
  partner: string
}

interface ActivityUser {
  id: string
  email: string
}

interface ActivityFields {
  [key: string]: any
}

export interface Activity {
  id: string
  type: string
  name: string
  activity: string
  activity_type: string
  user: ActivityUser
  org: ActivityOrg
  date_created: string
  time_since: string
  fields: ActivityFields
}

function ActivityIcon({ type }: { type: string }) {
  switch (type.toLowerCase()) {
    case 'voicemail':
      return <Icon name="microphone" color="grey" circular inverted size="small" />
    case 'outgoing':
      return (
        <Icon.Group>
          <Icon name="call" color="grey" />
          <Icon corner name="arrow right" color="grey" size="large" />
        </Icon.Group>
      )
    default:
      return <Icon name="phone volume" color="grey" />
  }
}

interface ActivityProps {
  onClick?: any
  data: Activity
  isActivitiesList?: boolean
  isActive?: boolean
  userRights?: UserRights
  setData?: React.Dispatch<any>
  setModalActive?: React.Dispatch<any>
}

export function Activity({
  data,
  onClick,
  isActive,
  isActivitiesList,
  userRights,
  setData,
  setModalActive,
}: ActivityProps) {
  const [modifiedActivity, setModifiedActivity] = useState(() =>
    data.type === 'feed' ? replaceAtSymbolToAt(data.activity) : data.activity
  )

  return (
    <StyledActivityItem onClick={onClick} isActive={isActive} isActivitiesList={isActivitiesList}>
      <StyledActivityHeader isActivitiesList={isActivitiesList}>
        <div>
          {data.activity_type && <strong>{data.activity_type}</strong>}
          {data.type === 'Twilio' &&
            data.fields.twilio_activity_type !== 'chat' &&
            data.fields.optional_fields?.type && (
              <ActivityIcon type={data.fields.optional_fields.type} />
            )}
        </div>
        <StyledActivityDate>{data.date_created}</StyledActivityDate>
        {userRights?.controlActivities && (
          <EditIconWrapper>
            <Icon
              name="pencil"
              onClick={() => {
                setModalActive(true)
                setData(data)
              }}
            />
          </EditIconWrapper>
        )}
      </StyledActivityHeader>
      <StyledActivityContent type={data.type} isActivitiesList={isActivitiesList}>
        <StyledActivityDesc type={data.type}>{modifiedActivity}</StyledActivityDesc>
        <ul>
          {data.type !== 'Twilio' &&
            data.fields &&
            Object.keys(data.fields)
              .filter(key => typeof data.fields[key] === 'string' || data.fields[key] === null) // ?? need desrciption why we filtered out objects before
              .map((fieldKey, index) => (
                <ActivityListItem key={`${fieldKey}-${index}`}>
                  <strong>{fieldKey}</strong> <span>{data.fields[fieldKey]}</span>
                </ActivityListItem>
              ))}
        </ul>
        {data.type === 'Twilio' && <ActivityChat data={data} />}
      </StyledActivityContent>
      {isActivitiesList && data.org && <StyledCompanyName>{data.org.name}</StyledCompanyName>}
    </StyledActivityItem>
  )
}
