import axios, { CancelToken } from 'axios'
import { useRef, useEffect } from 'react'

export const useCancelToken = () => {
  const axiosSource = useRef(null)
  const newAxiosCancelToken = (): CancelToken => {
    axiosSource.current = axios.CancelToken.source()
    return axiosSource.current.token
  }

  useEffect(
    () => () => {
      if (axiosSource.current) {
        axiosSource.current.cancel()
      }
    },
    []
  )

  return { newAxiosCancelToken, isCancel: axios.isCancel }
}
