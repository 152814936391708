// import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import PaymentOrgInc from 'components/Payment/PaymentOrgInfo/PaymentOrgInc'
import PaymentOrgIncQuick from 'components/Payment/PaymentOrgInfo/PaymentOrgIncQuick'
import PaymentOrgLLC from 'components/Payment/PaymentOrgInfo/PaymentOrgLLC'
import { useOrgWebSocket } from 'utils/hooks'

const emptyData = {
  name: '',
  taxes_manager: '',
  address: {
    id: '',
    address1: '',
    address2: '',
    city: '',
    zipcode: '',
    county: '',
    province: '',
    country: '',
  },
  orgType: '',
  type: '',
  state_filed: '',
  agent: {
    full_name: '',
    email: '',
    address: {
      id: '',
      address1: '',
      address2: '',
      city: '',
      zipcode: '',
      county: '',
      province: '',
      country: '',
    },
  },
  fields: { members_type: '' },
  partner: {
    username: '',
    fee: undefined,
  },
}

const PaymentOrgInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 5px;

  h2 {
    padding-left: 20px;
  }
`

function PaymentOrgInfo(props) {
  // const [orgData, setOrgData] = useState<any>(emptyData)
  // const [loading, setLoading] = useState(true)
  const { orgId } = props

  if (!orgId) return null

  const [orgData, handlers] = useOrgWebSocket(orgId, 'org_public')

  // const urlParamsString = useSelector<any, any>(state => state.router.location.search)
  const isQuick = useSelector<any, any>(state =>
    state.router.location.pathname.includes('/payment-q/')
  )
  // const fetchOrgData = useCallback(async () => {
  //   if (urlParamsString && !props.orgId) {
  //     try {
  //       let response
  //       const urlParams = queryString.parse(urlParamsString)
  //       response = await axiosInstance.get('/public/business/search', {
  //         params: {
  //           name: getOrgNameWithAmpersand(urlParamsString),
  //           state_filed: urlParams?.state?.toString().replace('_', ' '),
  //         },
  //       })

  //       setOrgData(mapOrgData(response.data))
  //       setLoading(false)
  //     } catch (e) {
  //       console.log(e.message)
  //       setLoading(false)
  //     }
  //   }
  // }, [urlParamsString, props.orgId])

  // useEffect(() => {
  //   fetchOrgData()
  // }, [fetchOrgData])

  let OrgComponent

  switch (orgData?.type?.toLowerCase()) {
    case 'inc':
      OrgComponent = isQuick ? PaymentOrgIncQuick : PaymentOrgInc
      break
    case 'llc':
    default:
      OrgComponent = PaymentOrgLLC
      break
  }

  // if (!orgData) return <PaymentOrgInfoPlaceholder />

  // return loading ? (
  // ) : (
  //   <PaymentOrgInfoWrapper>
  //     <OrgComponent data={orgData} handlers={handlers} />
  //   </PaymentOrgInfoWrapper>
  // )
  return (
    <PaymentOrgInfoWrapper>
      <OrgComponent data={orgData} handlers={handlers} confirmTooltips={props.confirmTooltips} />
    </PaymentOrgInfoWrapper>
  )
}

export default PaymentOrgInfo
