import React, { useState, useEffect, useMemo } from 'react'
import { Grid, Container, Button, Icon, Select } from 'semantic-ui-react'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'
import TagManager from 'react-gtm-module'
import { Link } from 'react-router-dom'

import { states } from 'utils/states'
import { usePartner } from 'utils/hooks'
import { DEFAULT_INC_SHARE_VALUE, DEFAULT_INC_SHARES, DEFAULT_INC_TYPE } from 'utils/constants'
import themes from 'utils/themes'
import axiosInstance from 'utils/axiosInstance'
import { CallBanner } from 'components/UI/CallBanner'

const StyledContainer = styled(Container)`
  padding-top: 30px;

  color: ${p => p.theme.createLLC.regularText};
  font-family: ${p => p.theme.createLLC.font};
`

const StyledHeading = styled.h1`
  width: 100%;
  margin: 0;

  font-size: 3em;
  font-weight: 600;
  text-align: center;
  font-family: ${p => p.theme.createLLC.font};

  @media screen and (max-width: 480px) {
    font-size: 2.5em;
    font-weight: 900;
  }
`

const StyledCaption = styled.h2`
  width: 100%;
  margin: 10px 0;

  text-align: center;
  color: ${p => p.theme.createLLC.lightText};

  @media screen and (max-width: 480px) {
    padding: 0 10px;
  }
`

const MemberType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 80px;

  h3 {
    font-size: 30px;
    text-align: center;
    font-family: ${p => p.theme.createLLC.font};

    @media screen and (max-width: 480px) {
      font-size: 24px;
    }
  }

  p {
    min-height: 100px;
    max-width: 80%;

    font-size: 16px;
    text-align: justify;
    color: ${p => p.theme.createLLC.lightText};

    @media screen and (max-width: 480px) {
      max-width: 100%;
    }
  }
`

const StyledButton = styled(Button)`
  &.ui.button {
    margin: 10px 0 0;

    background-color: ${p => p.theme.createLLC.buttonColor};
    color: #fff;
  }
`

const Wrapper = styled.div`
  background-color: ${p => p.theme.createLLC.background};
  height: 100%;
`

function CreateInc(props) {
  const [bState, setBState] = useState({ key: '', value: '', text: '' })
  const [partner, isBetterlegal] = usePartner()

  const partnerName = useSelector<any, any>(state => state.auth.partnerInfo.name)

  useEffect(() => {
    const urlParams = queryString.parse(props.location.search)

    if (urlParams.state) {
      const stateName = urlParams.state.toString() || ''
      setBState({ key: stateName, value: stateName, text: stateName.replace('_', ' ') })
    }
  }, [props.location.search])

  const handleDropdownChange = (e, data) =>
    setBState({ key: data.value, value: data.value, text: data.value.replace('_', ' ') })

  const handleButtonClick = type => {
    if (isBetterlegal) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'interactionEvent',
          eventCategory: 'LLC Selection',
          eventAction: type,
          eventLabel: bState.text,
        },
      })
    }
  }

  const searchQuery = useMemo(() => {
    const parsed = queryString.parse(props.location.search)

    if (bState.value) {
      parsed.state = bState.value
    }

    // BL4 Parameters; passed in for BL3 as well since it doesn't have
    // any negative effects
    parsed.new = 'yes';
    parsed.type = 'inc';

    return `?${queryString.stringify(parsed)}`
  }, [props.location.search, bState])

  return (
    <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
      <CallBanner />
      <Wrapper>
        <StyledContainer>
          <Grid>
            <Grid.Row>
              <StyledHeading>Which Type of Corporation Do You Need?</StyledHeading>
              <StyledCaption>
                {partnerName} offers two options to form your Corporation. Find out which is right for your
                business.
              </StyledCaption>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <MemberType>
                  <Icon name="suitcase" size="massive" />
                  <h3>SINGLE OWNER CORPORATION</h3>
                  <p>
                    Select this option if there will initially be only one member of your
                    corporation's board of directors (this usually applies to corporations that will
                    initially have only one owner/shareholder). This is perfect for new business
                    owners who need a quick and easy company structure.
                  </p>

                  <Select
                    placeholder="Select State"
                    options={states}
                    value={bState.value}
                    onChange={(e, data) => handleDropdownChange(e, data)}
                  />
                  {bState.value && (
                    <a href={`https://app2.betterlegal.com/formation${searchQuery}`}>
                      <StyledButton
                        size="big"
                        children="Single Owner Corporation"
                        onClick={() => handleButtonClick('Single')}
                      />
                    </a>
                  )}
                </MemberType>
              </Grid.Column>
              <Grid.Column>
                <MemberType>
                  <Icon name="building" size="massive" />
                  <h3>MULTI OWNER CORPORATION</h3>
                  <p>
                    Select this option for a more robust and comprehensive company structure that is
                    appropriate for a corporation with multiple members of the board of directors
                    and multiple owners/shareholders.
                  </p>

                  <Select
                    placeholder="Select State"
                    options={states}
                    value={bState.value}
                    onChange={(e, data) => handleDropdownChange(e, data)}
                  />
                  {bState.value && (
                    <a href={`https://app2.betterlegal.com/formation${searchQuery}`}>
                      <StyledButton
                        size="big"
                        children="Multi Owner Corporation"
                        onClick={() => handleButtonClick('Multi')}
                      />
                    </a>
                  )}
                </MemberType>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </StyledContainer>
      </Wrapper>
    </ThemeProvider>
  )
}

export default CreateInc
