import _ from 'lodash'

import axiosInstance from 'utils/axiosInstance'

export const CHAMBER_DISCOUNT = 100
export const AFFILIATE_DISCOUNT = 100
export const HARDCODED_FEES = {
  'New York': 125,
  California: 100,
}

export function calculateServiceFee(partnerFee, stateFee) {
  return partnerFee || _.floor(stateFee * 0.06, 2)
}

export function mapOrgData(data) {
  if (data.type.toLowerCase() === 'llc') {
    return {
      id: data.id,
      name: data.name,
      orgType: data.type,
      state_filed: data.state_filed,
      address: { ...data.address },
      agent: { ...data.agent, address: { ...data.agent.address } },
      partner: { ...data.partner },
      fields: { ...data.fields },
      business_purpose: data.fields.business_purpose,

      // non-required fields below:
      managers: [...data.managers],
      members: [...data.members],

      type: data.fields.members_type,
      taxes_manager: data.fields.taxes_manager,
    }
  } else if (data.type.toLowerCase() === 'inc') {
    return {
      id: data.id,
      name: data.name,
      orgType: data.type,
      state_filed: data.state_filed,
      address: { ...data.address },
      agent: { ...data.agent, address: { ...data.agent?.address } },
      partner: { ...data.partner },
      fields: { ...data.fields },
      business_purpose: data.fields.business_purpose,

      // non-required fields below:
      shareholders: [...data.shareholders],
      directors: [...data.directors],

      type: data.fields.corporation_type,
      shares: data.fields.shares,
      par_value: data.fields.par_value,
    }
  }
}

export const getOrgNameWithAmpersand = query => {
  const regex = /name=(.*?)&state=/

  const queryMatch = query?.match(regex)

  return queryMatch && decodeURIComponent(queryMatch[1])
}

export function mapAffiliateFields(org) {
  if (org.type === 'LLC') {
    return {
      ...org,
      members: [
        ...org.members.map(member => {
          if (_.isEmpty(member.fields)) {
            return {
              ...member,
              fields: {
                stake: 0,
                invested: 0,
              },
            }
          } else {
            return member
          }
        }),
      ],
    }
  } else if (org.type === 'Inc') {
    return {
      ...org,
      shareholders: [
        ...org.shareholders.map(shareholder => {
          if (_.isEmpty(shareholder.fields)) {
            return {
              ...shareholder,
              fields: {
                shares: 0,
              },
            }
          } else {
            return shareholder
          }
        }),
      ],
    }
  } else {
    return org
  }
}

export const checkAffiliate = async aff => {
  const response = await axiosInstance.get(`/public/affiliate?uuid=${aff}`)
  if (response.data.success) {
    return response.data.full_name
  }
}

export const checkChamber = async chamber => {
  const response = await axiosInstance.get(`/data-house/coupons/cbr/${chamber}`)

  if (response.data) {
    return response.data.name
  }
}

const toStr = (value: string | number | boolean = '') => String(value)
export const transformSuffix = (str: string | number | boolean = '') => {
  const find = [/L\.L\.C\./, /LLC/, /Llc/, /llc/, / inc\./, / inc /, / Inc\./, / Inc /]

  return find
    .reduce((acc, substitute) => acc.replace(new RegExp(substitute, 'g'), ''), ` ${toStr(str)} `)
    .trim()
}

export const ACCEPTABLE_KEYWORDS = [
  'apply',
  'become',
  'create',
  'establish',
  'file',
  'form',
  'get',
  'make',
  'open',
  'register',
  'set up',
  'start',
]
