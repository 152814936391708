
import styled, { ThemeProvider } from 'styled-components'
import _ from 'lodash'

import { usePartner } from 'utils/hooks'
import themes from 'utils/themes'

const StyledFilters = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    justify-content: center;
    flex-wrap: wrap;

    max-width: 100%;
    width: 600px;
    margin: 0 auto;
  }
`

const StyledFilter = styled.span<{ active: boolean }>`
  display: flex;
  align-items: center;

  margin-right: 15px;

  color: ${p => p.theme.accent};
  font-weight: ${p => (p.active ? 'bold' : 'normal')};
  text-transform: capitalize;

  transition: color 0.15s;
  cursor: ${p => (p.active ? 'default' : 'pointer')};

  &:hover {
    color: ${p => p.theme.accent};
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
`

const StyledLabel = styled.span<{ active: boolean }>`
  display: inline-block;

  padding: 0px 5px;
  margin-left: 3px;

  background-color: ${p => (p.active ? p.theme.accent : p.theme.lightDimmed)};
  border-radius: 3px;

  font-size: 12px;
  font-weight: bold;
  color: ${p => (p.active ? '#fff' : p.theme.accent)};
`

const OrgListFilter = props => {
  const { label, active, count, setFilter } = props

  return (
    <StyledFilter active={active} onClick={() => setFilter(label)}>
      {label || 'All'}
      <StyledLabel active={active}>{count}</StyledLabel>
    </StyledFilter>
  )
}

function OrgListFilters({ statuses, setFilter, activeFilter }) {
  const [, isBetterlegal] = usePartner()

  const allCount = _.reduce(statuses, (result, v) => (result += v), 0)

  return (
    <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
      <StyledFilters>
        <OrgListFilter
          label={''}
          count={allCount}
          active={activeFilter === ''}
          setFilter={setFilter}
        />
        {Object.keys(statuses).map(status => (
          <OrgListFilter
            key={status}
            label={status}
            count={statuses[status]}
            active={status === activeFilter}
            setFilter={setFilter}
          />
        ))}
      </StyledFilters>
    </ThemeProvider>
  )
}

export default OrgListFilters
