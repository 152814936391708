import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

import axiosInstance from 'utils/axiosInstance'
import { fetchPartnerInfoSuccess } from '../slices/authSlice'

export function* settingsSubmitSaga(action) {
  yield put(showLoading())

  try {
    const response = yield axiosInstance.put(`/partner/${action.payload.id}`, action.payload)
    yield put(fetchPartnerInfoSuccess(response.data))
    yield put(push('/'))
    yield toast.success('Settings saved successfully')
    yield put(hideLoading())
  } catch (err) {
    const errorsObject = err.response.data
    let errors = ''

    Object.keys(errorsObject).map(key => {
      if (key === 'address') {
        Object.keys(errorsObject.address).map(
          key => (errors += `${key} : ${errorsObject.address[key]} `)
        )
      } else {
        errors += `${key} : ${errorsObject[key]} `
      }
      return undefined
    })

    yield toast.error(errors)
    yield put(hideLoading())
  }
}
