
import _ from 'lodash'

import { ProfileInfoHeader } from '../ProfileInfoHeader'
import { EditableAgentSimplified } from 'components/UI/Editable/EditableAgent'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import { EditablePrincipalAddressSimplified } from 'components/UI/Editable/EditablePrincipalAddress'
import { EditablePersonsListSimplified } from 'components/UI/Editable/EditablePersonsList'
import { EditableDropdown } from 'components/UI/Editable/EditableDropdown'
import { businessPurposeSchema } from 'components/UI/Editable/editableSchemas'

import { states } from 'utils/states'
import { WS_TIMEOUT } from 'utils/constants'
import { isMatchRaAddress } from 'utils/simplifiedAddress'

import { OrgInfoPanel, StyledProfileOverviewContainer, OrgInfoRow } from '../../UI/orgHelpers'

function ProfileInc({ org, userRights, handlers, updateRAaddress }) {
  const { wsOrgInfo, wsAffiliate, wsRemoveAffiliate, wsAddAffiliate } = handlers

  return (
    <>
      <OrgInfoPanel>
        <ProfileInfoHeader caption="Info" />
        <StyledProfileOverviewContainer>
          <OrgInfoRow>
            <label>State Filed</label>
            <EditableDropdown
              fieldName="state_filed"
              options={states}
              data={{ state_filed: org.state_filed }}
              readOnly={!userRights.editInfo}
              wsHandler={wsOrgInfo}
              placeholder="Select State"
            />
          </OrgInfoRow>

          {/* <OrgInfoRow>
            <label>Type</label>
            <CustomInput name="type" value={org.fields.corporation_type} disabled />
          </OrgInfoRow> */}

          <OrgInfoRow>
            <label>Business Purpose</label>
            <EditableSimpleField
              fieldName="fields.business_purpose"
              data={{ fields: { business_purpose: org.fields.business_purpose } }}
              readOnly={!userRights.editInfo}
              isTextarea
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              validationSchema={businessPurposeSchema}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Principal Office Address</label>
            <EditablePrincipalAddressSimplified
              data={{ address: org.address }}
              readOnly={!userRights.editInfo}
              wsHandler={wsOrgInfo}
              useRaAddress={isMatchRaAddress(org.address, org.agent.address)}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Shares/Par Value</label>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              <EditableSimpleField
                fieldName="fields.shares"
                data={{ fields: { shares: org.fields.shares } }}
                readOnly={!userRights.editInfo}
                wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              />
              <EditableSimpleField
                fieldName="fields.par_value"
                data={{ fields: { par_value: org.fields.par_value } }}
                readOnly={!userRights.editInfo}
                wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              />
            </div>
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Registered Agent</label>
            <EditableAgentSimplified
              data={org.agent}
              readOnly={!userRights.editInfo}
              wsHandler={updateRAaddress}
            />
          </OrgInfoRow>
        </StyledProfileOverviewContainer>
      </OrgInfoPanel>

      <OrgInfoPanel>
        <EditablePersonsListSimplified
          type="Director"
          data={org.directors}
          userRights={userRights}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
          raAddress={org.agent?.address}
        />
      </OrgInfoPanel>

      <OrgInfoPanel>
        <EditablePersonsListSimplified
          type="Shareholder"
          data={org.shareholders}
          managers={org.directors}
          userRights={userRights}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
          raAddress={org.agent?.address}
        />
      </OrgInfoPanel>
    </>
  )
}

export default ProfileInc
