import _ from 'lodash'

export const isDefaultAddress = address =>
  _.isEmpty(address)
  || Object.keys(address)
      .filter(key =>
        key !== 'id' &&
        key !== 'country' &&
        key !== 'zipcode'
      )
      .every(key => !address[key])

export const serializeAddress = address => {
  const isDefault = isDefaultAddress(address)
  
  // if address is null / empty object / object with empty values -> return null
  if (isDefault) {
    return null
  }

  return address
}

const keysToCompare = [
  'address1',
  'address2',
  'city',
  'country',
  'county',
  'province',
  'zipcode'
]
export const isMatchRaAddress = (address, raAddress) => {
  // if address is empty/default
  // check if RA is empty/default as well
  if (isDefaultAddress(address)) {
    return isDefaultAddress(raAddress)
  }

  if (_.isEmpty(raAddress)) {
    return keysToCompare.every(key => !address[key])
  }

  return keysToCompare.every(key => address[key] === raAddress[key])
}
  
  