import React, { useState, useRef } from 'react'
import { Select, Button, Input, Form } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { states } from 'utils/states'
import axiosInstance from 'utils/axiosInstance'

import ActivityOrgSearchResults from './ActivityOrgSearchResults'

const StyledWrapper = styled.div`
  padding: 10px;

  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 5px;
`

export function ActivityOrgSearch({ activityId, updateActivity }) {
  const [input, setInput] = useState('')
  const [showNewOrg, setShowNewOrg] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [newOrgData, setNewOrgData] = useState({
    name: '',
    companyName: '',
    email: '',
  })
  const [bState, setBState] = useState({ key: '', value: '', text: '' })
  const [bType, setBType] = useState({ value: 'llc', key: 'llc', text: 'LLC' })

  const timeout = useRef(null)

  function onChange(e) {
    const nextValue = e.target.value
    setInput(nextValue)

    if (nextValue) {
      setIsSearching(true)

      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      // throttle requests to 300ms
      timeout.current = setTimeout(() => {
        searchOrg(nextValue)
      }, 300)
    } else {
      setIsSearching(false)
      timeout.current = null
    }
  }

  function searchOrg(searchTerm) {
    axiosInstance
      .get('/business/search/partial', {
        params: {
          name: searchTerm,
          case_sensitive: false,
        },
      })
      .then(res => {
        const { data } = res

        setSearchResults(data)
        setIsSearching(false)
      })
      .catch(() => {
        setSearchResults([])
        setIsSearching(false)
      })
  }

  function onAssignToOrg(org) {
    if (isProcessing) {
      return false
    }
    setIsProcessing(true)
    axiosInstance
      .put('/activity/' + activityId, {
        org_id: org.id,
      })
      .then(res => {
        // update activity in list
        setIsProcessing(false)
        updateActivity(res.data)
      })
      .catch(() => setIsProcessing(false))
  }

  function createNewCompany() {
    setIsProcessing(true)

    const data = {
      org_type: bType.value, // required
      name: newOrgData.companyName,
      email: newOrgData.email,
      person: newOrgData.name, // required
      state_filed: bState.value, // required
    }

    if (!bType.value) {
      return toast.error('Select org type')
    }

    if (!newOrgData.name) {
      return toast.error('Fill company name')
    }

    if (!bState.value) {
      return toast.error('Fill company name')
    }

    axiosInstance
      .post(`/business/organization/${bType.value}`, data)
      .then(res => {
        setIsProcessing(false)
        onAssignToOrg(res.data)
      })
      .catch(() => setIsProcessing(false))
  }

  function handleStateChange(e, data) {
    setBState({ key: data.value, value: data.value, text: data.value.replace('_', ' ') })
  }

  function handleTypeChange(e, data) {
    setBType({ key: data.value, value: data.value, text: data.value.replace('_', ' ') })
  }

  return (
    <StyledWrapper style={{ overflow: 'unset' }}>
      <div>
        <SearchWrapper>
          <Input
            type="text"
            icon="search"
            placeholder="Company name"
            value={input}
            onChange={onChange}
            fluid
          />
          <Button icon="plus" color="teal" onClick={() => setShowNewOrg(!showNewOrg)} />
        </SearchWrapper>
        {input && !isSearching && (
          <ActivityOrgSearchResults searchResults={searchResults} assignOrg={onAssignToOrg} />
        )}
      </div>

      {showNewOrg && !searchResults.length && (
        <Form style={{ padding: '20px 0 0' }}>
          <Form.Group widths="equal">
            <Form.Field>
              <Form.Input
                label="Name"
                fluid
                type="text"
                value={newOrgData.name}
                onChange={e => setNewOrgData({ ...newOrgData, name: e.target.value })}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label="Company Name"
                fluid
                type="text"
                value={newOrgData.companyName}
                onChange={e => setNewOrgData({ ...newOrgData, companyName: e.target.value })}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ display: 'grid', gridTemplateColumns: '5fr 3fr 2fr' }}>
            <Form.Field>
              <Form.Input
                label="Email"
                fluid
                type="email"
                value={newOrgData.email}
                onChange={e => setNewOrgData({ ...newOrgData, email: e.target.value })}
              />
            </Form.Field>
            <Form.Field>
              <label>State</label>
              <Select
                options={states}
                value={bState.value}
                onChange={(e, data) => handleStateChange(e, data)}
                style={{ minWidth: 'auto' }}
              />
            </Form.Field>
            <Form.Field>
              <label>Type</label>
              <Select
                options={[
                  { key: 'llc', value: 'llc', text: 'LLC' },
                  { key: 'inc', value: 'inc', text: 'Corporation' },
                ]}
                value={bType.value}
                onChange={(e, data) => handleTypeChange(e, data)}
                style={{ minWidth: 'auto' }}
              />
            </Form.Field>
          </Form.Group>
          <Button onClick={createNewCompany} disabled={isProcessing} color="teal">
            Create
          </Button>
        </Form>
      )}
    </StyledWrapper>
  )
}
