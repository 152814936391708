import { toast } from 'react-toastify'
import TagManager from 'react-gtm-module'
import _ from 'lodash'
import queryString from 'query-string'
import axios from 'axios'
import { push } from 'connected-react-router'
import { put, select, delay } from 'redux-saga/effects'

import axiosInstance from 'utils/axiosInstance'
import { mapOrgData, getOrgNameWithAmpersand } from 'utils/paymentUtils'
import { stateCodes } from 'utils/stateCodes'
import { formatOrgType } from 'utils/orgType'
import { ALLSTATE, EMPTY_URL_PARAM } from 'utils/constants'
import cookies from 'utils/cookies'
import { PaymentUrlParamsType } from 'components/Payment/paymentUrlParams'
import { ReducerType } from 'store/reducers'
import * as paymentActions from 'store/actions/payment'
import { getEmail, getPaymentState, getPartnerInfo } from 'store/sagas/selectors/payment'
import { Coupon } from 'store/reducers/payment'

const missing = 'UNDEFINED'

export function* checkParamsAffiliateSaga(
  action: ReturnType<typeof paymentActions.paymentCheckParamsAffiliate>
) {
  const { paramsAffiliateCode } = action

  try {
    const response: { data: Coupon } = yield axiosInstance.get(
      `/payment/coupon/${paramsAffiliateCode}`
    )

    yield put(paymentActions.paymentApplyDiscount(response.data.discount))
    if (response.data.id) {
      // TODO: set paramsAffiliate
      yield put(
        paymentActions.paymentSetParamsAffiliate({
          code: response.data.code,
          name: response.data.name,
        })
      )
    }
  } catch (err) {
    yield console.error(err)
  }
}

export function* paymentSearchStateInfoSaga(action) {
  try {
    const response = yield axiosInstance.get(`/payment/state/${action.state}`)

    yield put(paymentActions.paymentGetStateInfo(response.data))
  } catch (err) {
    yield console.error(err)
  }
}

export function* paymentGetOrgSaga(action) {
  yield put(paymentActions.paymentGetOrgStart())

  try {
    const response = yield axiosInstance.get(`/business/organization/${action.id}`)
    yield put(paymentActions.paymentSearchStateInfo(response.data.state_filed))
    yield put(paymentActions.paymentGetOrgSuccess(mapOrgData(response.data)))
  } catch (err) {
    yield put(paymentActions.paymentGetOrgFail())
    if (err.response.status === 404) {
      // yield put(push('/notfound'))
      yield toast.error('No such org ID')
    }
  }
}

export function* paymentSearchOrgSaga(action) {
  const { urlParams } = action

  const urlParamsParsed = queryString.parse(urlParams)

  // const name = urlParamsParsed.name.toString()
  const name = getOrgNameWithAmpersand(urlParams)
  const state = urlParamsParsed.state.toString()

  const counts = {
    managers: +urlParamsParsed.managers || 0,
    members: +urlParamsParsed.members || 0,
    directors: +urlParamsParsed.directors || 0,
    shareholders: +urlParamsParsed.shareholders || 0,
  }

  yield put(paymentActions.paymentSearchOrgStart())

  yield put(paymentActions.paymentSearchStateInfo(state.replace('_', ' ')))

  const MAX_ATTEMPTS = 5
  const DELAY = 5000
  let attempts = 0
  let success = false

  while (!success && attempts < MAX_ATTEMPTS) {
    try {
      const response = yield axiosInstance.get('/public/business/search', {
        params: { name: name, state_filed: state.replace('_', ' ') },
      })

      const data = response.data
      const type = data?.type?.toLowerCase()

      let userCondition

      if (data && type === 'llc') {
        userCondition =
          data.managers.length === counts.managers && data.members.length === counts.members
      } else if (data && type === 'inc') {
        userCondition =
          data.directors.length === counts.directors &&
          data.shareholders.length === counts.shareholders
      }

      if (data && userCondition) {
        success = true

        yield put(push(`/payment/${data.id + urlParams}`))
      } else {
        throw new Error("There is no data in response, or the count of users doesn't match")
      }
    } catch (err) {
      console.log('[ Error: ', err.message, ' ]')
      yield toast.info(`Compiling ${name} [${(attempts / MAX_ATTEMPTS) * 100}%]`, {
        position: 'top-center',
        pauseOnHover: false,
      })
      attempts++
      yield delay(DELAY)
    }
  }

  if (attempts === MAX_ATTEMPTS) {
    yield put(paymentActions.paymentSearchOrgFail())
    yield put(push(`/payment-s${urlParams}`))
  }
}

export function* paymentCheckCouponSaga(action) {
  if (action.coupon === EMPTY_URL_PARAM) return null

  try {
    const response = yield axiosInstance.get(`/payment/coupon/${action.coupon}`)

    yield put(paymentActions.paymentApplyDiscount(response.data.discount))
    if (response.data.name) {
      yield put(paymentActions.addCouponName(response.data.name))
    }

    // PUT COUPON NAME TO STORE
  } catch (err) {
    yield console.error(err)
  }
}

interface PaymentProcessPaymentSagaActionTypes {
  amount: number
  name: string
  orgId: string
  token: {
    card: {
      address_zip: string
      address_zip_check: string
      brand: string
      country: string
      cvc_check: string
      exp_month: number
      exp_year: number
      funding: string
      id: string
      last4: string
      name: string
      object: string
    }
    client_ip: string
    created: number
    id: string
    livemode: boolean
    object: string
    type: string
    used: boolean
  }
  type: string
  urlData: PaymentUrlParamsType
}

export function* paymentProcessPaymentSaga(action: PaymentProcessPaymentSagaActionTypes) {
  // TODO: change orgId || paymentState.data.id to only "paymentState.data.id" and set "paymentState.data.id" in the PaymentSimplified
  yield put(paymentActions.paymentProcessPaymentStart())

  const paymentState: ReducerType['payment'] = yield select(getPaymentState)
  const email = yield select(getEmail)
  const partnerInfo: ReducerType['auth']['partnerInfo'] = yield select(getPartnerInfo)

  const { urlData, orgId } = action
  const { mode, coupon, paramsAffiliate } = paymentState
  const marketingCookies = {
    marketing_click_id: cookies.get('betterlegalClickId'),
    marketing_sas: cookies.get('isSAS'),
  }

  const amount = _.floor(action.amount * 100)

  let paymentData

  if (mode === 'simple') {
    paymentData = {
      email: urlData.email || missing,
      token: action.token,
      amount: amount,
      metadata: {
        ...marketingCookies,
        name: action.name || missing,
        org_name: urlData.name || missing,
        org_id: orgId || missing,
        org_type: urlData.orgType || missing,
        province: urlData.state || missing,
        partner: (paramsAffiliate.code && ALLSTATE) || urlData.partner || missing,
        ...((coupon || paramsAffiliate.code) && { coupon: paramsAffiliate.code || coupon }),
      },
      subscription: paymentState.registeredAgentAddon,
      plan: paymentState.registeredAgentPlan,
    }
  } else {
    paymentData = {
      email: email || missing,
      token: action.token,
      amount: amount,
      metadata: {
        ...marketingCookies,
        name: action.name || missing,
        org_name: paymentState.data.name || missing,
        org_id: orgId || paymentState.data.id || missing,
        org_type: paymentState.data.orgType || missing,
        type: paymentState.data.fields?.members_type || missing,
        province: paymentState.data.state_filed || missing,
        partner: (paramsAffiliate.code && ALLSTATE) || urlData.partner || missing,
        ...((coupon || paramsAffiliate.code) && { coupon: paramsAffiliate.code || coupon }),
      },
      subscription: paymentState.registeredAgentAddon,
      plan: paymentState.registeredAgentPlan,
    }
  }

  try {
    const response = yield axiosInstance.post('/payment/process', paymentData)

    const redirectURL =
      mode === 'simple'
        ? `/afterpayment?type=${urlData.orgType}&name=${
            paymentState.data.name || urlData.name || missing
          }&state=${urlData.state}&members=${urlData.members}&w2=${urlData.w2}&W2=${
            urlData.w2
          }&cid=${response.data.customer_id}`
        : `/afterpayment/${orgId || paymentState.data.id}?cid=${response.data.customer_id}`

    if (partnerInfo.username === 'betterlegal') {
      const dataLayerPayload = {
        dataLayer: {
          event: 'purchase',
          ecommerce: {
            currencyCode: 'USD',
            purchase: {
              actionField: {
                id: response.data.transaction_id,
                affiliation: 'web',
                state: paymentState.data.state_filed || urlData.state || missing,
                country: 'United States',
                revenue: _.floor(action.amount),
                coupon: urlData?.coupon || '',
              },
              products: [
                {
                  name: 'LLC - ' + (paymentState.data.state_filed || urlData.state || missing),
                  sku:
                    'LLC-' +
                    stateCodes[
                      (paymentState.data.state_filed || (urlData.state as string)).replace('_', ' ')
                    ],
                  price: +parseFloat(partnerInfo.fees.filing).toFixed(2),
                  shipping: 0,
                  tax: 0,
                  brand: 'BetterLegal',
                  category:
                    formatOrgType(paymentState.data.orgType) ||
                    formatOrgType(urlData.orgType as string) ||
                    missing,
                  variant: paymentState.data.state_filed || urlData.state || missing,
                  quantity: 1,
                },
              ],
            },
          },
        },
      }

      TagManager.dataLayer(dataLayerPayload)
    }

    // yield put(paymentActions.paymentProcessPaymentSuccess())

    // Zapier hook with special request
    if (!paymentState.specialRequest) {
      yield put(push(redirectURL))
      return
    }

    // Zapier find org id and send data to backend
    if (!orgId && !paymentState.data.id) {
      yield axios({
        method: 'post',
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        url: 'https://hooks.zapier.com/hooks/catch/962269/ockk5ig/',
        data: {
          orgType: paymentState.data.orgType || urlData.orgType || missing,
          orgName: paymentState.data.name || urlData.name || missing,
          state: paymentState.data.state_filed || urlData.state || missing,
          partnerUsername: partnerInfo.username || missing,
          partnerEmail: partnerInfo.email || missing,
          specialRequest: paymentState.specialRequest || missing,
        },
      })
      yield put(push(redirectURL))
      return
    }

    yield axiosInstance.put(`/business/organization/LLC/${orgId || paymentState.data.id}`, {
      note: paymentState.specialRequest,
    })
    yield put(push(redirectURL))
    return
  } catch (err) {
    yield put(paymentActions.paymentProcessPaymentFail(err))
    yield toast.error(err.response?.data?.details[0]?.title)

    // Zapier hook with in case of payment error
    yield axios({
      method: 'post',
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      url: 'https://hooks.zapier.com/hooks/catch/962269/oygpvat/',
      data: {
        stripe_name: action.name,
        email: email,
        org_type: paymentState.data.orgType || urlData.orgType || missing,
        org_name: paymentState.data.name || urlData.name || missing,
        manager1: paymentState.data.managers[0] || missing,
        state: paymentState.data.state_filed || urlData.state || missing,
        managers_count: paymentState.data.managers.length || urlData.managers || missing,
        members_count: paymentState.data.members.length || urlData.members || missing,
        error_object: err,
      },
    })
  }
}
