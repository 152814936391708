
import { Formik, Field } from 'formik'

import { SimpleDropdown } from './SimpleDropdown'

interface EditableSimpleFieldProps {
  fieldName: string
  data: any
  readOnly?: boolean
  isTextarea?: boolean
  wsHandler?: any
  validationSchema?: any
  options: any
  placeholder: string
  disabled?: boolean
}

export const EditableDropdown: React.FC<EditableSimpleFieldProps> = ({
  fieldName,
  data,
  readOnly,
  isTextarea,
  wsHandler,
  validationSchema,
  options,
  placeholder,
  disabled,
  ...props
}) => {
  return (
    <Formik
      initialValues={data}
      onSubmit={wsHandler}
      enableReinitialize={true}
      validationSchema={validationSchema}
      {...props}
    >
      {/* https://github.com/jaredpalmer/formik/pull/2384 */}
      {({ handleSubmit, setFieldValue }: any) => (
        <Field name={fieldName}>
          {({ field }) => (
            <SimpleDropdown
              placeholder={placeholder}
              options={options}
              fluid
              search
              selection
              disabled={disabled || false}
              value={field.value}
              readOnly={readOnly}
              wsHandler={option => {
                setFieldValue(fieldName, option.value).then(handleSubmit)
              }}
            />
          )}
        </Field>
      )}
    </Formik>
  )
}
