import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Icon, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import { ReducerType } from 'store/reducers'
import { getPaymentState } from 'store/sagas/selectors/payment'
import { ReferralDropdown } from 'components/UI/Dropdown/ReferralDropdown'
import { AllstateAndBetterlegalHeaderLogo as AllstateHeaderLogo } from 'components/Affiliates/Allstate'
import { usePartner } from 'utils/hooks'
import * as vars from 'utils/styledVariables'

import Logo from 'img/logo.svg'

const StyledHeader = styled.header`
  display: flex;
  align-items: center;

  height: 60px;
  padding: 0 50px;

  margin-bottom: 20px;

  position: relative;

  background-color: #fff;
  box-shadow: ${vars.shadow};

  z-index: 5;

  @media screen and (max-width: 1200px) {
    padding: 0 15px;
  }

  @media screen and (max-width: 768px) {
    .ui.menu {
      min-height: 32px;
    }

    .ui.simple.dropdown {
      padding: 0 10px !important;
    }
  }

  @media screen and (max-width: 400px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    gap: 5px;

    height: auto;
    padding: 10px;
  }
`

const StyledLogo = styled.div`
  max-width: 320px;
  width: 100%;
  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: 800;
  color: ${vars.brownDark};
  text-transform: uppercase;

  & > a {
    color: inherit;
  }

  @media screen and (max-width: 768px) {
    img {
      max-width: 120px;
    }
  }
`

const StyledUser = styled.div`
  font-size: 14px;
  color: ${vars.brown};

  @media screen and (max-width: 400px) {
    grid-column: 1/4;
  }
`

const Header = ({ username, partner, onLogout, isLoggedIn, extendedMenu }) => {
  const [, isBetterlegal] = usePartner()

  const { paramsAffiliate } = useSelector<ReducerType, ReducerType['payment']>(getPaymentState)
  // const partnerInfo = useSelector<ReducerType, ReducerType['auth']['partnerInfo']>(getPartnerInfo)

  const helpLink = `https://poseidonimaging.typeform.com/to/U3dRJL?name=${partner.name}&username=${partner.username}&email=${partner.email}&fee=${partner.fee}`

  return (
    <StyledHeader>
      <StyledLogo>
        {paramsAffiliate.code ? (
          <AllstateHeaderLogo />
        ) : (
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            {isBetterlegal ? <img src={Logo} alt="BetterLegal" /> : partner.name}
          </Link>
        )}
      </StyledLogo>

      {isLoggedIn && (
        <Menu style={{ margin: '0 auto 0 20px' }}>
          <Dropdown text="View" simple item>
            <Dropdown.Menu>
              <Dropdown.Item icon="building" text="Businesses" as={Link} to="/" />
              <Dropdown.Item icon="bolt" text="Activities" as={Link} to="/activities" />
              <Dropdown.Item icon="file text" text="Documents" as={Link} to="/documents" />
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      )}

      {isLoggedIn && <ReferralDropdown />}

      <Menu style={{ margin: isLoggedIn ? '0 20px 0 0' : '0 20px 0 auto' }}>
        <Dropdown text="Create" simple item>
          <Dropdown.Menu>
            <Dropdown.Item icon="file text" text="LLC" as={Link} to="/create-llc" />

            <Dropdown.Item icon="building" text="Corporation" as={Link} to="/create-inc" />
          </Dropdown.Menu>
        </Dropdown>
      </Menu>

      <StyledUser>
        {isLoggedIn ? (
          <Dropdown trigger={<span>{username}</span> || <Icon name="user" />} floating labeled>
            <Dropdown.Menu style={{ left: 'auto', right: 0 }}>
              {extendedMenu && (
                <>
                  <Dropdown.Item
                    icon="setting"
                    text="Partner Settings"
                    as={Link}
                    to="/partner-settings"
                  />

                  <Dropdown.Item
                    icon="help"
                    text="Help"
                    as="a"
                    href={helpLink}
                    target="_blank noopener noreferrer"
                  />

                  <Dropdown.Divider />
                </>
              )}
              <Dropdown.Item icon="sign out" text="Sign out" onClick={onLogout} />
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Link to="/login">
            <Button content="Sign in" />
          </Link>
        )}
      </StyledUser>
    </StyledHeader>
  )
}

export default Header
