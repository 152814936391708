
import { useSelector } from 'react-redux'
// import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: 40px 10px 20px;

  font-size: 18px;

  > div {
    max-width: 40em;
    padding: 1.5em 2em 3em;

    background-color: #fff;
    border-radius: 4px;

    h1 {
      margin-bottom: 0.75em;

      font-size: 2em;
    }
  }
`

export function PaymentDone() {
  const partnerInfo = useSelector<any, any>(state => state.auth.partnerInfo)

  return (
    <Wrapper>
      <div>
        <h1>Thank you for using {partnerInfo.name}.</h1>
        {(partnerInfo.username === 'betterlegal' ||
          partnerInfo.email === 'filings@betterlegal.com') && (
          <p>
            You will receive an email in the next 15 minutes with confirmation of your order. If you
            don’t receive it, check your spam filter first then reach out to us via chat if not
            there.
          </p>
        )}
        {/* {responseServices.length && <p>You have added these services:</p>}
          {responseServices.scs && (
            <p>
              <Icon color="yellow" name="star" />
              <strong>State Compliance Service</strong>
            </p>
          )}
          {responseServices.licenses && (
            <p>
              <Icon color="yellow" name="star" />
              <strong>Licenses & Permits</strong>
            </p>
          )} */}

        {/* {partnerInfo.username === 'betterlegal' && (
          <a href="https://betterlegal.com">Go to homepage</a>
        )} */}
      </div>
    </Wrapper>
  )
}
