import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import styled from 'styled-components'
import { darken } from 'polished'

import DocumentCol from './DocumentCol'

const StyledDocument = styled.div<{ customPartner: boolean }>`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  grid-column-gap: 20px;

  width: 100%;
  min-height: 50px;
  padding: 5px 20px;

  position: relative;

  background-color: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #eff1f6;

  color: #555;

  cursor: pointer;

  &:hover {
    background-color: ${darken(0.015, '#fff')};
    border-top: 1px solid;
    border-color: ${darken(0.1, '#eff1f6')};
  }

  &:after {
    content: '';
    display: ${p => (p.customPartner ? 'block' : 'none')};

    width: 10px;
    height: 100%;

    position: absolute;
    left: -10px;
    top: 0;

    background-color: #2285d0;
    border-radius: 5px 0 0 5px;
  }


  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    height: auto;
    width: 400px;
    margin: 0 auto;
    padding: 10px 15px;
  }
`

function formatDateToChicagoTimeZone(dateString) {
  return dateString
    ? moment
        .parseZone(dateString)
        .utcOffset(-5)
        .format('M/D/YYYY h:mm A')
    : null
}

const Document = ({ document }) => {
  return (
    <StyledDocument customPartner={false}>
      <DocumentCol name="name" value={document.name} bold />
      <DocumentCol name="created" value={formatDateToChicagoTimeZone(document.uploaded_at)} />
    </StyledDocument>
  )
}

export default React.memo(Document)
