
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledDropdown = styled.div`
  width: 100%;

  .ui.dropdown {
    padding: 8px 10px !important;
    min-height: auto;
    height: 32px;

    border-radius: 2px !important;
    border: 1px solid #f5f5f5;

    font-size: 13px;

    > .dropdown.icon {
      margin: 0;

      top: 50%;
      right: 0;

      transform: translateY(-50%);
    }

    &:hover {
      border-color: #36978b;

      outline: none;
    }

    & input {
      padding-left: 10px !important;
    }
  }
`

export function SimpleDropdown({ wsHandler, value, style, ...props }: any) {
  const handleFieldChange = (_, d) => {
    if (d.value && value !== d.value) {
      wsHandler(d)
    }
  }

  const handleFocus = e => {
    e.target.setAttribute('autocomplete', 'nope')
  }

  return (
    <StyledDropdown style={style}>
      <Dropdown
        value={value}
        floating
        onChange={handleFieldChange}
        onFocus={handleFocus}
        {...props}
      />
    </StyledDropdown>
  )
}
