import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { toast } from 'react-toastify'
import { AxiosResponse } from 'axios'
import { Loader } from 'semantic-ui-react'
import { useSelector } from 'react-redux'

import themes from 'utils/themes'
import { ReferralDropdownMenu } from 'components/UI/Dropdown/ReferralDropdownMenu'
import axiosInstanceCustom from 'utils/axiosInstanceCustom'
import { useCancelToken } from 'utils/axiosCancelTokenHook'
import { ReducerType } from 'store/reducers'

const MenuWrapper = styled.div`
  display: none;
  position: absolute;
  width: 270px;
  right: -56px;
`

const DropdownWrapper = styled.div`
  position: relative;

  max-width: 158px;
  max-height: 37px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 6px #00000015;
  border-radius: 0.28571429rem;
  &:hover ${MenuWrapper} {
    display: block;
  }
`

const DropdownButton = styled.button`
  border: 1px solid rgba(34, 36, 38, 0.15);
  height: 37px;
  width: 100%;
  border-radius: 0.28571429rem;
  color: #ffffff;
  font-weight: 500;
  font-family: ${p => p.theme.createLLC.font};
  cursor: pointer;
  background-color: #36978b;
  &:hover {
    cursor: pointer;
    background-color: #2d7e74;
  }
`
type AffiliateType = 'Referral' | 'Manager' | 'Director'

type PartnerAffiliatesItem = {
  name: string
  type: AffiliateType
  affiliateIdAndFieldList: {
    fields: {
      invested?: string
      stake?: string
    }
    id: string
  }[]
}

export type PartnerAffiliates = {
  affiliatesAndCompanyNameList: PartnerAffiliatesItem[]
}

export const ReferralDropdown = () => {
  const [referralUrl, setReferralUrl] = useState('' as string)
  const { token } = useSelector<any, ReducerType['auth']>(state => state.auth)

  const { newAxiosCancelToken } = useCancelToken()

  const fetchReferralLink = async () => {
    try {
      const response: AxiosResponse<PartnerAffiliates> = await axiosInstanceCustom(
        'https://blprod.jsnake.tech'
      ).get('/partner-affiliates', {
        cancelToken: newAxiosCancelToken(),
      })

      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    ;(async () => {
      const referralLinkResult = token && (await fetchReferralLink())
      if (referralLinkResult) {
        const affiliate =
          referralLinkResult.affiliatesAndCompanyNameList?.find(
            affiliate => affiliate.type === 'Referral'
          ) || referralLinkResult.affiliatesAndCompanyNameList?.[0]

        const referralId = affiliate?.affiliateIdAndFieldList[0].id || ''
        if (referralId) {
          setReferralUrl(`https://betterlegal.com/aff/${referralId}`)
        }
      }
    })()
  }, [token])

  const handleClickCopyText = () => {
    navigator.clipboard.writeText(referralUrl)
    toast.success('Referral link copied!')
  }

  // const handleClickShareViaFacebook = () => {
  //   FB.ui(
  //     {
  //       method: 'share',
  //       href: referralUrl,
  //     },
  //     function (response) {}
  //   )
  // }

  const handleClickShareViaLinkedin = () => {
    window.open(
      `https://linkedin.com/shareArticle?url=${referralUrl}&title=betterlegal`,
      'mywin',
      'width=500,height=500'
    )
  }

  const handleClickShareViaEmail = () => {
    window.location.href = `mailto:?subject=It\'s my Betterlegal referral link&amp&body=${referralUrl}`
  }

  const handleClickShareViaTwitter = () => {
    window.open(`https://twitter.com/share?url=${referralUrl}`, 'mywin', 'width=500,height=500')
  }

  return (
    <ThemeProvider theme={themes.standard}>
      <DropdownWrapper style={{ marginRight: 20 }}>
        {referralUrl ? (
          <>
            <DropdownButton onClick={handleClickCopyText}>
              Refer & Get $100 <i className="dropdown icon white" style={{ marginLeft: 10 }}></i>
            </DropdownButton>
            <MenuWrapper>
              <ReferralDropdownMenu
                onClickCopyLink={handleClickCopyText}
                // onClickShareViaFacebook={handleClickShareViaFacebook}
                onClickShareViaLinkedin={handleClickShareViaLinkedin}
                onClickShareViaTwitter={handleClickShareViaTwitter}
                onClickShareViaEmail={handleClickShareViaEmail}
              />
            </MenuWrapper>
          </>
        ) : (
          <Loader inline={false} active={true} size="mini" />
        )}
      </DropdownWrapper>
    </ThemeProvider>
  )
}
