
import MaskedInput from 'react-text-mask'
import { Radio, Form } from 'semantic-ui-react'
import { Formik, Field } from 'formik'

import ValidationError from '../../UI/ValidationError'
import { afterpaymentEmployeeSchema } from './afterpaymentHelpers'

import { dateMask } from 'utils/masks'

function AfterpaymentEmployee({ data, updateData }) {
  // const values: AfterpaymentEmployeeType = data

  return (
    <Formik
      initialValues={data}
      onSubmit={() => null}
      validationSchema={afterpaymentEmployeeSchema}
    >
      {({ errors, touched, values, setFieldValue, handleChange, handleBlur }) => {
        const radioHanlder = (e, { name, value }) => {
          e.persist()
          setFieldValue(name, value)
          updateData({ ...values, [name]: value })
        }
        return (
          <Form
            onChange={e => {
              values[e.target.name] = e.target.value
              updateData(values)
            }}
          >
            <Form.Field required>
              <label>What is the first month wages will be paid to an employee?</label>
              <MaskedInput
                type="text"
                name="firstMonthWages"
                placeholder="mm/yyyy"
                mask={dateMask}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstMonthWages}
              />
              {errors.firstMonthWages && touched.firstMonthWages && (
                <ValidationError content={errors.firstMonthWages} />
              )}
            </Form.Field>
            <Form.Field required>
              <label>How many agricultural employees are expected in the next 12 months?</label>
              <Field type="number" name="expectedAgriculturalEmployees" placeholder="e.g. 3" />

              {errors.expectedAgriculturalEmployees && touched.expectedAgriculturalEmployees && (
                <ValidationError content={errors.expectedAgriculturalEmployees} />
              )}
            </Form.Field>
            <Form.Field required>
              <label>How many other employees are expected in the next 12 months?</label>
              <Field type="number" name="expectedEmployees" placeholder="e.g. 5" />

              {errors.expectedEmployees && touched.expectedEmployees && (
                <ValidationError content={errors.expectedEmployees} />
              )}
            </Form.Field>

            <Form.Field required>
              <label>
                Do you expect your total employment taxes to be $1,000 or less in the coming year?
              </label>
            </Form.Field>
            <Form.Field>
              <Radio
                label="Yes"
                name="taxesLessThan1000"
                value="yes"
                checked={values.taxesLessThan1000 === 'yes'}
                onChange={radioHanlder}
              />
              {errors.taxesLessThan1000 && touched.taxesLessThan1000 && (
                <ValidationError content={errors.taxesLessThan1000} />
              )}
            </Form.Field>
            <Form.Field>
              <Radio
                label="No"
                name="taxesLessThan1000"
                value="no"
                checked={values.taxesLessThan1000 === 'no'}
                onChange={radioHanlder}
              />
            </Form.Field>
          </Form>
        )
      }}
    </Formik>
  )
}
export default AfterpaymentEmployee
