
import styled from 'styled-components'

import { usePartner } from 'utils/hooks'

import mcafeeImg from 'img/seals/mcafee.png'
import stripeImg from 'img/seals/stripe.svg'
import godaddyImg from 'img/seals/godaddy.png'
import bbbImg from 'img/seals/bbb.png'
import trustpilotImg from 'img/seals/trustpilot.svg'

const StyledBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin-top: 10px;

  img {
    height: 35px;
    margin-bottom: 10px;
  }

  & > * + * {
    margin-left: 10px;
  }
`

const Badges = () => {
  const [, isBetterlegal] = usePartner()

  return (
    <StyledBadges>
      <img src={mcafeeImg} alt="McAfee seal" />
      <img src={stripeImg} alt="Stripe seal" />
      <img src={godaddyImg} alt="GoDaddy seal" />
      {isBetterlegal && (
        <>
          <a
            href="https://www.bbb.org/central-texas/business-reviews/incorporation/betterlegal-in-austin-tx-1000137460"
            target="_blank noopener noreferrer"
          >
            <img src={bbbImg} alt="BBB seal" />
          </a>
          <a
            href="https://www.trustpilot.com/review/betterlegal.com"
            target="_blank noopener noreferrer"
          >
            <img src={trustpilotImg} alt="TrustPilot seal" />
          </a>
        </>
      )}
    </StyledBadges>
  )
}

export default Badges
