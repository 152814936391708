
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'

import { usePartner } from 'utils/hooks'
import { authInit } from 'store/slices/authSlice'

const StyledLoginContainer = styled.div`
  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`

function Login(props) {
  const [partner, isBetterlegal] = usePartner()

  const { from } = props.location.state || { from: { pathname: '/' } }

  if (props.isLoggedIn) return <Redirect to={from} />

  return (
    <StyledLoginContainer>
      <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" color={isBetterlegal ? 'teal' : 'grey'} textAlign="center">
            Log in to your account
          </Header>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={values => props.submit(partner, values)}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form size="large">
                <Segment>
                  <Form.Input
                    fluid
                    icon="user"
                    placeholder="E-mail address"
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />

                  <Button
                    type="submit"
                    color={isBetterlegal ? 'teal' : 'grey'}
                    fluid
                    size="large"
                    onClick={() => handleSubmit()}
                  >
                    Login
                  </Button>
                  <Link to="/forgot-password" style={{ display: 'block', marginTop: 10 }}>
                    Forgot password?
                  </Link>
                </Segment>
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid>
    </StyledLoginContainer>
  )
}

const stateToProps = state => ({
  isLoggedIn: state.auth.token != null
})

const dispatchToProps = dispatch => ({
  submit: (partner, data) => dispatch(authInit({ partner, data }))
})

export default connect(
  stateToProps,
  dispatchToProps
)(Login)
