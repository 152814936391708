import React, { useState, useEffect } from 'react'
import { Container, Segment, Form, Button } from 'semantic-ui-react'
import queryString from 'query-string'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'

import { passwordResetSubmit } from 'store/actions/password'
import ValidationError from '../UI/ValidationError'

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Too short')
    .required('Required'),
  confirm: Yup.string()
    .min(6, 'Too short')
    .required('Required')
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
})

function ResetPassword(props) {
  const [token, setToken] = useState('')

  useEffect(() => {
    const urlToken = queryString.parse(props.location.search).token.toString()
    setToken(urlToken)
  }, [props.location.search])

  const submitHandler = values => {
    if (values.password === values.confirm) {
      props.submit(token, values.password)
    } else {
      alert('paswords should be same')
    }
  }

  return (
    <Container>
      <Segment padded="very" style={{ maxWidth: 500, margin: '0 auto' }}>
        <h1>Reset Password</h1>
        <Formik
          initialValues={{ password: '', confirm: '' }}
          onSubmit={values => submitHandler(values)}
          validationSchema={resetPasswordSchema}
        >
          {({ errors, touched, handleSubmit, isValid }) => (
            <Form>
              <Form.Group required>
                <label>New Password</label>
                {errors.password && touched.password && (
                  <ValidationError content={errors.password} />
                )}
              </Form.Group>
              <Form.Group>
                <Field type="password" name="password" placeholder="New Password" />
              </Form.Group>

              <Form.Group>
                <label>Confirm Password</label>
                {errors.confirm && touched.confirm && <ValidationError content={errors.confirm} />}
              </Form.Group>
              <Form.Group>
                <Field type="password" name="confirm" placeholder="Confirm Password" />
              </Form.Group>
              <Button
                disabled={!isValid}
                type="submit"
                content="Submit"
                onClick={() => handleSubmit()}
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  )
}

const mapDispatchToProps = dispatch => ({
  submit: (token, password) => dispatch(passwordResetSubmit(token, password))
})

export default connect(
  null,
  mapDispatchToProps
)(ResetPassword)
