
import styled from 'styled-components'
import { Icon, Modal } from 'semantic-ui-react'

const EmailsButton = styled.a`
  margin-left: 10px;

  font-size: 12px;
  color: #36978b;

  &:hover {
    color: #627381;
  }
`

const EmailsContent = styled.div`
  padding: 10px;

  & > p {
    margin: 2px 0;
  }
`

function ProfileContact({ org }) {
  let content

  if (org.partner?.username === 'betterlegal' || org.partner?.email === 'filings@betterlegal.com') {
    const users = org.type?.toLowerCase() === 'llc' ? org.managers : org.directors

    content = (
      <>
        {users?.map(u => (
          <p key={u?.id}>{u?.user?.email}</p>
        ))}
      </>
    )
  } else {
    content = <p>{org?.partner?.email}</p>
  }

  return (
    <Modal
      closeIcon
      size="mini"
      trigger={
        <EmailsButton href="#">
          <Icon name="mail" />
        </EmailsButton>
      }
      header="Order email"
      content={<EmailsContent>{content}</EmailsContent>}
    />
  )
}

export default ProfileContact
