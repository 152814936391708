import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import { ProfileInfoHeader } from '../ProfileInfoHeader'
import { EditableAgentSimplified } from 'components/UI/Editable/EditableAgent'
import { EditableSimpleField } from 'components/UI/Editable/EditableSimpleField'
import { EditablePrincipalAddressSimplified } from 'components/UI/Editable/EditablePrincipalAddress'
import { EditablePersonsListSimplified } from 'components/UI/Editable/EditablePersonsList'
import { EditableDropdown } from 'components/UI/Editable/EditableDropdown'
import { businessPurposeSchema } from 'components/UI/Editable/editableSchemas'
import { StakeWarning } from 'components/UI/StakeWarning'

import { states } from 'utils/states'
import { WS_TIMEOUT } from 'utils/constants'
import { isMatchRaAddress } from 'utils/simplifiedAddress'

import { StyledProfilePanel, StyledProfileOverviewContainer, OrgInfoRow } from '../../UI/orgHelpers'

function ProfileLLC({ org, userRights, handlers, updateRAaddress }) {
  const { wsOrgInfo, wsAffiliate, wsRemoveAffiliate, wsAddAffiliate } = handlers
  const [stakeSum, setStakeSum] = useState(0)

  useEffect(() => {
    let sum = 0
    _.forEach(org?.members, member => (sum = sum + parseInt(member?.fields?.stake)))
    setStakeSum(sum)
  }, [org])

  const managerOptions = org.managers.map(c => ({
    key: c.id,
    value: c.user.full_name,
    text: c.user.full_name,
  }))

  const onManagerChange = updatedManager => {
    wsAffiliate(updatedManager)

    // if taxes manager set, check if we need to update him
    const oldValueManager = org.managers.find(c => c.id === updatedManager.id)
    if (org.fields.taxes_manager && oldValueManager) {
      // check if name changed and if that manager is a taxes manager
      const previousFullName = oldValueManager.user.full_name

      if (
        previousFullName === org.fields.taxes_manager &&
        previousFullName !== updatedManager.user.full_name
      ) {
        wsOrgInfo({ fields: { taxes_manager: updatedManager.user.full_name } })
      }
    }
  }

  return (
    <>
      <StyledProfilePanel>
        <ProfileInfoHeader caption="Info" />
        <StyledProfileOverviewContainer>
          <OrgInfoRow>
            <label>State Filed</label>
            <EditableDropdown
              fieldName="state_filed"
              options={states}
              data={{ state_filed: org.state_filed }}
              readOnly={!userRights.editInfo}
              wsHandler={wsOrgInfo}
              placeholder="Select State"
            />
          </OrgInfoRow>

          {/* <OrgInfoRow>
            <label>Type</label>
            <CustomInput name="type" value={org.fields.members_type} disabled />
          </OrgInfoRow> */}

          <OrgInfoRow>
            <label>Business Purpose</label>
            <EditableSimpleField
              fieldName="fields.business_purpose"
              data={{ fields: { business_purpose: org.fields.business_purpose } }}
              isTextarea
              readOnly={!userRights.editInfo}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              validationSchema={businessPurposeSchema}
            />
          </OrgInfoRow>

          {/* I don't know why isEmpty was added there? It didn't affect any styles <OrgInfoRow isEmpty={!org.address.address1}> */}
          <OrgInfoRow>
            <label>Principal Office Address</label>
            <EditablePrincipalAddressSimplified
              data={{ address: org.address }}
              readOnly={!userRights.editInfo}
              wsHandler={wsOrgInfo}
              useRaAddress={isMatchRaAddress(org?.address, org?.agent?.address)}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Taxes Manager</label>
            <EditableDropdown
              fieldName="fields.taxes_manager"
              options={managerOptions}
              data={{ fields: { taxes_manager: org.fields.taxes_manager } }}
              readOnly={!userRights.editInfo}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
              placeholder={managerOptions.length ? 'Taxes Manager' : 'Enter a manager below'}
            />
          </OrgInfoRow>

          <OrgInfoRow>
            <label>Registered Agent</label>
            <EditableAgentSimplified
              data={org.agent}
              readOnly={!userRights.editInfo}
              wsHandler={updateRAaddress}
            />
          </OrgInfoRow>
        </StyledProfileOverviewContainer>
      </StyledProfilePanel>

      <StyledProfilePanel>
        <EditablePersonsListSimplified
          type="Manager"
          data={org.managers}
          userRights={userRights}
          wsHandler={onManagerChange}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
        />
      </StyledProfilePanel>

      <StyledProfilePanel>
        {stakeSum !== 100 && org?.members?.length > 0 && <StakeWarning />}
        <EditablePersonsListSimplified
          type="Member"
          data={org.members}
          managers={org.managers}
          userRights={userRights}
          wsHandler={wsAffiliate}
          wsRemoveAffiliate={wsRemoveAffiliate}
          wsAddAffiliate={wsAddAffiliate}
        />
      </StyledProfilePanel>
    </>
  )
}

export default ProfileLLC
