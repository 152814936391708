import { createSlice } from '@reduxjs/toolkit'

const auth = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    userData: {},
    partnerInfo: {
      name: '',
      username: '',
      email: '',
      fee: 0,
      address: {
        address1: '',
        city: '',
        province: '',
        zipcode: '',
      },
      fees: null,
    },
  },
  reducers: {
    checkCookies: (state, action) => {
      state.token = action.payload.token
    },
    fetchUsername: state => state,
    setUsernameSuccess: (state, action) => {
      state.userData = action.payload
    },
    authInit: (state, action) => {},
    authSuccess: (state, action) => {
      state.token = action.payload
    },
    fetchPartnerInfo: (state, action) => {},
    fetchPartnerInfoSuccess: (state, action) => {
      state.partnerInfo = action.payload
    },
  },
})

export const {
  fetchUsername,
  authInit,
  fetchPartnerInfo,
  checkCookies,
  setUsernameSuccess,
  authSuccess,
  fetchPartnerInfoSuccess,
} = auth.actions

export default auth.reducer
