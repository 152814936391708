import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Container, Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import styled from 'styled-components'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

import DocumentList from './DocumentList'
import DocumentListSearch from './DocumentListSearch'

import { usePartner } from 'utils/hooks'
import axiosInstance from 'utils/axiosInstance'
import { createUserRightsObject } from 'utils/userRights'

const StyledDocumentListControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const StyledContainer = styled(Container)`
  margin-bottom: 50px;

  position: relative;

  @media screen and (max-width: 1200px) {
    &.ui.container {
      width: 100%;
      padding: 0 15px;
    }
  }
`

const StyledDocumentsCount = styled.p`
  color: #aaa;
  text-align: right;
`

function getNextPageNumber(url) {
  if (!url) return null
  return queryString.parseUrl(url).query.page
}

function DocumentsContainer() {
  const dispatch = useDispatch()
  const [documents, setDocuments] = useState([])
  const [documentsCount, setDocumentsCount] = useState(0)
  const [searchQuery, setSearchQuery] = useState('')
  const [nextPage, setNextPage] = useState(null)
  const inputRef = useRef(null)

  const userRole = useSelector<any, any>(state => state.auth.userData.role)
  const userRights = createUserRightsObject(userRole)

  const [, isBetterlegal] = usePartner()

  const loadMore = async () => {
    dispatch(showLoading())
    const response = await axiosInstance({
      method: 'GET',
      url: '/documents/forms',
      params: {
        page_size: 200,
        ...(nextPage ? { page: nextPage } : {}),
        ...(searchQuery ? { name: searchQuery } : {}),
      },
    })

    setDocuments(documents.concat(response.data.results))
    setDocumentsCount(response.data.count)
    setNextPage(getNextPageNumber(response.data.next))
    dispatch(hideLoading())
  }

  const fetchDocuments = useCallback(async () => {
    dispatch(showLoading())
    const response = await axiosInstance({
      method: 'GET',
      url: '/documents/forms',
      params: {
        page_size: 200,
        ...(searchQuery ? { name: searchQuery } : {}),
      },
    })

    setDocuments(response.data.results)
    setDocumentsCount(response.data.count)
    setNextPage(getNextPageNumber(response.data.next))
    dispatch(hideLoading())
  }, [dispatch, searchQuery])

  useEffect(() => {
    fetchDocuments()
  }, [searchQuery, fetchDocuments])

  const uploadFile = useCallback(
    async file => {
      if (!file.name.includes('.docx')) {
        return toast.error('Document has to be docx format')
      }

      dispatch(showLoading())

      try {
        const data = new FormData()
        data.append('name', file.name.replace(/\.docx/g, ''))
        data.append('docx_file', file)

        await axiosInstance.post('/documents/forms/', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        toast.success('File was uploaded successfully!')
        dispatch(hideLoading())
        fetchDocuments()
      } catch (error) {
        console.error(error.response)
        toast.error(error.response?.data?.details[0]?.title)
        dispatch(hideLoading())
      }
    },
    [dispatch, fetchDocuments]
  )

  return (
    <>
      <StyledContainer>
        <StyledDocumentListControls>
          <DocumentListSearch searchQuery={searchQuery} searchFn={setSearchQuery} />
          {userRights.uploadDocument && (
            <Button
              color="teal"
              content="Upload document"
              onClick={() => inputRef.current.click()}
            />
          )}
          <input
            ref={c => {
              inputRef.current = c
            }}
            type="file"
            hidden
            disabled={!userRights.uploadDocument}
            onChange={e => {
              const file = e.target.files[0]

              if (file) {
                uploadFile(file)
              }
            }}
          />
        </StyledDocumentListControls>

        <DocumentList documents={documents} />

        {documentsCount > 0 && (
          <StyledDocumentsCount>{`${documents.length} / ${documentsCount} Documents loaded`}</StyledDocumentsCount>
        )}

        {documents.length < documentsCount ? (
          <>
            <Button
              color={isBetterlegal ? 'teal' : null}
              onClick={() => loadMore()}
              content="Load more"
            />
          </>
        ) : null}
      </StyledContainer>
    </>
  )
}

export default DocumentsContainer
