import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Howl } from 'howler'
import { Icon } from 'semantic-ui-react'
import axios from 'axios'

import { ACITIVITY_VOICE_RECORDING_LIMIT } from 'utils/constants'

const AudioPlayer = styled.div`
  display: flex;

  margin: 4px 0px;

  input[type='range'] {
    height: 4px;
    width: 100%;

    position: relative;

    border-radius: 2px;

    overflow: hidden;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;

    background: #ecedf1;
    border-radius: 5px;

    overflow: hidden;
    cursor: pointer;
  }

  input[type='range']::-moz-range-track {
    height: 3px;

    background: #ecedf1;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #ecedf1;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 3px;

    background: #ecedf1;
    border: none;

    color: transparent;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 0;
    height: 0;

    background: #fff;
    box-shadow: -100vw 0 0 100vw #8a90a9;
    -webkit-appearance: none;
  }

  input[type='range']::-moz-range-thumb {
    background: #fff;
    height: 0;
    width: 0;

    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw #8a90a9;

    box-sizing: border-box;
  }

  input[type='range']::-ms-fill-lower {
    background: #8a90a9;
  }

  input[type='range']::-ms-thumb {
    height: 0;
    width: 0;

    background: #fff;
    box-sizing: border-box;
  }

  input[type='range']::-ms-ticks-after {
    display: none;
  }

  input[type='range']::-ms-ticks-before {
    display: none;
  }

  input[type='range']::-ms-tooltip {
    display: none;
  }
`

const ActivityChatHeader = styled.div`
  padding: 10px;

  audio {
    display: none;
  }
`

const ActivityChatPlayerControls = styled.div`
  display: flex;

  .icon {
    margin-right: 0.5rem;

    cursor: pointer;
  }
`

const ActivityChatPlayerInfo = styled.div`
  line-height: normal;
  user-select: none;

  p {
    margin-bottom: 0;

    font-size: 14px;
    font-weight: 600;
  }

  span {
    font-size: 12px;
    color: #757575;
  }
`

function CallRecording({ title, url, phone }) {
  const [player, setPlayer] = useState({
    play: () => {},
    pause: () => {},
    seek: e => {},
    playing: () => false,
    state: () => 'unloaded',
    load: () => {},
  })
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDurtation] = useState(0)
  const [timePlayed, setTimePlayed] = useState(0)
  const timeout = useRef(null)

  useEffect(() => {
    function initHowl(preload) {
      const sound = new Howl({
        src: [url + '.wav'],
        preload,
      })

      sound.once('load', () => {
        setDurtation(Math.floor(sound.duration()))

        if (!preload) {
          // means user clicked play button and it was loaded
          sound.play()
        }

        timeout.current = setInterval(() => {
          setTimePlayed(Math.floor(sound.seek()))
        }, 250)
      })
      sound.on('end', () => setIsPlaying(false))

      setPlayer(sound)
    }

    axios({
      url: url + '.wav',
      method: 'HEAD',
    })
    .then(result => {
      const size = result.headers['content-length']
      const preload = size / 1024 < ACITIVITY_VOICE_RECORDING_LIMIT

      initHowl(preload)
    })
    .catch(() => {
      // in case something goes wrong just initalize
      initHowl(false)
    })

    // cleanup interval
    return removeInterval
  }, [url])

  function removeInterval() {
    if (timeout.current) {
      clearInterval(timeout.current)
    }
  }

  function togglePlayer() {
    if (player) {
      if (player.state() === 'unloaded') {
        player.load()
      } else if (player.playing()) {
        player.pause()
        setIsPlaying(false)
      } else {
        player.play()
        setIsPlaying(true)
      }
    }
  }

  function formatTime(duration) {
    const minutes = Math.floor(duration / 60)
    let seconds = Math.floor(duration % 60).toString()

    if (seconds.length === 1) {
      seconds = '0' + seconds
    }

    return `${minutes}:${seconds}`
  }

  function seekTo(seconds) {
    player.seek(seconds)
    setTimePlayed(seconds)
  }

  return (
    <ActivityChatHeader>
      <ActivityChatPlayerControls>
        <Icon
          onClick={togglePlayer}
          name={!isPlaying ? 'play circle outline' : 'pause circle outline'}
          id="play"
          color="grey"
          size="big"
        />
        <ActivityChatPlayerInfo>
          <p>
            {title} from {phone}
          </p>
          <span>
            {formatTime(timePlayed)} / {formatTime(duration)}
          </span>
        </ActivityChatPlayerInfo>
      </ActivityChatPlayerControls>
      <AudioPlayer>
        <input
          type="range"
          step="1"
          min="0"
          max={Math.floor(duration)}
          onChange={e => seekTo(+e.target.value)}
          value={timePlayed}
        />
      </AudioPlayer>
    </ActivityChatHeader>
  )
}

export default CallRecording
