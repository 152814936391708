import React, { useEffect, useState } from 'react'
import { Container, Button } from 'semantic-ui-react'
import styled from 'styled-components'
// import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import Helmet from 'react-helmet'

import OrgList from './OrgList'
import OrgListFilters from './OrgListFilters'
import OrgListSearch from './OrgListSearch'

import { usePartner } from 'utils/hooks'
import axiosInstance from 'utils/axiosInstance'
import { createUserRightsObject } from 'utils/userRights'

const StyledOrgListControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
`

const StyledContainer = styled(Container)`
  margin-bottom: 50px;

  position: relative;

  @media screen and (max-width: 1200px) {
    &.ui.container {
      width: 100%;
      padding: 0 15px;
    }
  }
`

const StyledOrgsCount = styled.p`
  color: #aaa;
  text-align: right;
`

function getNextPageNumber(url) {
  if (!url) return null
  return queryString.parseUrl(url).query.page
}

function OrgContainer() {
  const dispatch = useDispatch()
  const [orgs, setOrgs] = useState([])
  const [orgsCount, setOrgsCount] = useState(0)
  const [statuses, setStatuses] = useState('')
  const [filter, setFilter] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [nextPage, setNextPage] = useState(null)

  const { userData, partnerInfo } = useSelector<any, any>(state => state.auth)

  const userRights = createUserRightsObject(userData?.role)

  const [, isBetterlegal] = usePartner()

  const loadMore = async () => {
    dispatch(showLoading())
    const response = await axiosInstance({
      method: 'GET',
      url: '/business/organization',
      params: {
        page_size: 200,
        ...(nextPage ? { page: nextPage } : {}),
        ...(filter ? { status: filter } : {}),
        ...(searchQuery ? { name: searchQuery } : {}),
      },
    })

    setOrgs(orgs.concat(response.data.results))
    setOrgsCount(response.data.count)
    setStatuses(response.data.statuses)
    setNextPage(getNextPageNumber(response.data.next))
    dispatch(hideLoading())
  }

  useEffect(() => {
    async function fetchOrgs() {
      dispatch(showLoading())
      const response = await axiosInstance({
        method: 'GET',
        url: '/business/organization',
        params: {
          page_size: 200,
          ...(filter ? { status: filter } : {}),
          ...(searchQuery ? { name: searchQuery } : {}),
        },
      })

      setOrgs(response.data.results)
      setOrgsCount(response.data.count)
      setStatuses(response.data.statuses)
      setNextPage(getNextPageNumber(response.data.next))
      dispatch(hideLoading())
    }

    fetchOrgs()
  }, [dispatch, filter, searchQuery])

  return (
    <>
      <Helmet defer={false}>
        <title>{partnerInfo.name} - Organization List</title>
      </Helmet>
      <StyledContainer>
        <StyledOrgListControls>
          {userRights.extendedMenu && (
            <OrgListFilters statuses={statuses} activeFilter={filter} setFilter={setFilter} />
          )}
          <OrgListSearch searchQuery={searchQuery} searchFn={setSearchQuery} />
        </StyledOrgListControls>

        <OrgList orgs={orgs} />

        {orgsCount > 0 && (
          <StyledOrgsCount>{`${orgs.length} / ${orgsCount} organizations loaded`}</StyledOrgsCount>
        )}

        {/*  !loading && (
             <Link to="/welcome">
               <Button>No orgs yet, create one</Button>
             </Link>
           ) */}

        {orgs.length < orgsCount ? (
          <>
            <Button
              color={isBetterlegal ? 'teal' : null}
              onClick={() => loadMore()}
              content="Load more"
            />
          </>
        ) : null}
      </StyledContainer>
    </>
  )
}

export default OrgContainer
