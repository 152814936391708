import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import CallRecording from './CallRecording'
import { Scrollbars } from 'react-custom-scrollbars'
import axios from 'axios'
import moment from 'moment'

const StyledActivityChatRow = styled.div<{ isAgent?: boolean }>`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 18px;
  flex-direction: ${props => !props.isAgent ? 'row' : 'row-reverse'};
  float: ${props => !props.isAgent ? 'left' : 'right'};
  &:first-child {
    margin-top: 15px;
  }
`

const StyledActivityChatAvatarBlock = styled.div`
  position: relative;
  .icon {
    position: absolute;
    right: 2px;
    bottom: 4px;
    margin-right: 0px;
  }
`

const StyledActivityChatAvatar = styled.img`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-left: 5px;
`

const StyledActivityChatRowBlock = styled.div`
  width: 55%;
`

const StyledActivityChatMessage = styled.div<{ isAgent?: boolean }>`
  background-color: ${props => props.isAgent ? '#a7ddd6' : '#ecedf1'};
  padding: 10px 15px;
  border-radius: 12px;
  min-height: 58px;
`
const StyledActivityChatRowInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  font-size: 11px;
  font-weight: 500;
`

const StyledActivityChatButton = styled.div`
  border-radius: 6px;
  margin: 15px 10px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.2);
  padding: 1px 2px;
  cursor: pointer;
  .icon {
    margin-right: 0px;
  }
`


const StyledActivityChatHistory = styled.div`
  text-align: center;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 12px;
  border-top: 1px solid #ddd;
`

const StyledActivityChatContainer = styled.div`
  margin-top: 5px;
  border-top: 1px solid #ddd;
  padding: 0px 10px;
`

const StyledViewChatButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  cursor: pointer;
  outline:none;
  padding: 0px;
`

function returnTitle(data) {
  const activityType = data.fields.twilio_activity_type
  let title = ''

  if (activityType === 'voicemail') {
    title = 'Voicemail'
  } else if (activityType === 'voice') {
    const callType = data.fields.optional_fields?.type
    if (callType) {
      title = callType.slice(0, 1).toUpperCase() + callType.slice(1) + ' call'
    } else {
      title = 'Incoming call'
    }
  }

  return title
}

function ActivityChat({ data }) {
  const [showFull, setShowFull] = useState(false)
  const [messages, setmessages] = useState([])
  const [messagesLoaded, setmessagesLoaded] = useState(false)
  const type = data.fields.twilio_activity_type


  function toggleMessages(e) {
    e.stopPropagation()
    if (!messagesLoaded) {
      // load messages
      const params = (type === 'chat' || type === 'email')
                      ? { channelSid: data.fields.payload_url }
                      : { recordingUrl: data.fields.payload_url }

      axios({
        method: 'get',
        url: `${process.env.REACT_APP_TWILIO_URL}/fetch-channel-messages`,
        params
      })
      .then(res => {
        setmessages(res.data)
        setmessagesLoaded(true)
        setShowFull(true)
      })
    } else {
      setShowFull(!showFull)
    }
  }

  const title = returnTitle(data)
 
  return (
    <div>
      {(type === 'voicemail' || type === 'voice') &&
        <CallRecording
          title={title}
          url={data.fields.payload_url}
          phone={data.fields.phone}
        />
      }
      {!showFull &&
        <StyledViewChatButton>
          <Icon name='comment alternate outline' color='grey' size='large' onClick={toggleMessages} />
        </StyledViewChatButton>
      }
      
      {showFull &&
        <StyledActivityChatContainer>
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={500} autoHide>
          {messages.map(message => {
            // first message is always sent by user
            // not in transcription, we have Agent/Client there
            const isAgent = type === 'chat'
                              ? message.from !== messages[0].from
                              : message.from === 'Agent'

            return (
              <StyledActivityChatRow isAgent={isAgent} key={message.sid}>
                {isAgent &&
                  <StyledActivityChatAvatarBlock>
                    <StyledActivityChatAvatar src="https://via.placeholder.com/150" alt=""/>
                    <Icon circular inverted color='teal' name='phone' rotated='clockwise' size='tiny' />
                  </StyledActivityChatAvatarBlock>
                }
                <StyledActivityChatRowBlock>
                  <StyledActivityChatMessage isAgent={isAgent}>
                    <p>{message.body}</p>
                  </StyledActivityChatMessage>
                  <StyledActivityChatRowInfo>
                    <span>{isAgent ? message.from : 'Anonymous'} - {moment(message.dateCreated).format('H:mm a')}</span>
                    {/* <span>Transcribed</span> */}
                  </StyledActivityChatRowInfo>
                </StyledActivityChatRowBlock>
                <StyledActivityChatButton>
                  <Icon name="ellipsis vertical" color='grey' />
                </StyledActivityChatButton>
              </StyledActivityChatRow>
            )
          }
          )}
          </Scrollbars>
        </StyledActivityChatContainer>
      }
      {showFull &&
        <StyledActivityChatHistory onClick={(e) => {e.stopPropagation(); setShowFull(false)}}>
          CLOSE CHAT HISTORY
          <Icon name={showFull ? 'caret up' : 'caret down'} color='grey' />
        </StyledActivityChatHistory>
      }
    </div>
  )
}

export default ActivityChat
