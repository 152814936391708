import { push } from 'connected-react-router'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { signOff } from 'store/actions/auth'
import { paymentCheckParamsAffiliate } from 'store/actions/payment'
import { getPaymentState } from 'store/sagas/selectors/payment'
import { ALLSTATE, EMPTY_URL_PARAM } from 'utils/constants'
import { userRights } from 'utils/userRights'
import Header from 'components/UI/Header/Header'
import { ReducerType } from 'store/reducers'

function HeaderContainer() {
  const dispatch = useDispatch()
  const authState = useSelector<any, any>(state => state.auth)
  const { paramsAffiliate } = useSelector<any, ReducerType['payment']>(getPaymentState)
  const partnerId = queryString.parseUrl(location.href).query[
    ALLSTATE.toLocaleLowerCase()
  ] as string

  const handleLogout = () => {
    dispatch(signOff())
    dispatch(push('/login'))
    window.location.reload()
  }

  useEffect(() => {
    if (partnerId && !Array.isArray(partnerId) && partnerId !== EMPTY_URL_PARAM) {
      dispatch(paymentCheckParamsAffiliate(partnerId))
    }
  }, [partnerId, paramsAffiliate])

  return (
    <Header
      partner={authState.partnerInfo}
      isLoggedIn={authState.token != null}
      username={authState.userData.full_name}
      extendedMenu={userRights[authState.userData.role]?.extendedMenu}
      onLogout={() => handleLogout()}
    />
  )
}

export default HeaderContainer
