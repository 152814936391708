import { PaymentUrlParamsType } from 'components/Payment/paymentUrlParams'
import { ReducerType } from 'store/reducers'
import * as actionTypes from 'store/actions/actionTypes'

export const paymentGetOrgStart = () => {
  return { type: actionTypes.PAYMENT_GET_ORG_START }
}

export const paymentGetOrgFail = () => {
  return { type: actionTypes.PAYMENT_GET_ORG_FAIL }
}

export const paymentGetOrgSuccess = data => {
  return { type: actionTypes.PAYMENT_GET_ORG_SUCCESS, data }
}

export const paymentGetOrg = id => {
  return {
    type: actionTypes.PAYMENT_GET_ORG,
    id,
  }
}

export const paymentSearchOrgStart = () => {
  return { type: actionTypes.PAYMENT_SEARCH_ORG_START }
}

export const paymentSearchOrgFail = () => {
  return { type: actionTypes.PAYMENT_SEARCH_ORG_FAIL }
}

export const paymentSearchOrgSuccess = data => {
  return { type: actionTypes.PAYMENT_SEARCH_ORG_SUCCESS, data }
}

export const paymentSearchOrg = urlParams => {
  return {
    type: actionTypes.PAYMENT_SEARCH_ORG,
    urlParams,
  }
}

export const paymentSearchStateInfo = state => {
  return {
    type: actionTypes.PAYMENT_SEARCH_STATE_INFO,
    state,
  }
}

export const paymentGetStateInfo = data => {
  return {
    type: actionTypes.PAYMENT_GET_STATE_INFO,
    data,
  }
}

export const paymentCheckCoupon = coupon => {
  return {
    type: actionTypes.PAYMENT_CHECK_COUPON,
    coupon,
  }
}

export const paymentApplyDiscount = discount => {
  return {
    type: actionTypes.PAYMENT_APPLY_DISCOUNT,
    discount,
  }
}

export const addCouponName = name => {
  return {
    type: actionTypes.ADD_COUPON_NAME,
    name,
  }
}

export const paymentProcessPaymentStart = () => {
  return { type: actionTypes.PAYMENT_PROCESS_PAYMENT_START }
}

export const paymentProcessPaymentFail = error => {
  return { type: actionTypes.PAYMENT_PROCESS_PAYMENT_FAIL, error }
}

export const paymentProcessPaymentSuccess = () => {
  return { type: actionTypes.PAYMENT_PROCESS_PAYMENT_SUCCESS }
}

export const paymentProcessPayment = (
  name: string,
  token: string,
  amount: number,
  urlData?: PaymentUrlParamsType,
  orgId?: string
) => {
  return {
    type: actionTypes.PAYMENT_PROCESS_PAYMENT,
    name,
    token,
    amount,
    urlData,
    orgId,
  }
}

export const paymentSetRegisteredAgent = checked => {
  return {
    type: actionTypes.PAYMENT_SET_REGISTERED_AGENT,
    checked,
  }
}

export const paymentSwitchRegisteredAgentType = plan => {
  return {
    type: actionTypes.PAYMENT_SWITCH_REGISTERED_AGENT_TYPE,
    plan,
  }
}

export const paymentSimplifiedLoad = serviceFee => {
  return {
    type: actionTypes.PAYMENT_SIMPLIFIED_LOAD,
    serviceFee,
  }
}

export const paymentUpdateSpecialRequest = value => {
  return {
    type: actionTypes.PAYMENT_UPDATE_SPECIAL_REQUEST,
    value,
  }
}

export const paymentModeSet = mode => {
  return {
    type: actionTypes.PAYMENT_MODE_SET,
    mode,
  }
}

export const paymentSetCoupon = coupon => {
  return {
    type: actionTypes.PAYMENT_SET_COUPON,
    coupon,
  }
}

export const paymentCheckParamsAffiliate = (
  paramsAffiliateCode: ReducerType['payment']['paramsAffiliate']['code']
) => {
  return {
    type: actionTypes.PAYMENT_CHECK_PARAMS_AFFILIATE,
    paramsAffiliateCode,
  }
}

export const paymentSetParamsAffiliate = (
  paramsAffiliate: ReducerType['payment']['paramsAffiliate']
) => {
  return { type: actionTypes.PAYMENT_SET_PARAMS_AFFILIATE, paramsAffiliate }
}
