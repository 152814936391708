import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Tab, Icon } from 'semantic-ui-react'
import * as typeformEmbed from '@typeform/embed'
import { useSelector } from 'react-redux'
import queryString from 'query-string'

import themes from 'utils/themes'
import * as vars from 'utils/styledVariables'
import { usePartner } from 'utils/hooks'

import ProfileActivities from './ProfileActivities'
import ProfileDocs from './ProfileDocs'

import BannerImg from 'img/additional-services-banner.png'
import { ProfileAddActivityModal } from './ProfileAddActivityModal'
import { UserRights } from 'utils/userRights'
import { ProfileEditActivityModal } from './ProfileEditActivityModal'

const StyledTabMenu = styled(Tab)`
  box-shadow: ${vars.shadow};

  & > .ui.pointing.secondary.menu {
    margin-bottom: 0;

    border-radius: 4px 4px 0 0;
    border: none;
    background-color: ${p => p.theme.lightBright};

    & > .item {
      height: 40px;
      margin: 0 20px;
      padding: 0;

      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      &.active {
        border-width: 3px;
        border-color: ${p => p.theme.accent};

        font-weight: 900;
      }
    }
  }

  & > .ui.segment {
    margin-top: 0;
    padding: 0;

    box-shadow: none;
    border-radius: 0 0 4px 4px;
    border: none;
  }
`

const Banner = styled.img`
  max-width: 100%;
  margin-bottom: 10px;
`

interface ProfileSidebarType {
  orgData: any
  userRights: UserRights
}

export default function ProfileSidebar({ orgData, userRights }: ProfileSidebarType) {
  const [, isBetterlegal] = usePartner()
  const [addModalActive, setAddModalActive] = useState(false)

  const authData = useSelector<any, any>(state => state.auth)

  const typeformParams = {
    org_id: orgData.id,
    org_name: orgData.name?.replace('&', '%26'),
    org_type: orgData.type,
    state_filed: orgData.state_filed,
    partner: orgData.partner.name,
    partner_username: orgData.partner.username,
    user: authData?.userData.email,
  }

  const makePopup = () => {
    typeformEmbed.makePopup(
      `https://form.typeform.com/to/rkMdrso6?${queryString.stringify(typeformParams)}`,
      {
        mode: 'drawer_right',
        autoOpen: true,
      }
    )
  }

  const panes = [
    {
      menuItem: 'Activities',
      render: () => (
        <Tab.Pane attached={false}>
          <ProfileActivities
            orgData={orgData}
            userRights={userRights}
            addModalActive={addModalActive}
            setAddModalActive={setAddModalActive}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Documents',
      render: () => (
        <Tab.Pane attached={false}>
          <ProfileDocs orgId={orgData.id} />
        </Tab.Pane>
      ),
    },
  ]

  return (
    <ThemeProvider theme={isBetterlegal ? themes.standard : themes.partner}>
      <a
        href={`https://betterlegal.com/additional-services?org_name=${orgData.name}&org_type=${orgData.type}&state_filed=${orgData.state_filed}&org_id=${orgData.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Banner src={BannerImg} alt="" />
      </a>

      <div style={{ position: 'relative' }}>
        <StyledTabMenu menu={{ secondary: true, pointing: true }} panes={panes} />
        {userRights.controlActivities && (
          <Icon
            name="plus"
            style={{
              position: 'absolute',
              top: 9,
              right: 30,
              cursor: 'pointer',
              fontSize: 16,
            }}
            onClick={() => setAddModalActive(true)}
          />
        )}
        {isBetterlegal && userRights.sidebarMessage && (
          <Icon
            name="paper plane"
            style={{
              position: 'absolute',
              top: 9,
              right: 5,
              cursor: 'pointer',
              color: '#35978b',
              fontSize: 18,
            }}
            onClick={() => makePopup()}
          />
        )}
      </div>
    </ThemeProvider>
  )
}
