import React, { useEffect, useState, useCallback } from 'react'
import { Button, Confirm, Container, Grid, Label } from 'semantic-ui-react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import _ from 'lodash'
import Helmet from 'react-helmet'
import axios from 'axios'

import { usePartner } from 'utils/hooks'
import { formatOrgType } from 'utils/orgType'
import { userRights } from 'utils/userRights'
import { WS_TIMEOUT } from 'utils/constants'
import * as webSocketConstants from 'utils/webSocketConstants'
import axiosInstance from 'utils/axiosInstance'

import ProfileStatus from '../ProfileStatus'
import ProfileInc from '../ProfileInfo/ProfileInc'
import ProfileLLC from '../ProfileInfo/ProfileLLC'
import ProfileContact from '../ProfileContact'
import ProfileSidebar from '../Sidebar/ProfileSidebar'
import ProfileSole from '../ProfileInfo/ProfileSole'
import { ProfilePaymentModal } from '../ProfilePaymentModal'
import ProfileLP from '../ProfileInfo/ProfileLP'
import EditableDate from '../../UI/Editable/EditableDate'
import EditableStatus from '../../UI/Editable/EditableStatus'
import { EditableSimpleField } from '../../UI/Editable/EditableSimpleField'
import { fetchActivities } from '../../UI/orgHelpers'
import { isProduction } from 'utils/isProduction'

const StyledProfileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 25px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    height: auto;
  }
`

const StyledProfileName = styled.div`
  display: flex;
  align-items: center;

  width: 400px;
  margin: 0;

  font-size: 18px;
  font-weight: bold;
  color: black;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`

const StyledProfileStatuses = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export interface AffiliateType {
  id: string
  type: string
  user: any
  order: number
  address: any
  fields: any
}

function TestUserProfile(props) {
  const [orgData, setOrgData] = useState(null)
  const [activities, setActivities] = useState([])
  const [ws, setWs] = useState(null)
  const [paymentModalVisible, setPaymentModalVisible] = useState(false)
  const [confirmActive, setConfirmActive] = useState(false)

  const editable =
    orgData?.status?.toLowerCase() === 'active' || orgData?.status?.toLowerCase() === 'draft'

  const dispatch = useDispatch()
  const { partnerInfo, token, userData } = useSelector<any, any>(state => state.auth)

  const userRightsUser = userRights.user

  const [partner] = usePartner()

  const currentOrgId = props.match.params.id

  useEffect(() => {
    setPaymentModalVisible(orgData?.pm.active)
  }, [orgData])

  useEffect(() => {
    fetchActivities(currentOrgId).then(result => setActivities(result))
  }, [currentOrgId, orgData])

  useEffect(() => {
    if (!ws) {
      const wsUrl = `${process.env.REACT_APP_WS_URL}/org/${currentOrgId}/?token=${token}`
      setWs(new WebSocket(wsUrl))
    } else {
      ws?.addEventListener('message', e => {
        const response = JSON.parse(e.data)
        dispatch(hideLoading())

        if (response.type === webSocketConstants.CONNECT_ORG_INFO) {
          setOrgData(response.data)
          return
        } else if (response.type === webSocketConstants.ORG_INFO) {
          setOrgData({ ...response.data, address: { ...response.data.address } })
          return
        } else if (response.type === webSocketConstants.ERROR) {
          toast.error(response.message)
          return
        } else {
          toast.warn('Unknown WebSocket Response, see console for the details')
          console.warn(e)
        }
      })
    }

    // return function cleanup() {
    //   ws?.removeEventListener('message', e => console.log(JSON.parse(e)))
    //      ws?.close()
    // }
  }, [dispatch, token, ws, currentOrgId])

  const wsOrgInfo = useCallback(
    async data => {
      dispatch(showLoading())
      ws.send(
        JSON.stringify({
          type: webSocketConstants.EDIT_INFO,
          payload: {
            uuid: currentOrgId,
            ...data,
          },
        })
      )
    },
    [dispatch, ws, currentOrgId]
  )

  const wsAgent = useCallback(
    async data => {
      dispatch(showLoading())
      ws.send(
        JSON.stringify({
          type: webSocketConstants.EDIT_AGENT,
          payload: {
            uuid: data.id,
            ...data,
          },
        })
      )
    },
    [dispatch, ws]
  )

  const wsAffiliate = useCallback(
    async data => {
      dispatch(showLoading())

      data.fields.invested = +_.replace(data.fields?.invested, /[$,]/g, '')
      data.fields.stake = +_.replace(data.fields?.stake, /[,%]/g, '')

      ws.send(
        JSON.stringify({
          type: webSocketConstants.EDIT_AFFILIATE,
          payload: {
            uuid: data.id,
            ...data,
          },
        })
      )
    },
    [dispatch, ws]
  )

  const wsAddAffiliate = useCallback(
    async type => {
      dispatch(showLoading())

      ws.send(
        JSON.stringify({
          type: webSocketConstants.ADD_AFFILIATE,
          payload: {
            type,
          },
        })
      )
    },
    [dispatch, ws]
  )

  const wsRemoveAffiliate = useCallback(
    async (type, uuid) => {
      dispatch(showLoading())

      ws.send(
        JSON.stringify({
          type: webSocketConstants.REMOVE_AFFILIATE,
          payload: {
            type,
            uuid,
          },
        })
      )
    },
    [dispatch, ws]
  )

  const wsPaymentRevert = useCallback(async () => {
    dispatch(showLoading())

    ws.send(
      JSON.stringify({
        type: webSocketConstants.MODAL_REVERT,
        payload: {},
      })
    )
  }, [dispatch, ws])

  const wsPaymentConfirm = useCallback(
    async (customer_id?: string, transaction_id?: string) => {
      dispatch(showLoading())

      ws.send(
        JSON.stringify({
          type: webSocketConstants.MODAL_CONFIRM,
          payload: {},
        })
      )

      if (isProduction()) {
        await axios({
          method: 'post',
          headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          url: 'https://hooks.zapier.com/hooks/catch/962269/ow9ebz1/',
          data: {
            org_id: orgData?.id,
            org_name: orgData?.name,
            org_type: orgData?.type,
            service: orgData?.pm?.action,
            customer_id: customer_id,
            transaction_id: transaction_id,
            amount: orgData?.pm?.fees?.admin_fee + orgData?.pm?.fees?.filing_fee,
            user_name: userData?.full_name,
            user_email: userData?.email,
          },
        })
      }
    },
    [orgData, userData, dispatch, ws]
  )

  const handlers = {
    wsOrgInfo,
    wsAgent,
    wsAffiliate,
    wsAddAffiliate,
    wsRemoveAffiliate,
  }

  const mockModalDataFetch = () => {
    return {
      title: orgData.pm?.action,
      description: orgData.pm?.summary,
      turnaround: orgData.pm?.turnaround,
      serviceFee: orgData.pm?.fees?.admin_fee,
      stateFee: orgData.pm?.fees?.filing_fee,
      stateFiled: orgData.state_filed,
    }
  }

  const getStripeEmail = useCallback(() => {
    switch (orgData?.type.toLowerCase()) {
      case 'llc':
        return _.find(orgData.managers, { order: 1 })?.user?.email
      case 'inc':
        return _.find(orgData.directors, { order: 1 })?.user?.email
      default:
        return 'itsNotAnLLCorInc@mail.com'
    }
  }, [orgData])

  function renderProfile(orgData, handlers) {
    let Component

    switch (orgData.type.toLowerCase()) {
      case 'sole':
        Component = ProfileSole
        break
      case 'inc':
        Component = ProfileInc
        break
      case 'lp':
        Component = ProfileLP
        break
      case 'llc':
      default:
        Component = ProfileLLC
        break
    }
    return (
      <Component
        org={orgData}
        readOnly={!editable && !userRightsUser.editInfo}
        handlers={handlers}
      />
    )
  }

  const handleConfirmCancel = () => {
    wsPaymentRevert()
    setPaymentModalVisible(false)
    setConfirmActive(false)
  }

  const uploadFile = useCallback(
    async file => {
      dispatch(showLoading())

      try {
        const data = new FormData()
        data.append('file', file)
        data.append('org_id', orgData.id)
        data.append('org_type', orgData.type)
        await axiosInstance.post('/app/storage/upload', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        wsPaymentConfirm()
        toast.success('File was uploaded successfully!')
        dispatch(hideLoading())
      } catch (error) {
        toast.error(error)
        console.log(error)
      }
    },
    [dispatch, orgData, wsPaymentConfirm]
  )

  const processModalPayment = useCallback(
    async (token, name) => {
      dispatch(showLoading())

      try {
        const response = await axiosInstance.post(`/payment/process`, {
          email: getStripeEmail() || 'noManagerOrDirectorWithOrder1@mail.com',
          token: token,
          amount: (orgData.pm?.fees?.admin_fee + orgData.pm?.fees?.filing_fee) * 100,
          metadata: {
            name: name,
            org_name: orgData.name,
            org_id: orgData.id,
            org_type: orgData.type,
            province: orgData.state_filed,
            partner: orgData.partner.username,
            service: orgData.pm?.action,
          },
        })

        dispatch(hideLoading())
        wsPaymentConfirm(response.data.customer_id, response.data.transaction_id)
      } catch (error) {
        console.error(error.response)
        toast.error(error.response?.data?.details[0]?.title)
      }
    },
    [dispatch, orgData, wsPaymentConfirm, getStripeEmail]
  )

  return (
    orgData && (
      <Container>
        <Helmet defer={false}>
          <title>
            {partnerInfo.name} - {orgData.name}
          </title>
        </Helmet>
        <ProfilePaymentModal
          visible={paymentModalVisible}
          onClose={() => setConfirmActive(true)}
          data={mockModalDataFetch()}
          modalHandler={processModalPayment}
          uploadHandler={uploadFile}
        />
        <Confirm
          open={confirmActive}
          onCancel={() => setConfirmActive(false)}
          onConfirm={handleConfirmCancel}
          content="Are you sure want to close the payment dialog and revert the changes?"
          size="tiny"
          confirmButton={<Button style={{ backgroundColor: '#3ead9d' }}>Confirm</Button>}
        />
        <StyledProfileHeader>
          <StyledProfileName>
            <EditableSimpleField
              fieldName="name"
              data={{ name: orgData.name }}
              readOnly={!editable && !userRightsUser.editInfo}
              wsHandler={_.debounce(data => wsOrgInfo(data), WS_TIMEOUT)}
            />
            {orgData?.type?.toLowerCase() !== 'sole' && (
              <span style={{ marginLeft: 10 }}>{` ${formatOrgType(orgData.type)}`}</span>
            )}

            {partnerInfo.username === 'betterlegal' && <ProfileContact org={orgData} />}
          </StyledProfileName>

          <StyledProfileStatuses>
            {orgData.partner.username !== partner && (
              <ProfileStatus>
                <Label color="blue">
                  Partner
                  <Label.Detail>{orgData.partner.username}</Label.Detail>
                </Label>
              </ProfileStatus>
            )}
            <ProfileStatus caption="Status">
              <EditableStatus
                data={orgData.status}
                orgId={orgData.id}
                readOnly={!userRightsUser.editInfo}
                wsHandler={wsOrgInfo}
              />
            </ProfileStatus>

            <ProfileStatus caption="Date filed">
              <EditableDate
                data={orgData.date_filed}
                readOnly={!userRightsUser.editInfo}
                wsHandler={wsOrgInfo}
              />
            </ProfileStatus>
          </StyledProfileStatuses>
        </StyledProfileHeader>

        <Grid doubling columns={2}>
          <Grid.Column width={9}>{renderProfile(orgData, handlers)}</Grid.Column>
          <Grid.Column width={7}>
            <ProfileSidebar orgData={orgData} userRights={userRightsUser} />
          </Grid.Column>
        </Grid>
      </Container>
    )
  )
}

export default TestUserProfile
