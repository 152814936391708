import * as Yup from 'yup'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const nameRegex = /^[a-zA-Z0-9\-&\s]+$/g
const businessPurposeRegex = /^[a-zA-Z\s]+$/g
const orgNameRegex = /^[a-zA-Z\-&\s]+$/g

export const personSchema = Yup.object().shape({
  address: Yup.object().shape({
    address1: Yup.string()
      .matches(/^[^#]*$/g, { message: 'Please use NUM, APT, or STE instead of # in address field' })
      .required('Address is required'),
    city: Yup.string().required('City is required'),
    province: Yup.string().required('State is required'),
    zipcode: Yup.string().required('Zip Code is required'),
  }),
  user: Yup.object().shape({
    first_name: Yup.string().required('First name is required').matches(nameRegex, {
      message: 'The only special characters allowed in full name are - or &',
    }),
    last_name: Yup.string().required('Last name is required').matches(nameRegex, {
      message: 'The only special characters allowed in full name are - or &',
    }),
    email: Yup.string().email('Email is in wrong format').required('Email is required'),
    phone: Yup.number().typeError('Phone number should contain only digits'),
  }),
})

export const personSchemaSimplified = Yup.object().shape({
  user: Yup.object().shape({
    full_name: Yup.string().required('Full name is required').matches(nameRegex, {
      message: 'The only special characters allowed in full name are - or &',
    }),
    email: Yup.string().email('Email is in wrong format').required('Email is required'),
    phone: Yup.number().typeError('Phone number should contain only digits'),
  }),
})


export const addressSchema = Yup.object().shape({
  address: Yup.object().shape({
    address1: Yup.string()
      .matches(/^[^#]*$/g, { message: 'Please use NUM, APT, or STE instead of # in address field' })
      .required('Address is required'),
    city: Yup.string().required('City is required'),
    province: Yup.string().required('State is required'),
    zipcode: Yup.string()
      .typeError('Zip Code should contain only digits')
      .required('Zip Code is required'),
  }),
})


export const orgNameSchema = Yup.object().shape({
  name: Yup.string().matches(orgNameRegex, {
    message: 'The only special characters allowed in full name are - or &',
  }),
})

export const businessPurposeSchema = Yup.object().shape({
  fields: Yup.object().shape({
    business_purpose: Yup.string().matches(businessPurposeRegex, {
      message: 'Special characters are not allowed',
    }),
  }),
})

export const investedMask = createNumberMask({
  prefix: '$',
})

export const stakeMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: false,
  suffix: '%',
  integerLimit: 100,
})