import styled from 'styled-components'

export const StyledProfileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 25px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    height: auto;
  }
`

export const StyledProfileName = styled.div`
  display: flex;
  align-items: center;

  width: 600px;
  margin: 0;

  font-size: 18px;
  font-weight: bold;
  color: black;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const StyledProfileStatuses = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
`
