
import styled from 'styled-components'

import { colors } from './colors'
import { StatusType } from './StatusType'

const StyledStatus = styled.span<StatusType>`
  padding: 2px 5px;

  border: 1px solid;
  border-color: ${p => colors[p.status]};
  border-radius: 4px;

  color: ${p => colors[p.status]};
  font-weight: 900;
  font-size: 11px;
  text-transform: uppercase;
`

export function OrgStatus({ status }: StatusType) {
  return <StyledStatus status={status}>{status}</StyledStatus>
}
