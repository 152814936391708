import styled from 'styled-components'

export const StyledActivityItem = styled.div<{ isActive?: boolean; isActivitiesList?: boolean }>`
  padding: ${props => (props.isActivitiesList ? '15px 0px' : '15px 25px')};

  position: relative;

  background-color: ${props => (props.isActive ? '#fbfbfb' : '#fff')};

  font-size: 14px;

  /* cursor: pointer; */

  & + & {
    border-top: 1px solid #ddd;
  }
`

export const StyledActivityHeader = styled.div<{ isActivitiesList?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 5px;
  padding: ${props => (props.isActivitiesList ? '0 25px' : '0px')};

  font-size: 14px;
  color: #379285;

  div {
    display: flex;
    align-items: center;
  }

  i.icon {
    margin: 0 0.3rem 0;

    &.phone {
      font-size: 1.3em;

      transform: rotate(-35deg);
    }

    &.call {
      font-size: 1.1em;

      transform: rotate(100deg);
    }

    &.arrow {
      margin: 0;

      top: -2px;
      bottom: auto;

      font-size: 0.65em;

      transform: rotate(-35deg);
    }
  }
`

export const StyledActivityContent = styled.div<{ type?: string; isActivitiesList?: boolean }>`
  padding: ${props => (props.isActivitiesList ? '0 25px' : '0px')};

  position: relative;

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }
`

export const StyledActivityDate = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #a3a3a3;
`

export const StyledActivityDesc = styled.p<{ type?: string }>`
  display: ${props => (props.type === 'call' ? 'none' : 'initial')};

  margin: 0 0 5px;

  font-weight: 500;
  word-break: break-all;
`

export const StyledCompanyName = styled.p`
  margin-top: 15px;
  padding: 10px 25px 0px;

  border-top: 1px solid #ddd;

  font-size: 15px;
`

export const ActivityListItem = styled.li`
  display: grid;
  grid-template-columns: 3fr 5fr;
  gap: 10px;
  align-items: center;

  > strong {
    text-align: right;
    font-weight: 700;
  }

  > span {
    color: #646464;
    font-weight: 400;
    word-break: break-all;
  }
`

export const EditIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  cursor: pointer;

  font-size: 12px;
  color: #ddd;

  &:hover {
    color: #379285;
  }
`
