import { put } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import axiosInstance from 'utils/axiosInstance'
import { fetchOrgSuccess } from '../slices/orgSlice'

export function* fetchOrgSaga(action) {
  yield put(showLoading())

  try {
    const response = yield axiosInstance.get(`/business/organization/${action.payload}`)
    yield put(fetchOrgSuccess(response.data))
    yield put(hideLoading())
  } catch (err) {
    console.error(err)
    yield put(hideLoading())
  }
}
