import { createSlice } from '@reduxjs/toolkit'
import { mapAffiliateFields } from 'utils/paymentUtils'

const org = createSlice({
  name: 'org',
  initialState: { activeOrg: null },
  reducers: {
    fetchOrg: (state, action) => {
      state.activeOrg = null
    },
    fetchOrgSuccess: (state, action) => {
      state.activeOrg = mapAffiliateFields(action.payload)
    },
    newEditInfoUpdateProfile: (state, action) => {
      state.activeOrg = action.payload
    },
    registeredAgentPutSuccess: (state, action) => {
      state.activeOrg.agent = action.payload
    }
  }
})

export const { fetchOrg, fetchOrgSuccess, newEditInfoUpdateProfile, registeredAgentPutSuccess } = org.actions

export default org.reducer