
import styled from 'styled-components'
import MaskedInput from 'react-text-mask'

const Placeholder = styled.span`
  display: none;
  padding: 2px;

  position: absolute;
  top: 0px;
  right: 2px;

  background: #fff;

  font-size: 11px;
  line-height: 0;
  color: #444;
`

const StyledInput = styled.input<{ active?: boolean; form: any }>`
  width: 100%;
  padding: 8px 10px;

  border: 1px solid #f5f5f5;
  border-radius: 2px;
  border-color: ${p => p.active && '#36978b'};
  border-color: ${p => p.form && !p.form?.isValid && '#e34626 !important'};
  box-sizing: border-box;

  font-size: 13px;

  &:hover,
  &:focus {
    border-color: #36978b;
    outline: none;
  }

  &:focus + ${Placeholder} {
    display: block;
  }

  &:disabled {
    cursor: not-allowed;
    border-style: dotted;
    border-color: #efefef;

    color: #666;
    font-style: italic;
  }
`

const FieldWrapper = styled.div`
  position: relative;
`

const StyledTextarea = styled.textarea<{ active?: boolean; form: any }>`
  width: 100%;
  padding: 10px;
  height: 50px;

  border-radius: 0;
  border: 1px solid #efefef;
  border-color: ${p => p.active && '#36978b'};
  border-color: ${p => p.form && !p.form?.isValid && '#e34626 !important'};

  font-size: 13px;

  resize: none;
  transition: 0.2s ease;

  &:hover,
  &:focus {
    border-color: #36978b;
    outline: none;
  }

  &:disabled {
    border-style: dotted;
    border-color: #efefef;

    color: #666;
    font-style: italic;

    cursor: not-allowed;
  }
`

export const CustomInput = ({ field, ...props }: any) => (
  <FieldWrapper {...props}>
    <StyledInput
      type="text"
      {...field}
      placeholder={props.placeholder}
      disabled={props.disabled}
      style={props.style}
    />
    {props.placeholder && <Placeholder>{props.placeholder}</Placeholder>}
  </FieldWrapper>
)

export const CustomTextarea = ({ field, ...props }: any) => <StyledTextarea {...field} {...props} />

export const CustomInputMasked = ({ mask, field, ...props }) => (
  <FieldWrapper {...props}>
    <MaskedInput
      mask={mask}
      type="text"
      {...field}
      render={(ref, props) => <StyledInput ref={ref} {...props} />}
    />
    {props.placeholder && <Placeholder>{props.placeholder}</Placeholder>}
  </FieldWrapper>
)
