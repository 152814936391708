
import { Scrollbars } from 'react-custom-scrollbars'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { ActivityOrgSearch } from './ActivityOrgSearch'

const StyledWrapper = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const ActivityHeader = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;

  padding: 10px 15px;

  border-radius: 4px 4px 0 0;
  background-color: #f7f8fb;

  font-size: 12px;
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  p {
    margin: 0;
  }

  .icon {
    margin-left: 5px;

    position: absolute;
  }

  a .icon {
    margin-left: 12px;
  }
`

const ActivityHeaderManager = styled.div`
  display: flex;
  align-items: center;
`

const StyledOrgContent = styled.div`
  position: relative;
`

const StyledOrgContentContainer = styled.div`
  padding: 10px 15px;
`

const StyledOrgContentRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 150px 1fr;

  padding: 2px 0;
  margin: 5px 0;

  background-color: #fff;

  label {
    font-weight: 600;
  }
`

const StyledOrgContentFieldFull = styled.span`
  width: 100%;
  padding: 10px;

  border: 1px solid #efefef;

  transition: 0.2s ease;
  box-sizing: border-box;
`
interface StyledOrgContentFieldBlockProps {
  isAgent?: boolean
  isMemberOrManager?: boolean
}

interface StyledOrgContentFieldBlockProps {
  isAgent?: boolean
  isMemberOrManager?: boolean
}

const StyledOrgContentFieldBlock = styled.div<StyledOrgContentFieldBlockProps>(
  p => `
  display: grid;
  grid-template-columns: ${p.isMemberOrManager ? '4fr 2fr 1fr 1fr' : '2fr 1fr 1fr'};
  grid-template-rows: ${p.isAgent ? '1fr 1fr 1fr' : '1fr 1fr'};
  gap: 2px;

  width: 100%;
  padding: ${p.isMemberOrManager ? '10px' : '0px'};

  position: relative;
  
  border-top: ${p.isMemberOrManager ? '1px solid #eee;' : 'none'};

  span {
    width: 100%;
    padding: 10px;

    border: 1px solid #efefef;
    
    transition: 0.2s ease;
    box-sizing: border-box;
  }
`
)

function Field({ value, style, placeholder }: any) {
  return <span style={{ ...style, color: value ? '#000' : 'grey' }}>{value || placeholder}</span>
}

function ActivitySidebar({ org, selectedActivity, updateActivity }) {
  if (selectedActivity.id && !selectedActivity.org) {
    return <ActivityOrgSearch activityId={selectedActivity.id} updateActivity={updateActivity} />
  }

  return (
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={760} autoHide>
      <StyledWrapper>
        <ActivityHeader>
          <p>INFO</p>
          {org && (
            <Link to={`/org/${org.id}`} style={{ lineHeight: '1.4285em', color: '#000' }}>
              {org.name}
              <Icon name="edit" color="grey" />
            </Link>
          )}
        </ActivityHeader>
        {org && (
          <StyledOrgContent>
            <StyledOrgContentContainer>
              <StyledOrgContentRow>
                <label>State Filed</label>
                <StyledOrgContentFieldFull>{org.state_filed}</StyledOrgContentFieldFull>
              </StyledOrgContentRow>

              <StyledOrgContentRow>
                <label>Type</label>
                <StyledOrgContentFieldFull>{org.fields.members_type}</StyledOrgContentFieldFull>
              </StyledOrgContentRow>

              <StyledOrgContentRow>
                <label>Business Purpose</label>
                <StyledOrgContentFieldFull>{org.fields.business_purpose}</StyledOrgContentFieldFull>
              </StyledOrgContentRow>

              <StyledOrgContentRow>
                <label>Principal Office Address</label>
                <StyledOrgContentFieldBlock>
                  <Field
                    style={{ gridColumn: '1/4' }}
                    value={org.principal_office.address1}
                    placeholder="Address"
                  />

                  <Field value={org.principal_office.city} placeholder="City" />
                  <Field value={org.principal_office.province} placeholder="Province" />
                  <Field value={org.principal_office.zipcode} placeholder="Zipcode" />

                  <Field
                    style={{ gridColumn: '1/4' }}
                    value={org.principal_office.county}
                    placeholder="County"
                  />
                </StyledOrgContentFieldBlock>
              </StyledOrgContentRow>

              <StyledOrgContentRow>
                <label>Taxes Manager</label>
                <StyledOrgContentFieldFull>{org.fields.taxes_manager}</StyledOrgContentFieldFull>
              </StyledOrgContentRow>

              {org.agent && (
                <StyledOrgContentRow>
                  <label>Registered Agent</label>
                  <StyledOrgContentFieldBlock isAgent>
                    <Field
                      style={{ gridColumn: '1/4' }}
                      value={org.agent.full_name}
                      placeholder="Full name"
                    />
                    <Field
                      style={{ gridColumn: '1/4' }}
                      value={org.agent.email}
                      placeholder="Email"
                    />
                    <Field
                      style={{ gridColumn: '1/4' }}
                      value={org.agent.address.address1}
                      placeholder="Address"
                    />

                    <Field value={org.agent.address.city} placeholder="City" />
                    <Field value={org.agent.address.province} placeholder="Province" />
                    <Field value={org.agent.address.zipcode} placeholder="Zipcode" />

                    <Field
                      style={{ gridColumn: '1/4' }}
                      value={org.agent.address.county}
                      placeholder="County"
                    />
                  </StyledOrgContentFieldBlock>
                </StyledOrgContentRow>
              )}
            </StyledOrgContentContainer>
          </StyledOrgContent>
        )}
      </StyledWrapper>

      <StyledWrapper>
        <ActivityHeader>
          <p>MANAGERS {org?.managers ? `(${org.managers.length})` : ''}</p>
        </ActivityHeader>

        {org?.managers && (
          <StyledOrgContent>
            {org.managers.map(manager => (
              <StyledOrgContentFieldBlock isMemberOrManager key={manager.id}>
                <Field
                  style={{ gridRow: '1/2' }}
                  value={manager.user.full_name}
                  placeholder="Full name"
                />
                <Field style={{ gridRow: '2/3' }} value={manager.user.email} placeholder="Email" />
                <Field style={{ gridRow: '3/4' }} value={manager.user.phone} placeholder="Phone" />

                <Field
                  style={{ gridColumn: '2/5' }}
                  value={manager.address.address1}
                  placeholder="Address"
                />

                <Field value={manager.address.city} placeholder="City" />
                <Field value={manager.address.province} placeholder="Province" />
                <Field value={manager.address.zipcode} placeholder="Zipcode" />
              </StyledOrgContentFieldBlock>
            ))}
          </StyledOrgContent>
        )}
      </StyledWrapper>

      <StyledWrapper>
        <ActivityHeader>
          <ActivityHeaderManager>
            <p>MEMBERS {org?.members ? `(${org.members.length})` : ''}</p>
          </ActivityHeaderManager>
        </ActivityHeader>
        {org?.members && (
          <div>
            {org.members.map(member => (
              <StyledOrgContentFieldBlock isMemberOrManager key={member.id}>
                <Field
                  style={{ gridRow: '1/2' }}
                  value={member.user.full_name}
                  placeholder="Full name"
                />
                <Field style={{ gridRow: '2/3' }} value={member.user.email} placeholder="Email" />
                <Field style={{ gridRow: '3/4' }} value={member.user.phone} placeholder="Phone" />
                <Field
                  style={{ gridArea: '1 / 2 / 2 / 3' }}
                  value={member.fields.stake ? member.fields.stake + '%' : ''}
                  placeholder="Stake"
                />
                <Field
                  style={{ gridArea: '1 / 3 / 2 / 5' }}
                  value={member.fields.invested ? '$' + member.fields.invested : ''}
                  placeholder="Invested"
                />

                <Field
                  style={{ gridColumn: '2/5' }}
                  value={member.address.address1}
                  placeholder="Address"
                />
                <Field value={member.address.city} placeholder="City" />
                <Field value={member.address.province} placeholder="Province" />
                <Field value={member.address.zipcode} placeholder="Zipcode" />
              </StyledOrgContentFieldBlock>
            ))}
          </div>
        )}
      </StyledWrapper>
    </Scrollbars>
  )
}

export default ActivitySidebar
