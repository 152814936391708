import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ProfileEditActivityModal } from 'components/Profile/Sidebar/ProfileEditActivityModal'

import { createUserRightsObject } from 'utils/userRights'
import axiosInstance from 'utils/axiosInstance'
import { push } from 'connected-react-router'
import { Activity } from './Activity'

export default function ActivityPage(props) {
  const dispatch = useDispatch()

  const [modalActive, setModalActive] = useState<boolean>(true)
  const [activeActivity, setActiveActivity] = useState<Activity>(null)

  const activityId = props.match.params.id

  const { userData } = useSelector<any, any>(state => state.auth)
  const userRights = createUserRightsObject(userData?.role)

  const fetchActivity = async id => {
    const response = axiosInstance.get(`/activity/${id}`)

    return response
  }

  useEffect(() => {
    fetchActivity(activityId)
    .then(response => setActiveActivity(response.data))
    .catch(() => dispatch(push('/activities')))
  }, [activityId])

  return (
    <ProfileEditActivityModal
      userRights={userRights}
      data={activeActivity}
      active={modalActive}
      onClose={() => dispatch(push(`/org/${activeActivity.org.id}`))}
      setModalActive={setModalActive}
      afterSubmit={() => dispatch(push(`/org/${activeActivity.org.id}`))}
    />
  )
}
