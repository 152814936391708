import { put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import axiosInstance from 'utils/axiosInstance'

export function* passwordForgotSubmitSaga(action) {
  const partner = yield select(state => state.auth.partnerInfo.username)

  try {
    yield axiosInstance.post('/users/reset-password', {
      email: action.email,
      partner_site: partner,
      link_only: false
    })

    yield toast.success('Success')
  } catch (err) {
    yield toast.error(err?.response?.data?.details[0]?.title)
  }
}

export function* passwordResetSubmitSaga(action) {
  try {
    yield axiosInstance.put('/users/reset-password', {
      token: action.token,
      password: action.password
    })

    yield toast.success('Success')
    yield put(push('/login'))
  } catch (err) {
    yield toast.error(err?.response?.data?.details[0]?.title)
  }
}
