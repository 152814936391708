import React, { useEffect } from 'react'
// import React, { useState, useEffect, useMemo } from 'react'
import { Container, Segment, Form, Button, Icon, Grid, Input } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import * as typeformEmbed from '@typeform/embed'
import Helmet from 'react-helmet'
import { push } from 'connected-react-router'

// import { Editor, createEditor } from 'slate'
// import { withReact } from 'slate-react'
import ValidationError from 'components/UI/ValidationError'
import { partnerSettingsSchema } from 'components/Account/partnerSettingsSchema'
// import { WelcomeDescEditor } from './WelcomeDescEditor'
import { RootState } from 'store/rootState'
import { partnerSettingsSubmit } from 'store/slices/partnerSettingsSlice'
import { userRights } from 'utils/userRights'

// const TEXT_FORMATS = ['bold', 'italic', 'underline']

const StyledField = styled(Field)`
  &:disabled {
    background: #f5f5f5 !important;
    cursor: not-allowed;
  }
`

const PartnerSettingsHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    margin: 0 0 20px;

    h1 {
      margin: 0;

      font-size: 24px;
    }
  }
`

const NewUserBtn = styled.button`
  display: inline-block;
  max-width: 100%;

  margin: 0;
  padding: 10px 15px;

  background-color: #69c6b9;
  border-radius: 3px;
  border: none;

  color: white;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;

  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: #2b7a76;
    color: #fff;
  }

  @media screen and (max-width: 480px) {
    padding: 10px;

    font-size: 14px;
  }
`

// const isFormatActive = (editor, format) => {
//   if (TEXT_FORMATS.includes(format)) {
//     const [match] = Editor.nodes(editor, {
//       match: { [format]: true },
//       mode: 'all'
//     })
//     return !!match
//   }

//   return false
// }

// const withRichText = editor => {
//   const { exec } = editor

//   editor.exec = command => {
//     if (command.type === 'toggle_format') {
//       const { format } = command
//       const isActive = isFormatActive(editor, format)

//       if (TEXT_FORMATS.includes(format)) {
//         Editor.setNodes(
//           editor,
//           { [format]: isActive ? null : true },
//           { match: 'text', split: true }
//         )
//       }
//     } else {
//       exec(command)
//     }
//   }

//   return editor
// }

function Settings() {
  const dispatch = useDispatch()
  // const [loading, setLoading] = useState(false)

  // const editor = useMemo(() => withRichText(withReact(createEditor())), [])

  const data = useSelector((state: RootState) => state.auth.partnerInfo)
  const token = useSelector((state: RootState) => state.auth.token)
  const userRole = useSelector((state: RootState) => state.auth.userData.role)
  // const [editorState, setEditorState] = useState(html.deserialize('<p></p>'))

  // useEffect(() => {
  //   if (data.welcome_text) setEditorState(html.deserialize(data.welcome_text))
  // }, [data])

  useEffect(() => {
    if (userRole && !userRights[userRole]?.partnerSettings) dispatch(push('/notfound'))
  }, [userRole, dispatch])

  const handleSubmit = data => {
    dispatch(partnerSettingsSubmit(data))
  }

  return (
    <Container>
      <Helmet defer={false}>
        <title>Partner Settings</title>
      </Helmet>
      <Segment padded style={{ margin: '0 auto' }}>
        <PartnerSettingsHeader>
          <h1>Partner Settings</h1>

          <NewUserBtn
            onClick={() => {
              typeformEmbed.makePopup(
                `https://poseidonimaging.typeform.com/to/MCl5iV?partner=${data.username}&username=${data.name}`,
                {
                  mode: 'drawer_right',
                  autoOpen: true,
                }
              )
            }}
          >
            <Icon name="user plus" /> New Partner User
          </NewUserBtn>
        </PartnerSettingsHeader>
        <Formik
          initialValues={data}
          onSubmit={handleSubmit}
          validationSchema={partnerSettingsSchema}
          enableReinitialize
        >
          {({ errors, touched, isValid, handleSubmit, setFieldValue }) => (
            <Form>
              <Grid stackable columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Group>
                      <label>Username</label>
                      {errors.username && touched.username && (
                        <ValidationError content={errors.username} />
                      )}
                    </Form.Group>
                    <Form.Group>
                      <StyledField name="username" placeholder="username" disabled />
                    </Form.Group>
                    <Form.Group>
                      <label>Name</label>
                      {errors.name && touched.name && <ValidationError content={errors.name} />}
                    </Form.Group>
                    <Form.Group>
                      <StyledField name="name" placeholder="name" />
                    </Form.Group>

                    <Form.Group>
                      <label>Email</label>
                      {errors.email && touched.email && <ValidationError content={errors.email} />}
                    </Form.Group>
                    <Form.Group>
                      <StyledField name="email" placeholder="email" />
                    </Form.Group>

                    <Form.Group>
                      <label>Address</label>
                    </Form.Group>
                    {errors.address &&
                      Object.keys(errors.address).map(k => (
                        <ValidationError
                          key={k}
                          content={`${k}: ${errors.address[k]}`}
                          style={{ margin: '0 0 5px 0' }}
                        />
                      ))}

                    <Form.Group
                      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 4 }}
                    >
                      <StyledField
                        name="address.address1"
                        placeholder="address1"
                        style={{ gridColumn: '1 / 4' }}
                      />
                      <StyledField name="address.city" placeholder="city" />
                      <StyledField name="address.province" placeholder="state" />
                      <StyledField name="address.zipcode" placeholder="zipcode" />
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Group>
                      <label>Filing Fee</label>
                      {errors.fee && touched.fee && <ValidationError content={errors.fee} />}
                    </Form.Group>
                    <Form.Group>
                      <StyledField name="fee" placeholder="fee" />
                    </Form.Group>
                    <Form.Group>
                      <label>Registered Agent Fees</label>
                    </Form.Group>

                    <ErrorMessage
                      name="fees.agent.monthly"
                      render={msg => (
                        <ValidationError
                          content={'Monthly: ' + msg}
                          style={{ margin: '0 0 5px 0' }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="fees.agent.yearly"
                      render={msg => (
                        <ValidationError
                          content={'Yearly: ' + msg}
                          style={{ margin: '0 0 5px 0' }}
                        />
                      )}
                    />
                    <Form.Group style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
                      <StyledField
                        name="fees.agent.monthly"
                        placeholder="Registered Agent Monthly Fee"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            label={{ basic: true, content: 'Mo' }}
                            labelPosition="left"
                            style={{ width: 'calc(100% - 50px)' }}
                          />
                        )}
                      />
                      <StyledField
                        name="fees.agent.yearly"
                        placeholder="Registered Agent Yearly Fee"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            label={{ basic: true, content: 'Yr' }}
                            labelPosition="left"
                            style={{ width: 'calc(100% - 50px)' }}
                          />
                        )}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>State Compliance Fees</label>
                    </Form.Group>
                    <ErrorMessage
                      name="fees.scs.monthly"
                      render={msg => (
                        <ValidationError
                          content={'Monthly: ' + msg}
                          style={{ margin: '0 0 5px 0' }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="fees.scs.yearly"
                      render={msg => (
                        <ValidationError
                          content={'Yearly: ' + msg}
                          style={{ margin: '0 0 5px 0' }}
                        />
                      )}
                    />
                    <Form.Group style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
                      <StyledField
                        name="fees.scs.monthly"
                        placeholder="State Compliance Monthly Fee"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            label={{ basic: true, content: 'Mo' }}
                            labelPosition="left"
                            style={{ width: 'calc(100% - 50px)' }}
                          />
                        )}
                      />
                      <StyledField
                        name="fees.scs.yearly"
                        placeholder="State Compliance Yearly Fee"
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            label={{ basic: true, content: 'Yr' }}
                            labelPosition="left"
                            style={{ width: 'calc(100% - 50px)' }}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group>
                      <label>API Token</label>
                    </Form.Group>
                    <Form.Group>
                      <StyledField value={token} style={{ fontStyle: 'italic' }} disabled />
                    </Form.Group>

                    <Form.Group>
                      <label>Welcome text</label>
                    </Form.Group>
                    <Form.Group>
                      <p style={{ fontStyle: 'italic', color: '#2b7a76' }}>Will be here soon!</p>
                    </Form.Group>
                    {/* <Form.Group>
                <WelcomeDescEditor
                  editor={editor}
                  initialValue={data.welcome_text}
                  // value={editorState}
                  // handleChange={({ value }) => {
                  //   setEditorState(value)
                  //   setFieldValue('welcome_text', html.serialize(value))
                  // }}
                />
              </Form.Group> */}
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Button
                disabled={!isValid}
                type="submit"
                content="Submit"
                onClick={() => handleSubmit()}
                fluid
                size="large"
                color="teal"
                style={{ marginTop: '2em' }}
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </Container>
  )
}

export default Settings
